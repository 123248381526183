import React from "react";
import Button from "../components/ui/Button";
import "../styles/footer.css";
import Form from "../pages/Home/Form"
// import Copyright from "../components/Copyright";
import SecondaryFooter from "./SecondaryFooter";

import { Link, useNavigate } from "react-router-dom";

import { LanguagePageContext } from '../context/LanguagePageContext';

export default function Footer() {

    const instagramIconPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "instagram.png";
    const facebookIconPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "facebook.png";
    const youtubeIconPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "youtube.png";
    const twitterIconPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "twitter.png";
    const tiktokIconPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "tiktok.png";

    const languagePageData = React.useContext(LanguagePageContext);


    const navigate = useNavigate();

    function redirection(address) {

        if (address !== undefined) {

            let currentPath = window.location.pathname;

            if(currentPath === "/") {
                currentPath = address;
            }
            else {
                currentPath = currentPath + address;
            }

            navigate(currentPath);
        }
        return;
    }



    return(
        <footer className="footer-design">
            <div className="container d-flex align-items-center justify-content-center">
                <div className="row w-100" style={{maxWidth: "800px"}}>
                    {/* ROW1 */}
                    <div className="col-lg-6 text-center mt-4 px-0">
                        <p className="footer-quote">“{languagePageData.homePage[0].quote}”</p>
                        <p className="footer-quote"><strong>-George R.R. Martin</strong></p>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center px-5">
                        <Button content={languagePageData.homePage[0].letStartButtonContent} buttonHandler={() => redirection("/register")} isDisable={false} isFooter={true}/>
                    </div>
                    {/* ENDROW1 */}
                    {/* ROW2 */}
                    <div className="col-sm-6 mt-3">
                        <Form/>
                    </div>
                    <div className="col-sm-6 mt-3">
                        {/* SUBROW1 */}
                        <div className="row text-center">
                            <div className="col-6 px-0">
                                <Link reloadDocument to={window.location.pathname.length === 1 ? "/about" : window.location.pathname + "/about"} className="footer-items footer-emphasis">{languagePageData.homePage[0].aboutLink}</Link>
                            </div>
                            <div className="col-6 px-0">
                                <Link reloadDocument to={window.location.pathname.length === 1 ? "/blog" : window.location.pathname + "/blog"} className="footer-items footer-emphasis">{languagePageData.homePage[0].blogLink}</Link>
                            </div>
                        </div>
                        {/* ENDSUBROW1 */}
                        {/* SUBROW2 */}
                        <div className="row text-center mt-3">
                            <div className="px-0">
                                <p className="footer-language-subtitle footer-emphasis">{languagePageData.homePage[0].languagesFooter}</p>
                            </div>
                        </div>
                        {/* ENDSUBROW2 */}
                        {/* SUBROW3 */}
                        <div className="row text-center mt-2 ">
                            <div className="col-6 px-0 d-flex flex-column mt-2">
                                <Link reloadDocument to="/en" className="footer-items">{languagePageData.homePage[0].englishLink}</Link>
                            </div>
                            <div className="col-6 px-0 d-flex flex-column mt-2">
                                <Link reloadDocument to="/es" className="footer-items">{languagePageData.homePage[0].spanishLink}</Link>
                            </div>
                        {/* </div> */}
                        {/* ENDSUBROW3 */}
                        {/* SUBROW4 */}
                        {/* <div className="row text-center mt-2"> */}
                            <div className="col-6 px-0 d-flex flex-column mt-2">
                                <Link reloadDocument to="/de" className="footer-items">{languagePageData.homePage[0].germanLink}</Link>
                            </div>
                            <div className="col-6 px-0 d-flex flex-column mt-2">
                                <Link reloadDocument to="/fr" className="footer-items">{languagePageData.homePage[0].frenchLink}</Link>
                            </div>
                        {/* </div> */}
                        {/* ENDSUBROW4 */}
                        {/* SUBROW5 */}
                        {/* <div className="row text-center mt-2 "> */}
                            <div className="col-6 px-0 d-flex flex-column mt-2">
                                <Link reloadDocument to="/ru" className="footer-items">{languagePageData.homePage[0].russianLink}</Link>
                            </div>
                            <div className="col-6 px-0 d-flex flex-column mt-2">
                                <Link reloadDocument to="/pl" className="footer-items">{languagePageData.homePage[0].polishLink}</Link>
                            </div>
                        {/* </div> */}
                        {/* ENDSUBROW5 */}
                        {/* SUBROW5 */}
                        {/* <div className="row text-center mt-2"> */}
                            <div className="col-6 px-0 d-flex flex-column mt-2">
                                <Link reloadDocument to="/it" className="footer-items">{languagePageData.homePage[0].italianLink}</Link>
                            </div>
                            <div className="col-6 px-0 d-flex flex-column mt-2">
                                <Link reloadDocument to="/pt" className="footer-items">{languagePageData.homePage[0].portugueseLink}</Link>
                            </div>
                        </div>
                        {/* ENDSUBROW5 */}
                    </div>
                    {/* ENDROW2 */}

                    <div className="mt-4 mb-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center d-flex align-items-center justify-content-center">
                                    {/* <p className="social-icon">Instagram</p> */}
                                    <a className="px-1" href="https://www.instagram.com/lingotales/" target="blank">
                                        <img src={instagramIconPath} className="social-icon" />   
                                    </a>
                                    <a className="px-1" href="https://www.facebook.com/profile.php?id=100090425867317" target="blank">
                                        <img src={facebookIconPath} className="social-icon" />
                                    </a>
                                    <a className="px-1" href="https://www.youtube.com/@lingotalesOfficial/featured" target="blank">
                                        <img src={youtubeIconPath} className="social-icon"/> 
                                    </a>
                                    <a className="px-1" href="https://twitter.com/lingotales" target="blank">
                                        <img src={twitterIconPath} className="social-icon"/>  
                                    </a>
                                    <a className="px-1" href="https://www.tiktok.com/@lingotales" target="blank">
                                        <img src={tiktokIconPath} className="social-icon"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Copyright/> */}
            <SecondaryFooter/>
        </footer>
    );
}