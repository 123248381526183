import React from "react";
import "../../styles/accountmodal.css";

import { useNavigate } from "react-router-dom";

import { logout } from '../../utils/auth';
import { updateUserLanguagePage } from "../../utils/user";


export default function AccountModal({open, data}) {
    const navigate = useNavigate();

    if (!open) return null;

    async function buttonHandler(event) {
        let address = "/" + event.target.id + window.location.pathname.slice(3);
        if (event.target.id === "account") {
            address = window.location.pathname.slice(0,4) + event.target.id;
            navigate(address);
            navigate(0);
            return;
        }
        else if (event.target.id === "logout"){
            logout() // Remove Token from Cookie
            localStorage.clear();
            address = window.location.pathname.slice(0,3);
            navigate(address);
            navigate(0);
            return;
        }
        else {
            //Set user language page
            const email = localStorage.getItem('email');
            const success = await updateUserLanguagePage(email, event.target.id);
    
            navigate(address);
            navigate(0);
        }
    }



    const buttonList = data.map((button, i) => {
        return <button key={i} className="account-modal-btn" id={button.id} onClick={buttonHandler}>{button.content}</button>
    })

    return(
        <div className="account-modal-container" onClick={(e) => {e.stopPropagation()}} >
            {buttonList}
        </div>
    );
}