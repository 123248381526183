import React, { useEffect } from "react";
import HeaderStories from "../../layout/HeaderStories";
import Button from "../../components/ui/Button";
import Filter from "../Discover/Filter";
import SearchBar from "../Discover/SearchBar";
import StoryCard from "../../components/ui/StoryCard";
import "../../styles/storiesbrowser.css"

// import storyCardData from "../data/storyCardData";

import { useNavigate, useLocation } from "react-router-dom";

import Container from "react-bootstrap/esm/Container";


import { LanguagePageContext } from "../../context/LanguagePageContext";

import useAxios from "../../hooks/useAxios";
import axiosLingo from '../../apis/lingotalesApi';
import axios from "axios";

import {Helmet} from "react-helmet";

export default function StoriesBrowser({language}) {

    // const [isLoading, setIsLoading] = React.useState(true);

    // const [backendData, loading, error, setBackendData] = useAxios({
    //     axiosInstance: axiosLingo,
    //     method: 'GET',
    //     url: window.location.pathname,
    //     requestConfig: {
    //         headers: {
    //             'Content-Language': 'en-US',
    //         }
    //     }
    // });

    const location = useLocation();

    const [filterData, setFilterData] = React.useState();
    const [backendData, setBackendData] = React.useState([]);
    const [searchBar, setSearchBar] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);


    React.useEffect(() => {
        
        // console.log("getting backend Data");

        // $+window.location.pathname
        

        axios.get(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}${window.location.pathname}`).then(response => {
            setBackendData(response.data);
            // setIsLoading(false);
        }).catch( error => {
            // console.log(error);
            // setIsLoading(false);
        });
    }, [location])

    React.useEffect(() => {
        if (filterData !== undefined) {
            // console.log(filterData);
            setIsLoading(true);

            axios.post(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}${window.location.pathname}`, filterData).then(response => {
                setBackendData(response.data);
                setSearchBar("");
                // setIsLoading(false);
                setIsLoading(false);
            }).catch( error => {
                // console.log(error);
                // setIsLoading(false);
                setIsLoading(false);
            });
            
        }
    }, [filterData])

    const navigate = useNavigate();

    const nextPath = React.useRef(window.location.pathname);


    

    React.useEffect(() => {
        setSearchBar(() => "");
    },[language])

    function searchBarHandler(event) {
        setSearchBar(() => event.target.value)
    }




    React.useEffect(() => {
        nextPath.current = window.location.pathname;
        //console.log("in useffect:" + nextPath.current);
    }, []);

    const availableCardSize = React.useRef(2);


    function cardSelectorHandler (event) {

        if (backendData.storyCards === undefined) {return}

        //Just consider the first then card options to redirect otherwise go to log in page
        for (let i = 0; i < availableCardSize.current ; i++) {
            if (Number(backendData.storyCards[i].storyId) === Number(event.target.id)) {
                nextPath.current = window.location.pathname.slice(0, window.location.pathname.indexOf(`${language}`) + language.length);
                redirection(nextPath.current + "/" + event.target.id);
                return;
            }
        }
        let loginPath = "/login";
        if (window.location.pathname.slice(0, 3) === "/st") {
            loginPath = "/login";
        }
        else{
            loginPath = window.location.pathname.slice(0, 3) + "/login";
        }
    
        redirection(loginPath);

    }
    

    const [next, setNext] = React.useState(true);
    const [prev, setPrev] = React.useState(true);
    const [currentCards, setCurrentCards] = React.useState([]);

    const page = React.useRef(1);
    const step = React.useRef(4); // humber of cards to display in the page
    const nextDisable = React.useRef(false);
    const prevDisable = React.useRef(false);

    

    function redirection(address) {
        if (address !==  undefined) {
            navigate(address);
        }
        return;
    }

    

    function setPage(state) {

        if (state === "next") {
            setNext((prev) =>{
                return !prev;
            })
            return;
        }

        if (state ==="prev") {
            setPrev((prev) =>{
                return !prev;
            })
            return;
        }    
    }

    let AllstoryCardList;

    if (backendData.storyCards !== undefined) {
        AllstoryCardList = backendData.storyCards.map((card, index) => {
            return <StoryCard 
                            title={card.title} 
                            category={card.categoryLabel} 
                            level={card.levelLabel}
                            likes={card.likes} 
                            language={card.language}
                            img={card.fileName} 
                            levelRef={card.levelRef} 
                            id={card.storyId}
                            clickHandler={(event) => cardSelectorHandler(event)}
                            index={index + 1}
                            availableCardSize={2}
                            key={index}
                            />
        })
    }
    
    

    const [storyCardList, setStoryCardList] = React.useState(AllstoryCardList);

    React.useEffect(() => {
        let tempAllstoryCardList
        if (backendData.storyCards !== undefined) {
            const loginPrompt = backendData.storyBrowserPage[0].loginCardPrompt;
            const loginButtonContent = backendData.navBar[0].navLogin;

            tempAllstoryCardList = backendData.storyCards.map((card, index) => {
                return <StoryCard 
                                title={card.title} 
                                category={card.categoryLabel} 
                                level={card.levelLabel}  
                                likes={card.likes} 
                                language={card.language}
                                img={card.fileName} 
                                levelRef={card.levelRef} 
                                id={card.storyId}
                                clickHandler={(event) => cardSelectorHandler(event)}
                                index={index + 1}
                                loginCardPrompt={loginPrompt}
                                availableCardSize={2}
                                loginButtonContent={loginButtonContent}
                                key={index}
                                />
            })
        }

        setStoryCardList(tempAllstoryCardList);
    }, [backendData])

    React.useEffect(()=>{
        page.current = page.current + 1;
        //console.log(page.current);
        updateCards("next");
    }, [next])

    React.useEffect(()=>{
        page.current = page.current - 1;
        updateCards("prev");

    }, [prev])

    React.useEffect(() => {
        setStoryCardList(() => AllstoryCardList);
        
    },[language])


    
    //UPDATE SEARCH BAR
    React.useEffect(() => {
        if (searchBar === undefined) {
            return;
        }

        if(searchBar.length === 0) {
            setStoryCardList(() => AllstoryCardList);
        }

        if (searchBar.length > 0) {
            setStoryCardList(() => AllstoryCardList.filter((card) => card.props.title.slice(0, searchBar.length).toLowerCase() === searchBar.toLowerCase()));
        }
 
    }, [searchBar])

    React.useEffect(() => {
        page.current = 1;
        updateCards("init");
    }, [storyCardList])



    function updateCards(action) {

        if (storyCardList === undefined) {return}
        
        const lastPage = Math.ceil(storyCardList.length / step.current);
        const firstPage = 1;

        let nextCount = 0;
        let currentCount = 0;

        const condition1 = page.current < lastPage && page.current >= firstPage;
        const condition2 = page.current > firstPage && page.current <= lastPage;
        const condition3 = action === "init";


        if (!condition3) {
            nextDisable.current = condition1 ? false : true;
            prevDisable.current = condition2 ? false : true;
        }

        if (condition1 || condition2 || condition3 ) {
            currentCount = page.current * step.current - step.current;

            if (storyCardList.length - currentCount >= step.current) {
                nextCount = currentCount + step.current;
            }
            else{
                nextCount = storyCardList.length;
            }
            
            setCurrentCards(() => {
                return storyCardList.slice(currentCount, nextCount);
            })
        }

        if (condition3) {

            if (storyCardList.length <= step.current) {
                nextDisable.current = true;
                prevDisable.current = true;
            }

            if (storyCardList.length > step.current) {
                nextDisable.current = false;
                prevDisable.current = true;
            }
        }
    }

    
    return(
        <>
        {backendData.storyBrowserPage === undefined ? <></>:
        <LanguagePageContext.Provider value={backendData}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{backendData.storyBrowserPage[0].discoverMetaTitle} | Lingotales</title>
            </Helmet>
            <HeaderStories activeLanguage={language}/>
            <Container className="d-flex justify-content-center">
                <div className="w-100 mx-2" style={{maxWidth: "800px"}}>
                    <div className="row">
                        <Filter activeLanguage={language} setFilterData={setFilterData} isLoading={isLoading}/>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <SearchBar searchBar={searchBar} searchBarText={backendData.storyBrowserPage[0].searchBarText} searchBarHandler={(event) => searchBarHandler(event)}/>
                    </div>

                    <div className="row text-center mt-2">
                        {currentCards.length > 0 && currentCards}
                        {currentCards.length === 0 && <h2 className="mt-2 text-center mb-5 ">{backendData.storyBrowserPage[0].noStoriesText}</h2>}
                    </div>
                    
                    <div className="row mb-2 d-flex justify-content-center align-items-center mt-2" >
                        <div className="col-md-2 px-0 col-sm-3 col-4">
                            {currentCards.length > 0 && <Button content={backendData.storyBrowserPage[0].backButtonContent} buttonHandler={() => setPage("prev")} isDisable={prevDisable.current}/>}
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            {currentCards.length > 0 && <p className="page-number text-center mx-auto">{page.current}</p>}
                        </div>
                        <div className="col-md-2 px-0 col-sm-3 col-4">
                            {currentCards.length > 0 && <Button content={backendData.storyBrowserPage[0].nextButtonContent} buttonHandler={() => setPage("next")} isDisable={nextDisable.current}/>}
                        </div>
                    </div>
            </div>
            </Container>
        </LanguagePageContext.Provider>
        }
        </>
    );
}
