import React from "react";

import NavBarExt from "../../layout/NavBarExt";
import SecondaryFooter from "../../layout/SecondaryFooter";

import useAxios from "../../hooks/useAxios";
import axios from '../../apis/lingotalesApi';

import { LanguagePageContext } from "../../context/LanguagePageContext";

import Container from "react-bootstrap/esm/Container";

import {Helmet} from "react-helmet";

import "../../styles/blogpage.css";

export default function BlogPage() {

    
    const micePath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "mice.png";


    const [response, loading, error, fetchData] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        url: process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL + window.location.pathname + window.location.search,
        requestConfig: {
            headers: {
                'Content-Language': 'en-US',
            }
        }
    });

    React.useEffect(() => {
        fetchData();
    },[])

    if (error) {
        return <div>Error: Page not found</div>
    }

    if (loading) {
        return null;
    }

    if(response.errorStatus) {
        return <div>Error: Blog not found</div>
    }

    const imagePath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS +  response.blog.image;

    // console.log(imagePath)

    const blogParagraphList = response.blog.paragraphs.map( (paragraph) => {
        return (
            <>
                <h2 id={`${paragraph.title}`} className="mt-4 blog-page-post-subtitle">{paragraph.title}</h2>
                <p className="mt-2 blog-page-post-text">{paragraph.text}</p>
            </>
        );
    })

    const blogIndexList = response.blog.paragraphs.map( (paragraph) => {
        return (
            <li className="mt-1 blog-list-link"><a className="blog-content-link" href={`#${paragraph.title}`}>{paragraph.title}</a></li>
        );
    })

    return(
        <LanguagePageContext.Provider value={response}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{response.blog.title} | Lingotales</title>
                <meta
                name="description"
                content= {response.blog.paragraphs[0].text}
                />
            </Helmet>
            <NavBarExt isBlogPage={true}/>
            <Container className="d-flex justify-content-center" >
                <div className="row blog-page-container" style={{maxWidth: "600px"}}>
                    <h1 className="blog-page-post-title">{response.blog.title}</h1>
                    <div className="mt-1 blog-author-container justify-content-center">
                        <img src={micePath} className="blog-author-img"/>
                        <p className="blog-page-post-author"><strong>{response.blog.writtenBy}</strong> ~ {response.blog.dateLetters}</p>
                    </div>
                    <div className="mt-3 blog-img-container">
                        <img src={imagePath} alt="" className="blog-page-img"/>
                    </div>
                    <div className="mt-4 index-container px-2">
                        <h3 className="blog-index-title pt-2">{response.blog.indexTitle}</h3>
                        <ul className="unorder-list-link">
                            {blogIndexList}
                        </ul>
                    </div>
                    {blogParagraphList}
                </div>
            </Container>
            <SecondaryFooter/>
        </LanguagePageContext.Provider>
    );
}