import React from "react";
import LogNavBar from "../../layout/LogNavBar";

import StoryCardList from "../../components/ui/StoryCardList";
import "../../styles/dashboard.css";

import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios"

import {Helmet} from "react-helmet";

import { LanguagePageContext } from "../../context/LanguagePageContext";
import Banner from "./Banner";

export default function Dashboard() {

    const [backendData, setBackendData] = React.useState([]);
    const [activeLanguage, setActiveLanguage] = React.useState();

    const location = useLocation();

    React.useEffect(() => {
        
        console.log("getting backend Data");

        const email = localStorage.getItem('email');
        // console.log("sending email", email);

        

        axios.post(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}${window.location.pathname}`, {email: email}).then(response => {
            setBackendData(response.data);
            setActiveLanguage(response.data.user[0].targetLang[0]);
            // setIsLoading(false);
        }).catch( error => {
            // console.log(error);
            // setIsLoading(false);
        });
    }, [location])



    // const [backendData, loading, error] = useAxios({
    //     axiosInstance: axios,
    //     method: 'GET',
    //     url: window.location.pathname,
    //     requestConfig: {
    //         headers: {
    //             'Content-Language': 'en-US',
    //         }
    //     }
    // });

    // if (loading) {
    //     return <></>
    // }


    const defaultModalController = [{    "id" : "language",
                                        "show" : false
                                    },
                                    {    "id" : "account",
                                        "show" : false
                                    }] 

    const [modalController, setModalController] = React.useState(defaultModalController);

    function closeModalHandler(event){
        // console.log(event.target)
        setModalController(prev => prev.map((modal) => {
            if (event.target.id !== modal.id) {
                return {...modal,
                        show: false};
            }
            return modal;
        }))
    }

    function closeAllModalHandler() {
        setModalController(prev => prev.map((modal) => {return {...modal, show: false}}))
    }

    function openModalHandler(event) {
        setModalController(prev => prev.map((modal) => {
            if (event.target.id === modal.id) {
                return {...modal,
                        show: true};
            }
            return modal;
        }))
    }

    // Select Active Language
    function clickHandler(event) {
        // console.log(event.target.id);
        setActiveLanguage(event.target.id);
    }

    let flagList;

    if (backendData.flags !== undefined) {

    flagList = backendData.flags.map((country) => {
        const pathFlag = process.env.REACT_APP_PUBLIC_URL_FLAGS + country.fileName;

        if (backendData.user[0].targetLang.includes(country.language)) {
            return(
                <span className={`emphasis-container ${country.language === activeLanguage ? "clicked-flag" : ""}`}>
                    <img className="dashboard-language-flag" src={pathFlag} alt="flag" onClick={clickHandler} id={country.language}/>
                </span>
            );
        }
        
    })

    }
    

        return(
            <>
            {backendData.dashboard === undefined ? null :
            <LanguagePageContext.Provider value={backendData}>
                <Helmet>
                <meta charSet="utf-8" />
                <title>{backendData.dashboard[0].dashboardMetaTitle} | Lingotales</title>
                </Helmet>
                <div>
                    <LogNavBar isHome={true} modalController={modalController} openModalHandler={(event) => openModalHandler(event)}  closeModalHandler={(event) => closeModalHandler(event)}/>
                    <div className="container mt-2" onClick={closeAllModalHandler}>
                        <div className="row mx-auto mb-5" style={{maxWidth: "1100px"}}>
                            <Banner storyOfTheDay={backendData.storyOfTheDay[activeLanguage]} img={backendData.storyOfTheDay[activeLanguage].fileName} flagList={flagList} language={activeLanguage}/>
                            {backendData.inProgressCards.length > 0 ? <StoryCardList storyCards={backendData.inProgressCards} categoryTitle={backendData.dashboard[0].continueReadTitle}/> : null}
                            <StoryCardList storyCards={backendData.storyCards[activeLanguage]} flagList={flagList} categoryTitle={backendData.dashboard[0].newHitsTitle}/>
                            {backendData.alreadyReadCards.length > 0 ? <StoryCardList storyCards={backendData.alreadyReadCards} categoryTitle={backendData.dashboard[0].recentReadTitle}/> : null}
                        </div>
                    </div>
                </div>
            </LanguagePageContext.Provider>
            }
            </>
            
        );
}
