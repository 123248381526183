const data = {
    "message": "SUCCESS",
    "data" : {  "storyLink" : [
                {
                    "id": "1",
                    "language": "english",
                    "path": "/stories/english",
                },
                {
                    "id": "2",
                    "language": "french",
                    "path": "/stories/french",
                },
                // {
                //     "id": "3",
                //     "language": "polish",
                //     "path": "/stories/polish",
                // },
                // {
                //     "id": "4",
                //     "language": "russian",
                //     "path": "/stories/russian",
                // },
                {
                    "id": "5",
                    "language": "spanish",
                    "path": "/stories/spanish",
                },
                {
                    "id": "6",
                    "language": "german",
                    "path": "/stories/german",
                },
                // {
                //     "id": "7",
                //     "language": "italian",
                //     "path": "/stories/italian",
                // },
                // {
                //     "id": "8",
                //     "language": "portuguese",
                //     "path": "/stories/portuguese",
                // },
                {
                    "id": "9",
                    "language": "english",
                    "path": "/en/stories/english",
                },
                {
                    "id": "10",
                    "language": "french",
                    "path": "/en/stories/french",
                },
                // {
                //     "id": "11",
                //     "language": "polish",
                //     "path": "/en/stories/polish",
                // },
                // {
                //     "id": "12",
                //     "language": "russian",
                //     "path": "/en/stories/russian",
                // },
                {
                    "id": "13",
                    "language": "spanish",
                    "path": "/en/stories/spanish",
                },
                {
                    "id": "14",
                    "language": "german",
                    "path": "/en/stories/german",
                },
                // {
                //     "id": "15",
                //     "language": "italian",
                //     "path": "/en/stories/italian",
                // },
                // {
                //     "id": "16",
                //     "language": "portuguese",
                //     "path": "/en/stories/portuguese",
                // },
                {
                    "id": "17",
                    "language": "english",
                    "path": "/de/stories/english",
                },
                {
                    "id": "18",
                    "language": "french",
                    "path": "/de/stories/french",
                },
                // {
                //     "id": "19",
                //     "language": "polish",
                //     "path": "/de/stories/polish",
                // },
                // {
                //     "id": "20",
                //     "language": "russian",
                //     "path": "/de/stories/russian",
                // },
                {
                    "id": "21",
                    "language": "spanish",
                    "path": "/de/stories/spanish",
                },
                {
                    "id": "22",
                    "language": "german",
                    "path": "/de/stories/german",
                },
                // {
                //     "id": "23",
                //     "language": "italian",
                //     "path": "/de/stories/italian",
                // },
                // {
                //     "id": "24",
                //     "language": "portuguese",
                //     "path": "/de/stories/portuguese",
                // },
                {
                    "id": "25",
                    "language": "english",
                    "path": "/fr/stories/english",
                },
                {
                    "id": "26",
                    "language": "french",
                    "path": "/fr/stories/french",
                },
                // {
                //     "id": "27",
                //     "language": "polish",
                //     "path": "/fr/stories/polish",
                // },
                // {
                //     "id": "28",
                //     "language": "russian",
                //     "path": "/fr/stories/russian",
                // },
                {
                    "id": "29",
                    "language": "spanish",
                    "path": "/fr/stories/spanish",
                },
                {
                    "id": "30",
                    "language": "german",
                    "path": "/fr/stories/german",
                },
                // {
                //     "id": "31",
                //     "language": "italian",
                //     "path": "/fr/stories/italian",
                // },
                // {
                //     "id": "32",
                //     "language": "portuguese",
                //     "path": "/fr/stories/portuguese",
                // },
                {
                    "id": "33",
                    "language": "english",
                    "path": "/ru/stories/english",
                },
                {
                    "id": "34",
                    "language": "french",
                    "path": "/ru/stories/french",
                },
                // {
                //     "id": "35",
                //     "language": "polish",
                //     "path": "/ru/stories/polish",
                // },
                // {
                //     "id": "36",
                //     "language": "russian",
                //     "path": "/ru/stories/russian",
                // },
                {
                    "id": "37",
                    "language": "spanish",
                    "path": "/ru/stories/spanish",
                },
                {
                    "id": "38",
                    "language": "german",
                    "path": "/ru/stories/german",
                },
                // {
                //     "id": "39",
                //     "language": "italian",
                //     "path": "/ru/stories/italian",
                // },
                // {
                //     "id": "40",
                //     "language": "portuguese",
                //     "path": "/ru/stories/portuguese",
                // },
                {
                    "id": "41",
                    "language": "english",
                    "path": "/es/stories/english",
                },
                {
                    "id": "42",
                    "language": "french",
                    "path": "/es/stories/french",
                },
                // {
                //     "id": "43",
                //     "language": "polish",
                //     "path": "/es/stories/polish",
                // },
                // {
                //     "id": "44",
                //     "language": "russian",
                //     "path": "/es/stories/russian",
                // },
                {
                    "id": "45",
                    "language": "spanish",
                    "path": "/es/stories/spanish",
                },
                {
                    "id": "46",
                    "language": "german",
                    "path": "/es/stories/german",
                },
                // {
                //     "id": "47",
                //     "language": "italian",
                //     "path": "/es/stories/italian",
                // },
                // {
                //     "id": "48",
                //     "language": "portuguese",
                //     "path": "/es/stories/portuguese",
                // },
                {
                    "id": "49",
                    "language": "english",
                    "path": "/it/stories/english",
                },
                {
                    "id": "50",
                    "language": "french",
                    "path": "/it/stories/french",
                },
                // {
                //     "id": "51",
                //     "language": "polish",
                //     "path": "/it/stories/polish",
                // },
                // {
                //     "id": "52",
                //     "language": "russian",
                //     "path": "/it/stories/russian",
                // },
                {
                    "id": "53",
                    "language": "spanish",
                    "path": "/it/stories/spanish",
                },
                {
                    "id": "54",
                    "language": "german",
                    "path": "/it/stories/german",
                },
                // {
                //     "id": "55",
                //     "language": "italian",
                //     "path": "/it/stories/italian",
                // },
                // {
                //     "id": "56",
                //     "language": "portuguese",
                //     "path": "/it/stories/portuguese",
                // },
                {
                    "id": "57",
                    "language": "english",
                    "path": "/pt/stories/english",
                },
                {
                    "id": "58",
                    "language": "french",
                    "path": "/pt/stories/french",
                },
                // {
                //     "id": "59",
                //     "language": "polish",
                //     "path": "/pt/stories/polish",
                // },
                // {
                //     "id": "60",
                //     "language": "russian",
                //     "path": "/pt/stories/russian",
                // },
                {
                    "id": "61",
                    "language": "spanish",
                    "path": "/pt/stories/spanish",
                },
                {
                    "id": "62",
                    "language": "german",
                    "path": "/pt/stories/german",
                },
                // {
                //     "id": "63",
                //     "language": "italian",
                //     "path": "/pt/stories/italian",
                // },
                // {
                //     "id": "64",
                //     "language": "portuguese",
                //     "path": "/pt/stories/portuguese",
                // },
                {
                    "id": "65",
                    "language": "english",
                    "path": "/pl/stories/english",
                },
                {
                    "id": "66",
                    "language": "french",
                    "path": "/pl/stories/french",
                },
                // {
                //     "id": "67",
                //     "language": "polish",
                //     "path": "/pl/stories/polish",
                // },
                // {
                //     "id": "68",
                //     "language": "russian",
                //     "path": "/pl/stories/russian",
                // },
                {
                    "id": "69",
                    "language": "spanish",
                    "path": "/pl/stories/spanish",
                },
                {
                    "id": "70",
                    "language": "german",
                    "path": "/pl/stories/german",
                }
                // {
                //     "id": "71",
                //     "language": "italian",
                //     "path": "/pl/stories/italian",
                // },
                // {
                //     "id": "72",
                //     "language": "portuguese",
                //     "path": "/pl/stories/portuguese",
                 //}
                ],

                "homeLink" : [
                {
                    "id": "1",
                    "language": "english",
                    "path": "/en",
                    "basePath" : "/"
                },
                {
                    "id": "2",
                    "language": "french",
                    "path": "/fr",
                    "basePath" : ""
                },
                {
                    "id": "3",
                    "language": "polish",
                    "path": "/pl",
                    "basePath" : ""
                },
                {
                    "id": "4",
                    "language": "russian",
                    "path": "/ru",
                    "basePath" : ""
                },
                {
                    "id": "5",
                    "language": "spanish",
                    "path": "/es",
                    "basePath" : ""
                },
                {
                    "id": "6",
                    "language": "german",
                    "path": "/de",
                    "basePath" : ""
                },
                {
                    "id": "7",
                    "language": "italian",
                    "path": "/it",
                    "basePath" : ""
                },
                {
                    "id": "8",
                    "language": "portuguese",
                    "path": "/pt",
                    "basePath" : ""
                },
                {
                    "id": "9",
                    "language": "english",
                    "path": "/",
                    "basePath" : ""
                }],

                "registerLink": [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/register",
                        "basePath" : "/register"
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/fr/register",
                        "basePath" : "/register"
                    },
                    {
                        "id": "3",
                        "language": "polish",
                        "path": "/pl/register",
                        "basePath" : "/register"
                    },
                    {
                        "id": "4",
                        "language": "russian",
                        "path": "/ru/register",
                        "basePath" : "/register"
                    },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/es/register",
                        "basePath" : "/register"
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/de/register",
                        "basePath" : "/register"
                    },
                    {
                        "id": "7",
                        "language": "italian",
                        "path": "/it/register",
                        "basePath" : "/register"
                    },
                    {
                        "id": "8",
                        "language": "portuguese",
                        "path": "/pt/register",
                        "basePath" : "/register"
                    },
                    {
                        "id": "9",
                        "language": "english",
                        "path": "/register",
                        "basePath" : "/register"
                    }
                ],

                "loginLink" : [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/login",
                        "basePath" : "/login"
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/fr/login",
                        "basePath" : "/login"
                    },
                    {
                        "id": "3",
                        "language": "polish",
                        "path": "/pl/login",
                        "basePath" : "/login"
                    },
                    {
                        "id": "4",
                        "language": "russian",
                        "path": "/ru/login",
                        "basePath" : "/login"
                    },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/es/login",
                        "basePath" : "/login"
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/de/login",
                        "basePath" : "/login"
                    },
                    {
                        "id": "7",
                        "language": "italian",
                        "path": "/it/login",
                        "basePath" : "/login"
                    },
                    {
                        "id": "8",
                        "language": "portuguese",
                        "path": "/pt/login",
                        "basePath" : "/login"
                    },
                    {
                        "id": "9",
                        "language": "english",
                        "path": "/login",
                        "basePath" : "/login"
                    }
                ],

                "helpLink": [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/help",
                        "basePath" : "/help"
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/fr/help",
                        "basePath" : "/help"
                    },
                    {
                        "id": "3",
                        "language": "polish",
                        "path": "/pl/help",
                        "basePath" : "/help"
                    },
                    {
                        "id": "4",
                        "language": "russian",
                        "path": "/ru/help",
                        "basePath" : "/help"
                    },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/es/help",
                        "basePath" : "/help"
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/de/help",
                        "basePath" : "/help"
                    },
                    {
                        "id": "7",
                        "language": "italian",
                        "path": "/it/help",
                        "basePath" : "/help"
                    },
                    {
                        "id": "8",
                        "language": "portuguese",
                        "path": "/pt/help",
                        "basePath" : "/help"
                    },
                    {
                        "id": "9",
                        "language": "english",
                        "path": "/help",
                        "basePath" : "/help"
                    }
                ],

                "aboutLink": [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/about",
                        "basePath" : "/about"
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/fr/about",
                        "basePath" : "/about"
                    },
                    {
                        "id": "3",
                        "language": "polish",
                        "path": "/pl/about",
                        "basePath" : "/about"
                    },
                    {
                        "id": "4",
                        "language": "russian",
                        "path": "/ru/about",
                        "basePath" : "/about"
                    },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/es/about",
                        "basePath" : "/about"
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/de/about",
                        "basePath" : "/about"
                    },
                    {
                        "id": "7",
                        "language": "italian",
                        "path": "/it/about",
                        "basePath" : "/about"
                    },
                    {
                        "id": "8",
                        "language": "portuguese",
                        "path": "/pt/about",
                        "basePath" : "/about"
                    },
                    {
                        "id": "9",
                        "language": "english",
                        "path": "/about",
                        "basePath" : "/about"
                    }
                ],

                "termsLink": [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/terms",
                        "basePath" : "/about"
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/fr/terms",
                        "basePath" : "/terms"
                    },
                    {
                        "id": "3",
                        "language": "polish",
                        "path": "/pl/terms",
                        "basePath" : "/terms"
                    },
                    {
                        "id": "4",
                        "language": "russian",
                        "path": "/ru/terms",
                        "basePath" : "/terms"
                    },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/es/terms",
                        "basePath" : "/terms"
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/de/terms",
                        "basePath" : "/terms"
                    },
                    {
                        "id": "7",
                        "language": "italian",
                        "path": "/it/terms",
                        "basePath" : "/terms"
                    },
                    {
                        "id": "8",
                        "language": "portuguese",
                        "path": "/pt/terms",
                        "basePath" : "/terms"
                    },
                    {
                        "id": "9",
                        "language": "english",
                        "path": "/terms",
                        "basePath" : "/terms"
                    }
                ],

                "privacyLink": [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/privacy",
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/fr/privacy",
                    },
                    {
                        "id": "3",
                        "language": "polish",
                        "path": "/pl/privacy",
                    },
                    {
                        "id": "4",
                        "language": "russian",
                        "path": "/ru/privacy",
                    },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/es/privacy",
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/de/privacy",
                    },
                    {
                        "id": "7",
                        "language": "italian",
                        "path": "/it/privacy",
                    },
                    {
                        "id": "8",
                        "language": "portuguese",
                        "path": "/pt/privacy",
                    },
                    {
                        "id": "9",
                        "language": "english",
                        "path": "/privacy",
                    }
                ],

                "blogLink": [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/blog",
                        "basePath" : "/blog"
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/fr/blog",
                        "basePath" : "/blog"
                    },
                    {
                        "id": "3",
                        "language": "polish",
                        "path": "/pl/blog",
                        "basePath" : "/blog"
                    },
                    {
                        "id": "4",
                        "language": "russian",
                        "path": "/ru/blog",
                        "basePath" : "/blog"
                    },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/es/blog",
                        "basePath" : "/blog"
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/de/blog",
                        "basePath" : "/blog"
                    },
                    {
                        "id": "7",
                        "language": "italian",
                        "path": "/it/blog",
                        "basePath" : "/blog"
                    },
                    {
                        "id": "8",
                        "language": "portuguese",
                        "path": "/pt/blog",
                        "basePath" : "/blog"
                    },
                    {
                        "id": "9",
                        "language": "english",
                        "path": "/blog",
                        "basePath" : "/blog"
                    }
                ],
                "pricingLink": [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/pricing",
                        "basePath" : "/pricing"
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/fr/pricing",
                        "basePath" : "/pricing"
                    },
                    {
                        "id": "3",
                        "language": "polish",
                        "path": "/pl/pricing",
                        "basePath" : "/pricing"
                    },
                    {
                        "id": "4",
                        "language": "russian",
                        "path": "/ru/pricing",
                        "basePath" : "/pricing"
                    },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/es/pricing",
                        "basePath" : "/pricing"
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/de/pricing",
                        "basePath" : "/pricing"
                    },
                    {
                        "id": "7",
                        "language": "italian",
                        "path": "/it/pricing",
                        "basePath" : "/pricing"
                    },
                    {
                        "id": "8",
                        "language": "portuguese",
                        "path": "/pt/pricing",
                        "basePath" : "/pricing"
                    },
                    {
                        "id": "9",
                        "language": "english",
                        "path": "/pricing",
                        "basePath" : "/pricing"
                    }
                ],

                "dashboardLink": [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/home",
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/fr/home",
                    },
                    {
                        "id": "3",
                        "language": "polish",
                        "path": "/pl/home",
                    },
                    {
                        "id": "4",
                        "language": "russian",
                        "path": "/ru/home",
                    },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/es/home",
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/de/home",
                    },
                    {
                        "id": "7",
                        "language": "italian",
                        "path": "/it/home",
                    },
                    {
                        "id": "8",
                        "language": "portuguese",
                        "path": "/pt/home",
                    }
                ],

                "discoverLink": [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/discover/english",
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/en/discover/french",
                    },
                    // {
                    //     "id": "3",
                    //     "language": "polish",
                    //     "path": "/en/discover/polish",
                    // },
                    // {
                    //     "id": "4",
                    //     "language": "russian",
                    //     "path": "/en/discover/russian",
                    // },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/en/discover/spanish",
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/en/discover/german",
                    },
                    // {
                    //     "id": "7",
                    //     "language": "italian",
                    //     "path": "/en/discover/italian",
                    // },
                    // {
                    //     "id": "8",
                    //     "language": "portuguese",
                    //     "path": "/en/discover/portuguese",
                    // },
                    {
                        "id": "9",
                        "language": "english",
                        "path": "/de/discover/english",
                    },
                    {
                        "id": "10",
                        "language": "french",
                        "path": "/de/discover/french",
                    },
                    // {
                    //     "id": "11",
                    //     "language": "polish",
                    //     "path": "/de/discover/polish",
                    // },
                    // {
                    //     "id": "12",
                    //     "language": "russian",
                    //     "path": "/de/discover/russian",
                    // },
                    {
                        "id": "13",
                        "language": "spanish",
                        "path": "/de/discover/spanish",
                    },
                    {
                        "id": "14",
                        "language": "german",
                        "path": "/de/discover/german",
                    },
                    // {
                    //     "id": "15",
                    //     "language": "italian",
                    //     "path": "/de/discover/italian",
                    // },
                    // {
                    //     "id": "16",
                    //     "language": "portuguese",
                    //     "path": "/de/discover/portuguese",
                    // },
                    {
                        "id": "17",
                        "language": "english",
                        "path": "/it/discover/english",
                    },
                    {
                        "id": "18",
                        "language": "french",
                        "path": "/it/discover/french",
                    },
                    // {
                    //     "id": "19",
                    //     "language": "polish",
                    //     "path": "/it/discover/polish",
                    // },
                    // {
                    //     "id": "20",
                    //     "language": "russian",
                    //     "path": "/it/discover/russian",
                    // },
                    {
                        "id": "21",
                        "language": "spanish",
                        "path": "/it/discover/spanish",
                    },
                    {
                        "id": "22",
                        "language": "german",
                        "path": "/it/discover/german",
                    },
                    // {
                    //     "id": "23",
                    //     "language": "italian",
                    //     "path": "/it/discover/italian",
                    // },
                    // {
                    //     "id": "24",
                    //     "language": "portuguese",
                    //     "path": "/it/discover/portuguese",
                    // },
                    {
                        "id": "25",
                        "language": "english",
                        "path": "/fr/discover/english",
                    },
                    {
                        "id": "26",
                        "language": "french",
                        "path": "/fr/discover/french",
                    },
                    // {
                    //     "id": "27",
                    //     "language": "polish",
                    //     "path": "/fr/discover/polish",
                    // },
                    // {
                    //     "id": "28",
                    //     "language": "russian",
                    //     "path": "/fr/discover/russian",
                    // },
                    {
                        "id": "29",
                        "language": "spanish",
                        "path": "/fr/discover/spanish",
                    },
                    {
                        "id": "30",
                        "language": "german",
                        "path": "/fr/discover/german",
                    },
                    // {
                    //     "id": "31",
                    //     "language": "italian",
                    //     "path": "/fr/discover/italian",
                    // },
                    // {
                    //     "id": "32",
                    //     "language": "portuguese",
                    //     "path": "/fr/discover/portuguese",
                    // },
                    {
                        "id": "33",
                        "language": "english",
                        "path": "/ru/discover/english",
                    },
                    {
                        "id": "34",
                        "language": "french",
                        "path": "/ru/discover/french",
                    },
                    // {
                    //     "id": "35",
                    //     "language": "polish",
                    //     "path": "/ru/discover/polish",
                    // },
                    // {
                    //     "id": "36",
                    //     "language": "russian",
                    //     "path": "/ru/discover/russian",
                    // },
                    {
                        "id": "37",
                        "language": "spanish",
                        "path": "/ru/discover/spanish",
                    },
                    {
                        "id": "38",
                        "language": "german",
                        "path": "/ru/discover/german",
                    },
                    // {
                    //     "id": "39",
                    //     "language": "italian",
                    //     "path": "/ru/discover/italian",
                    // },
                    // {
                    //     "id": "40",
                    //     "language": "portuguese",
                    //     "path": "/ru/discover/portuguese",
                    // },
                    {
                        "id": "41",
                        "language": "english",
                        "path": "/pl/discover/english",
                    },
                    {
                        "id": "42",
                        "language": "french",
                        "path": "/pl/discover/french",
                    },
                    // {
                    //     "id": "43",
                    //     "language": "polish",
                    //     "path": "/pl/discover/polish",
                    // },
                    // {
                    //     "id": "44",
                    //     "language": "russian",
                    //     "path": "/pl/discover/russian",
                    // },
                    {
                        "id": "45",
                        "language": "spanish",
                        "path": "/pl/discover/spanish",
                    },
                    {
                        "id": "46",
                        "language": "german",
                        "path": "/pl/discover/german",
                    },
                    // {
                    //     "id": "47",
                    //     "language": "italian",
                    //     "path": "/pl/discover/italian",
                    // },
                    // {
                    //     "id": "48",
                    //     "language": "portuguese",
                    //     "path": "/pl/discover/portuguese",
                    // },
                    {
                        "id": "49",
                        "language": "english",
                        "path": "/es/discover/english",
                    },
                    {
                        "id": "50",
                        "language": "french",
                        "path": "/es/discover/french",
                    },
                    // {
                    //     "id": "51",
                    //     "language": "polish",
                    //     "path": "/es/discover/polish",
                    // },
                    // {
                    //     "id": "52",
                    //     "language": "russian",
                    //     "path": "/es/discover/russian",
                    // },
                    {
                        "id": "53",
                        "language": "spanish",
                        "path": "/es/discover/spanish",
                    },
                    {
                        "id": "54",
                        "language": "german",
                        "path": "/es/discover/german",
                    },
                    // {
                    //     "id": "55",
                    //     "language": "italian",
                    //     "path": "/es/discover/italian",
                    // },
                    // {
                    //     "id": "56",
                    //     "language": "portuguese",
                    //     "path": "/es/discover/portuguese",
                    // },
                    {
                        "id": "57",
                        "language": "english",
                        "path": "/pt/discover/english",
                    },
                    {
                        "id": "58",
                        "language": "french",
                        "path": "/pt/discover/french",
                    },
                    // {
                    //     "id": "59",
                    //     "language": "polish",
                    //     "path": "/pt/discover/polish",
                    // },
                    // {
                    //     "id": "60",
                    //     "language": "russian",
                    //     "path": "/pt/discover/russian",
                    // },
                    {
                        "id": "61",
                        "language": "spanish",
                        "path": "/pt/discover/spanish",
                    },
                    {
                        "id": "62",
                        "language": "german",
                        "path": "/pt/discover/german",
                    }
                    // {
                    //     "id": "63",
                    //     "language": "italian",
                    //     "path": "/pt/discover/italian",
                    // },
                    // {
                    //     "id": "64",
                    //     "language": "portuguese",
                    //     "path": "/pt/discover/portuguese",
                    // }
                ],

                "accountLink": [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/account",
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/fr/account",
                    },
                    {
                        "id": "3",
                        "language": "polish",
                        "path": "/pl/account",
                    },
                    {
                        "id": "4",
                        "language": "russian",
                        "path": "/ru/account",
                    },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/es/account",
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/de/account",
                    },
                    {
                        "id": "7",
                        "language": "italian",
                        "path": "/it/account",
                    },
                    {
                        "id": "8",
                        "language": "portuguese",
                        "path": "/pt/account",
                    }
                ],

                "forgotPasswordLink": [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/forgot-password",
                    },
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/forgot-password",
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/fr/forgot-password",
                    },
                    {
                        "id": "3",
                        "language": "polish",
                        "path": "/pl/forgot-password",
                    },
                    {
                        "id": "4",
                        "language": "russian",
                        "path": "/ru/forgot-password",
                    },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/es/forgot-password",
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/de/forgot-password",
                    },
                    {
                        "id": "7",
                        "language": "italian",
                        "path": "/it/forgot-password",
                    },
                    {
                        "id": "8",
                        "language": "portuguese",
                        "path": "/pt/forgot-password",
                    }
                ],

                "resetPasswordLink": [
                    {
                        "id": "1",
                        "language": "english",
                        "path": "/en/reset-password/:id/:token",
                    },
                    {
                        "id": "2",
                        "language": "french",
                        "path": "/fr/reset-password/:id/:token",
                    },
                    {
                        "id": "3",
                        "language": "polish",
                        "path": "/pl/reset-password/:id/:token",
                    },
                    {
                        "id": "4",
                        "language": "russian",
                        "path": "/ru/reset-password/:id/:token",
                    },
                    {
                        "id": "5",
                        "language": "spanish",
                        "path": "/es/reset-password/:id/:token",
                    },
                    {
                        "id": "6",
                        "language": "german",
                        "path": "/de/reset-password/:id/:token",
                    },
                    {
                        "id": "7",
                        "language": "italian",
                        "path": "/it/reset-password/:id/:token",
                    },
                    {
                        "id": "8",
                        "language": "portuguese",
                        "path": "/pt/reset-password/:id/:token",
                    }
                ]
            
            
            }
}

export default data;