import axios from 'axios';

const apiUrl = 'http://localhost:4000'; // Your API URL

// Function to log in the user and get the JWT token
export const updateUserLanguagePage = async (email, languagePage) => {
  try {
    await axios.put(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}/user/language`, { email: email, languagePage: languagePage });

    return true;
  } catch (error) {
    // console.error('Login failed:', error);
    return false;
  }
};