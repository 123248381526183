import React from "react";
import Title from "./Title";

import "../../styles/info.css";

import { LanguagePageContext } from '../../context/LanguagePageContext';
import { useLocation } from 'react-router-dom';


export default function Info() {
    const bookImgPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "discover_screenshot.png";
    const checkImgPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "check.webp";

    const languagePageData = React.useContext(LanguagePageContext);

    const [textReason1, setTextReason1] = React.useState(languagePageData.homePage[0].firstReason) //array of objects  [{"index" : "1", "word" : "Immerse", "set" : "1", "active": false},...]
    const [textReason2, setTextReason2] = React.useState(languagePageData.homePage[0].secondReason) //array of objects  [{"index" : "1", "word" : "Immerse", "set" : "1", "active": false},...]
    const [textReason3, setTextReason3] = React.useState(languagePageData.homePage[0].thirdReason) //array of objects  [{"index" : "1", "word" : "Immerse", "set" : "1", "active": false},...]

    //List of all words first reason
    const createTextReason1 = textReason1.map((word, i) => {
        return(
            <div key={i} className="reason1-container" >
                <p className={`reason1-text ${word.active ? "bold" : ""}`} id={word.set} >{word.word}</p>
            </div>
        );
    })

    const createTextReason2 = textReason2.map((word, i) => {
        return(
            <div key={i} className="reason1-container" >
                <p className={`reason1-text ${word.active ? "bold" : ""}`} id={word.set} >{word.word}</p>
            </div>
        );
    })

    const createTextReason3 = textReason3.map((word, i) => {
        return(
            <div key={i} className="reason1-container" >
                <p className={`reason1-text ${word.active ? "bold" : ""}`} id={word.set} >{word.word}</p>
            </div>
        );
    })

    const location = useLocation()

    React.useEffect(() => {
        setTextReason1(() => languagePageData.homePage[0].firstReason)
        setTextReason2(() => languagePageData.homePage[0].secondReason)
        setTextReason3(() => languagePageData.homePage[0].thirdReason)
      }, [location.key])

    return(
        <article>
            <div className="container mt-5 mb-5">
                {/* ROW 2 */}
                <div className="row">
                    <div className="col-lg-6 col-12 px-4 order-lg-1 order-2 mt-4">
                        <img src={bookImgPath} alt="Book" className="max-size"/>
                    </div>
                    <div className="col-12 col-lg-6 d-flex px-4 order-lg-2 order-1 mt-4">
                        <div className="container">
                            {/* SUBROW 1 */}
                            <div className="row">
                                <Title title={languagePageData.homePage[0].whyTitle}/>
                                <div className="col-10 col-sm-10 px-0 d-flex flex-wrap mt-2">
                                    {createTextReason1}
                                </div>
                                <div className="col-2 col-sm-2 d-flex align-items-center px-2 mt-2">
                                    <img src={checkImgPath} alt="Check" className="check-size img-center"/>
                                </div>
                            </div>
                            {/* SUBROW 2 */}
                            <div className="row mt-3">
                                <div className="col-10 col-sm-10 px-0 text-center d-flex flex-wrap">
                                    {createTextReason2}
                                </div>
                                <div className="col-2 col-sm-2 d-flex align-items-center px-2">
                                    <img src={checkImgPath} alt="Check" className="check-size img-center"/>
                                </div>
                            </div>
                            {/* SUBROW 3 */}
                            <div className="row mt-3">
                                <div className="col-10 col-sm-10 px-0 text-center d-flex flex-wrap">
                                    {createTextReason3}
                                </div>
                                <div className="col-2 col-sm-2 d-flex align-items-center px-2">
                                    <img src={checkImgPath} alt="Check" className="check-size img-center"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
}