import React from "react";

import LogNavBar from "../../layout/LogNavBar";
import Filter from "./Filter";
import { useNavigate, useLocation } from "react-router-dom";
// import storyBrowserData from "../data/storyBrowserData ";
import StoryCardList from "../../components/ui/StoryCardList";
import SearchBar from "./SearchBar";
import "../../styles/dashboard.css";

import {Helmet} from "react-helmet";


import axios from "axios"

import { LanguagePageContext } from "../../context/LanguagePageContext";

export default function Discover(props){

    const [filterData, setFilterData] = React.useState();
    const [backendData, setBackendData] = React.useState([]);
    const [storyCardList, setStoryCardList] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);

    const location = useLocation();

    React.useEffect(() => {
        
        // console.log("getting backend Data");

        axios.get(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}${window.location.pathname}`).then(response => {
            setBackendData(response.data);
            setStoryCardList(response.data.storyCards);

            // setIsLoading(false);
        }).catch( error => {
            // console.log(error);
            // setIsLoading(false);
        });
    }, [location])

    React.useEffect(() => {
        if (filterData !== undefined) {
            // console.log(filterData);
            setIsLoading(true);

            axios.post(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}${window.location.pathname}`, filterData).then(response => {
                setBackendData(response.data);
                setStoryCardList(response.data.storyCards);
                setSearchBar("");
                setIsLoading(false);

                // console.log(response.data)
                // setIsLoading(false);
            }).catch( error => {
                // console.log(error);
                // setIsLoading(false);
                setIsLoading(false);
            });
            
        }
    }, [filterData])

    const lastSlashIndex = window.location.pathname.lastIndexOf("/");
    const pathLength = window.location.pathname.length -1;

    const activeLanguage =  window.location.pathname.slice(lastSlashIndex-pathLength);

    // const currentStoryBrowserData =  storyBrowserData;


    const defaultModalController = [{    "id" : "language",
                                        "show" : false
                                    },
                                    {    "id" : "account",
                                        "show" : false
                                    }] 

    const [modalController, setModalController] = React.useState(defaultModalController);

    function closeModalHandler(event){
        // console.log(event.target)
        setModalController(prev => prev.map((modal) => {
            if (event.target.id !== modal.id) {
                return {...modal,
                        show: false};
            }
            return modal;
        }))
    }

    function closeAllModalHandler() {
        setModalController(prev => prev.map((modal) => {return {...modal, show: false}}))
    }

    function openModalHandler(event) {
        setModalController(prev => prev.map((modal) => {
            if (event.target.id === modal.id) {
                return {...modal,
                        show: true};
            }
            return modal;
        }))
    }



    const [searchBar, setSearchBar] = React.useState("");


    React.useEffect(() => {
        setSearchBar(() => "");
    },[props.language])

    function searchBarHandler(event) {
        setSearchBar(() => event.target.value)
    }

    //UPDATE SEARCH BAR
    React.useEffect(() => {
        if (searchBar === undefined) {
            return;
        }

        if(searchBar.length === 0) {
            setStoryCardList(() => backendData.storyCards);
        }

        if (searchBar.length > 0) {
            setStoryCardList(() => backendData.storyCards.filter((card) => card.title.slice(0, searchBar.length).toLowerCase() === searchBar.toLowerCase()));
        }
 
    }, [searchBar])

    // console.log(backendData);

        return(
            <>
            {backendData.logNavBar === undefined ? <></>:
            <LanguagePageContext.Provider value={backendData}>
                <Helmet>
                <meta charSet="utf-8" />
                <title>{backendData.storyBrowserPage[0].discoverMetaTitle} | Lingotales</title>
                </Helmet>
                <LogNavBar isDiscover={true} activeLanguage={activeLanguage} modalController={modalController} openModalHandler={(event) => openModalHandler(event)}  closeModalHandler={(event) => closeModalHandler(event)}/>
                <div className="container mt-2" onClick={closeAllModalHandler}>
                    <div className="mx-auto" style={{maxWidth: "1100px"}}>
                        <div className="row">
                            <Filter activeLanguage={activeLanguage} setFilterData={setFilterData} isLoading={isLoading}/>
                        </div>

                        <div className="row d-flex justify-content-center">
                            <SearchBar searchBar={searchBar} searchBarText={backendData.storyBrowserPage[0].searchBarText} searchBarHandler={(event) => searchBarHandler(event)}/>
                        </div>

                        <div className="row">
                            <StoryCardList storyCards={storyCardList} />
                            {storyCardList.length === 0 && <h2 className="text-center mt-2 mb-5">{backendData.storyBrowserPage[0].noStoriesText}</h2>}
                        </div>
                    </div>
                </div>
            </LanguagePageContext.Provider>
            }
            </>
        );
}