import React from "react";
import "../../styles/button.css"



export default function Button(props) {

    const shadow = props.isFooter ? "" : "shadow-button";
    
    return(
        
        <>
        {(props.isDisable && !props.isNavBar && !props.isModal) &&
            <button className="button--disable-container bright-button" onClick={props.buttonHandler} disabled>
                <p className="button-message">{props.content}</p>
            </button> }
        {(!props.isDisable && !props.isNavBar && !props.isModal) &&
        <button className={`button-container bright-button ${shadow}`} onClick={props.buttonHandler} id={props.id}>
            <p className="button-message">{props.content}</p>
        </button> }
        {props.isNavBar && 
        <button className={`button-container bright-button ${shadow}`} onClick={props.buttonHandler} id={props.id}>
            <p className="nav-button-message">{props.content}</p>
        </button> }
        {props.isModal && 
        <button className={`button-container bright-button ${shadow}`} onClick={props.buttonHandler} id={props.idModal}>
            <p className="nav-button-message">{props.content}</p>
        </button> }
            
        </>
        
    );
}