import React from "react";

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Button from "../ui/Button";

import { LanguagePageContext } from '../../context/LanguagePageContext';

import "../../styles/selectlanguagemodal.css";

import { useNavigate } from "react-router-dom";

export default function SelectLanguageModal(props){

    const navigate = useNavigate();

    const languagePageData = React.useContext(LanguagePageContext);


    function languagePageSelectorHandler(event) {

        let root = ""

        if (    event.target.id === "English" || event.target.id === "Anglais" || event.target.id === "Inglese" || event.target.id === "Englisch" ||
                event.target.id === "Английский" || event.target.id === "Angielski" || event.target.id === "Inglês" || event.target.id === "Inglés" ) 
                {
                    root = "/en";
        }

        else if (   event.target.id === "Spanish" || event.target.id === "Espagnol" || event.target.id === "Spagnolo" || event.target.id === "Spanisch" ||
                    event.target.id === "Испанский" || event.target.id === "Hiszpański" || event.target.id === "Espanhol" || event.target.id === "Español" ) 
                    {            
                        root = "/es";
        }

        else if (   event.target.id === "French" || event.target.id === "Français" || event.target.id === "Francese" || event.target.id === "Französisch" ||
                    event.target.id === "Французский" || event.target.id === "Francuski" || event.target.id === "Francês" || event.target.id === "Francés" ) 
                    {            
                        root = "/fr";
        }

        else if (   event.target.id === "German" || event.target.id === "Allemand" || event.target.id === "Tedesco" || event.target.id === "Deutsch" ||
                    event.target.id === "Немецкий" || event.target.id === "Niemiecki" || event.target.id === "Alemão" || event.target.id === "Alemán" ) 
                    {            
                        root = "/de";
        }

        else if (   event.target.id === "Italian" || event.target.id === "Italien" || event.target.id === "Italiano" || event.target.id === "Italienisch" ||
                    event.target.id === "Итальянский" || event.target.id === "Włoski" || event.target.id === "Italiano" || event.target.id === "Italiano" ) 
                    {            
                        root = "/it";
        }

        else if (   event.target.id === "Polish" || event.target.id === "Polonais" || event.target.id === "Polacco" || event.target.id === "Polnisch" ||
                    event.target.id === "Польский" || event.target.id === "Polski" || event.target.id === "Polaco" || event.target.id === "Polaco" ) 
                    {            
                        root = "/pl";
        }

        else if (   event.target.id === "Russian" || event.target.id === "Russe" || event.target.id === "Russo" || event.target.id === "Russisch" ||
                    event.target.id === "Русский" || event.target.id === "Rosyjski" || event.target.id === "Russo" || event.target.id === "Ruso" ) 
                    {            
                        root = "/ru";
        }

        else if (   event.target.id === "Portuguese" || event.target.id === "Portugais" || event.target.id === "Portoghese" || event.target.id === "Portugiesisch" ||
                    event.target.id === "Португальский" || event.target.id === "Portugalski" || event.target.id === "Português" || event.target.id === "Portugués" ) 
                    {            
                        root = "/pt";
        }

        let currentPath = window.location.pathname;

        if (currentPath === "/") {
            currentPath = root;
        }

        else if (currentPath === "/blog") {
            currentPath = root + "/blog";
        }

        else if (currentPath === "/about") {
            currentPath = root + "/about";
        }

        else if (currentPath === "/pricing") {
            currentPath = root + "/pricing";
        }

        else if (currentPath === "/login") {
            currentPath = root + "/login";
        }

        else if (currentPath === "/register") {
            currentPath = root + "/register";
        }

        else if (currentPath === "/help") {
            currentPath = root + "/help";
        }

        else if (currentPath.slice(0,8) === "/stories") {
            currentPath = root + currentPath;
        }

        else {
            currentPath = root + currentPath.slice(3, currentPath.length);
        }

        props.onHide();
        
        navigate(currentPath);
        navigate(0)

    }

    let langList;

    if (languagePageData.languageCodes !== undefined) {
        langList = languagePageData.languageCodes.map((lang, i) => {
            return(
                <Col key={i} xs={3} md={3} className="modal-col p-1">
                    <Button content={lang.languageName} isModal={true} buttonHandler={(event) => languagePageSelectorHandler(event)} idModal={lang.languageName}/>
                </Col>
            );
        })
    }
    else {
        langList = languagePageData.languageCodes.map((lang, i) => {
            return(
                <Col key={i} xs={3} md={3} className="modal-col">
                    <Button content={lang.languageName} isModal={true} buttonHandler={(event) => languagePageSelectorHandler(event)} idModal={lang.languageName}/>
                </Col>
            );
        })

    }

    


    return(
        <Modal {...props} id="main-modal" dialogClassName="modal-100w">
            <Modal.Body className="px-1 py-1">
                <Container>
                <Row>
                    {langList}
                </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}