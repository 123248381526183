import React from "react";
import Button from "../../components/ui/Button";

import "../../styles/paragraph.css";

import { LanguagePageContext } from "../../context/LanguagePageContext";

import AudioPlayer from "../../components/ui/AudioPlayer";




export default function Paragraph(props) {

    const audioFile = process.env.REACT_APP_PUBLIC_URL_AUDIO + props.audio;

    const languagePageData = React.useContext(LanguagePageContext);

    
    const divRef = React.useRef(null);

    React.useEffect(() => {
        divRef.current.scrollIntoView({ behavior: 'smooth' });
    },[]);

    

    //List of all words eslogan
    const generateText = props.text.map((word, i) => {
        return(
            <div key={i} className="paragraph-word-container">
                <p className={`paragraph-word-text ${word.active ? "bold" : ""}`} id={word.index} onClick={props.selectWord}>{word.word}</p>
            </div>
        );
    })


    return(
        <div ref={divRef} className={`paragraph-container px-3 my-2 ${props.levelRef}-paragraph-container`}>
            <p className="note-message">{languagePageData.storyPage[0].tipPrompt}</p>
            {/* <p className="paragraph-text">{props.text}</p> */}
            <div className="d-flex flex-wrap justify-content-start mt-1">
                {generateText}
            </div>
            
            <div className="row d-flex align-items-center mb-3">
                <div className="col-sm-4 col-12 mt-2">
                    <AudioPlayer audioFile={audioFile}/>
                </div>
                <div className="col-sm-4 col-6 mt-2">
                    <Button content={props.isActive ? languagePageData.storyPage[0].hideTransButtonPrompt : languagePageData.storyPage[0].translationButtonPrompt} isDisable={false} buttonHandler={props.clickHandler} id={props.paragraphId}/>
                </div>
                <div className="col-sm-3 col-5 mt-2 px-0">
                    <Button content={props.isLastParagraph ? languagePageData.storyPage[0].finishButtonPrompt : languagePageData.storyPage[0].continueButtonPrompt} isDisable={props.disableContinueBtn} buttonHandler={props.continueHandler} id={props.paragraphId}/>
                </div>
                
            </div>
        </div>
        
    );
}