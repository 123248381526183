import React from "react";

import NavBarExt from "../../layout/NavBarExt";
import BlogCard from "./BlogCard";

import "../../styles/blog.css"

import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/esm/Container";

import { LanguagePageContext } from "../../context/LanguagePageContext";

import {Helmet} from "react-helmet";

import useAxios from "../../hooks/useAxios";
import axios from '../../apis/lingotalesApi';
//import useFetch from "../hooks/useFetch";

export default function Blog() {

    const [response, loading, error, fetchData] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        url: process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL + window.location.pathname,
        requestConfig: {
            headers: {
                'Content-Language': 'en-US',
            }
        }
    });

    const navigate = useNavigate();

    React.useEffect(() => {
        fetchData();
    },[])

    const selectBlogHandler = (event) => {
        const titleParam = encodeURIComponent(event.target.id);
        navigate(`${window.location.pathname}/post?title=${titleParam}`);
    }

    if (loading) {
        return null;
    }
    

    let blogList;

    if (response.blog !== undefined ) {
        blogList = response.blog.map((card, i) => {
            return <BlogCard title={card.title} author={card.author} dateString={card.dateString} image={card.image} id={card.blogId} key={i} handleClick={(event) => selectBlogHandler(event)}/>
        })
    }



    return(
        <LanguagePageContext.Provider value={response}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{response.blogBrowserPage[0].blogTitle} | Lingotales</title>
                <meta
                name="description"
                content= {response.blogBrowserPage[0].blogMetaContent}
                />
                <link rel="canonical" href="https://lingotales.com/blog"/>
                <link rel="alternate" hreflang="en" href="https://lingotales.com/en/blog"/>
                <link rel="alternate" hreflang="de" href="https://lingotales.com/de/blog"/>
                <link rel="alternate" hreflang="fr" href="https://lingotales.com/fr/blog"/>
                <link rel="alternate" hreflang="es" href="https://lingotales.com/es/blog"/>
                <link rel="alternate" hreflang="it" href="https://lingotales.com/it/blog"/>
                <link rel="alternate" hreflang="pl" href="https://lingotales.com/pl/blog"/>
                <link rel="alternate" hreflang="pt" href="https://lingotales.com/pt/blog"/>
                <link rel="alternate" hreflang="ru" href="https://lingotales.com/ru/blog"/>
            </Helmet>
            <NavBarExt isBlog={true}/>
            <Container className="d-flex justify-content-center">
                <div className="row">
                    <div className="mx-auto col-12 text-center blog-page-title-container mt-4" style={{maxWidth: "200px"}}>
                        <h1 className="blog-page-title mb-1 mt-1">{response.blogBrowserPage[0].blogTitle}</h1>
                    </div>
                    
                    <div className="col-12 text-center">
                        <h4 className="blog-page-subtitle mt-3">{response.blogBrowserPage[0].blogSubtitle}</h4>
                        {blogList}
                    </div>
                    
                </div>
                
            </Container>
        </LanguagePageContext.Provider>
    );
}