import React from "react";

export default function useAxios(configObj, dependencyCallback) {

    const {
        axiosInstance,
        method,
        url,
        data,
        requestConfig = {}
    } = configObj;

    const [response, setResponse] = React.useState({});
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    const controller = new AbortController();

    const fetchData = async () => {
        try {  

            const res = await axiosInstance["get"](url, {
                ...requestConfig,
                signal: controller.signal
            });
            
            setResponse(res.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const performPost = async () => {
        try {
            const res = await axiosInstance[method.toLowerCase()](url, data, {
                ...requestConfig,
                signal: controller.signal
            });
            setResponse(res.data);
            if (dependencyCallback) {
                dependencyCallback();
            }
        } catch (err) {
            // console.error("Error performing POST:", err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        return () => {
            controller.abort();
        };
    }, []);

    return  [response, loading, error, fetchData, performPost];
}

