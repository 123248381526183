import React from "react";
import "../styles/headerstories.css";
import Flag from "../components/ui/Flag";
import Button from "../components/ui/Button";

import DropdownLanguage from "../components/ui/DropdownLanguage";
// import flagData from "../data/flagData";
import "../styles/flaglist.css"

import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/esm/Container";

import { LanguagePageContext } from '../context/LanguagePageContext';
import SelectLanguageModal from '../components/modals/SelectLanguageModal';

export default function HeaderStories(props) {

    const logoPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "mice.png";

    const languagePageData = React.useContext(LanguagePageContext);

    const navigate = useNavigate();

    const [modalShow, setModalShow] = React.useState(false);

    function languageSelecthandler(event) {
        if (event.target.id === undefined || event.target.id === "" ) {
            return;
        }
        // navigate(`/stories/${event.target.id}`);

        // Get the all the path previous stories/
        const substring = "stories/";
        const currentPath = window.location.pathname.slice(0, window.location.pathname.indexOf(substring) + substring.length) + event.target.id; // id is the active language

        navigate(currentPath);
        // navigate(0);
    }

    const languageList = languagePageData.flags.map((flag, i) => {
        const currentLanguage = flag.language.toLowerCase();

        return(
            <div key={i} className="col-sm-1 col-2 d-flex align-items-center flex-column mx-3" onClick={languageSelecthandler}>
                <Flag id={currentLanguage} fileName={flag.fileName} format={"header-stories"}/>
                {props.activeLanguage === currentLanguage && <p className="caption-size emphasis" id={currentLanguage} >{flag.label} </p>}
                {props.activeLanguage !== currentLanguage && <p className="caption-size" id={currentLanguage}>{flag.label} </p>}
            </div>)
    })

    function redirection(address) {


        let currentPath = window.location.pathname;

        if ((currentPath === "/" && address !== "/") || (currentPath === "/blog" && address !== "/blog")|| 
            (currentPath === "/login" && address !== "/login") || (currentPath === "/register" && address !== "/register")) {
            currentPath = address;
        }
        else if (   currentPath === "/en" || currentPath === "/de" || currentPath === "/fr" || currentPath === "/ru" ||
                    currentPath === "/it" || currentPath === "/pt" || currentPath === "/pl" || currentPath === "/es") {

            if (address === "/") {
                return;
            }

            currentPath = currentPath + address;
        }

        else if (currentPath.length > 3) {
            if (currentPath.slice(-address.length) === address){
                return
            }

            const root = "/stories/";

            if (currentPath.slice(0, root.length) === root) {
                currentPath = address;
            }

            else if (address !== "/") {
                currentPath = currentPath.slice(0, 3) + address;
            }

            // go back home
            else{
                currentPath = currentPath.slice(0, 3);
            }
        }

        else {
            return;
        }

        navigate(currentPath);
    }

    

    return(
        <nav>
            <Container className="d-flex justify-content-center">
                <div className="w-100" style={{maxWidth: "1400px"}}>
                    <div className='row align-items-center my-2 px-2'>
                        
                        <div className="col-md-3 col-4 d-flex order-1 order-md-1 justify-content-center" onClick={() => redirection("/")}>
                            <img className='nav-logo' src={logoPath}/>
                            <p className='nav-name' >LingoTales</p>
                        </div>

                        <div className="col-md-6 col-12 d-flex order-4 order-md-2 justify-content-center">
                            <div className="container">
                                <div className="row ">
                                    <div className="d-flex align-items-center flex-column">
                                        <p className='header-nav-title d-md-block d-none' onClick={() => redirection("/stories/english")}>{languagePageData.navBar[0].navGeneralStoryTitle}</p>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center mt-2">
                                    {languageList}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-1 col-4 order-2 order-md-3 d-flex justify-content-center dropdown">
                            {!props.isStoryPage && <p className='header-nav-item hover-underline-animation' onClick={() =>setModalShow(true)}>{languagePageData.navBar[0].navLanguage}</p>}
                        </div>

                        <div className="col-md-2 col-4 order-3 order-md-4 d-flex justify-content-center">
                            {/* <p className='header-nav-item hover-underline-animation' onClick={() => redirection("/login")}>{languagePageData.navBar[0].navLogin}</p> */}
                            <Button content={languagePageData.navBar[0].navLogin} buttonHandler={() => redirection("/login")} isNavBar={true}/>
                        </div>

                        <SelectLanguageModal show={modalShow} onHide={() => setModalShow(false)}/>
                    </div>
                </div>
            </Container>
        </nav>
    );
}