import React from "react";
import "../styles/secondaryfooter.css";

import { Link } from "react-router-dom";
import {getUrlRoot} from "../utils/utils.js"

import { LanguagePageContext } from '../context/LanguagePageContext';


export default function SecondaryFooter() {
    const languagePageData = React.useContext(LanguagePageContext);

    return(
        <footer className="dark-background-color">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className=" secondary-footer-container">
                        <p className="secondary-footer-text">© LingoTales, {languagePageData.footer.year} |  <Link reloadDocument to={`${getUrlRoot()}/terms`} className="footer-items" target="_blank" rel="noopener noreferrer">{languagePageData.footer.termsText}</Link> | <Link reloadDocument to={`${getUrlRoot()}/privacy`} className="footer-items" target="_blank" rel="noopener noreferrer">{languagePageData.footer.privacyText}</Link> | {languagePageData.footer.contactText}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}