import React from "react";
import NavBarExt from "../../layout/NavBarExt";
import Button from "../../components/ui/Button";
import SecondaryFooter from "../../layout/SecondaryFooter";
import { useNavigate } from "react-router-dom";
import { LanguagePageContext } from "../../context/LanguagePageContext";
import {Helmet} from "react-helmet";
import "../../styles/about.css";

import useAxios from "../../hooks/useAxios";
import axios from '../../apis/lingotalesApi';


export default function About() {

    const [response, loading, error, fetchData] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        url: process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL + window.location.pathname,
        requestConfig: {
            headers: {
                'Content-Language': 'en-US',
            }
        }
    });

    React.useEffect(() => {
        fetchData();
    },[])

    const navigate = useNavigate();

    if (loading) {return null}


    const sectionList =  response.aboutPage[0].aboutInfo.map((section, i) => {

        return(
            <div key={i}>
                <h4 className="about-page-subtitle mt-3"><span className="subtitle-number-tag">{section.subtitleIndex}.</span> {section.subtitle} </h4>
                <p className="about-subtitle-text">{section.paragraph}</p>
            </div>
        );
    })

    

    let registerAddres = "/";

    function redirection() {
        const root = window.location.pathname.slice(0, 4)
        if (    root === "/en/" || root === "/de/" || root === "/ru/" || root === "/pl/" || 
                root === "/fr/" || root === "/it/" || root === "/es/" || root === "/pt/") {
                    registerAddres = root;
                }
                
        navigate(registerAddres + "register");
    }

    return(
        <LanguagePageContext.Provider value={response}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{response.aboutPage[0].aboutMetaTitle} | Lingotales</title>
                <meta
                name="description"
                content= {response.aboutPage[0].aboutMetaContent}
                />
                <link rel="canonical" href="https://lingotales.com/about"/>
                <link rel="alternate" hreflang="en" href="https://lingotales.com/en/about"/>
                <link rel="alternate" hreflang="de" href="https://lingotales.com/de/about"/>
                <link rel="alternate" hreflang="fr" href="https://lingotales.com/fr/about"/>
                <link rel="alternate" hreflang="es" href="https://lingotales.com/es/about"/>
                <link rel="alternate" hreflang="it" href="https://lingotales.com/it/about"/>
                <link rel="alternate" hreflang="pl" href="https://lingotales.com/pl/about"/>
                <link rel="alternate" hreflang="pt" href="https://lingotales.com/pt/about"/>
                <link rel="alternate" hreflang="ru" href="https://lingotales.com/ru/about"/>
            </Helmet>
            <NavBarExt isHome={true}/>
            <div className="container mx-auto" style={{maxWidth: "900px"}}>
                <div className="row px-2 my-2" style={{maxWidth: "330px"}}>
                    <div className="text-center about-page-title-container d-flex align-items-center justify-content-center mt-2">
                        <h1 className="about-page-title mb-1 mt-1">{response.aboutPage[0].aboutTitle}</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="text-left about-page-subtitle-container">
                        {sectionList}
                    </div>
                </div>

                <div className="row">
                    <div className="d-flex justify-content-center align-items-center my-3 mx-auto" style={{maxWidth: "240px"}}>
                        <Button content={response.aboutPage[0].aboutJoinButtonContent } buttonHandler={() => redirection()}/>
                    </div>
                </div>
            </div>
            <SecondaryFooter/>
        </LanguagePageContext.Provider>  
    );
}