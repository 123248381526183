import React from "react";
import Hero from "./Hero";
import NavBarExt from "../../layout/NavBarExt";
import ResponsiveNavbar from "../../layout/ResponsiveNavBar";
import Info from "./Info";
import LanguageList from "./LanguagesList";
import Footer from "../../layout/Footer";

import Container from "react-bootstrap/esm/Container";

import { LanguagePageContext } from "../../context/LanguagePageContext";

import useAxios from "../../hooks/useAxios";
import axios from '../../apis/lingotalesApi';

import {Helmet} from "react-helmet";

export default function Start() {

    const [backendData, loading, error, fetchData] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        url: process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL + window.location.pathname,
        requestConfig: {
            headers: {
                'Content-Language': 'en-US',
            }
        }
    });

    React.useEffect(() => {
        fetchData();
    },[])


    if (loading) {
        return null;
    }

    // console.log(backendData)

    return(
        <LanguagePageContext.Provider value={backendData}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{backendData.homePage[0].homeMetaTitle} | Lingotales</title>
                <meta
                name="description"
                content= {backendData.homePage[0].homeMetaContent}
                
                />
                <link rel="canonical" href="https://lingotales.com/"/>
                <link rel="alternate" hreflang="en" href="https://lingotales.com/en"/>
                <link rel="alternate" hreflang="de" href="https://lingotales.com/de"/>
                <link rel="alternate" hreflang="fr" href="https://lingotales.com/fr"/>
                <link rel="alternate" hreflang="es" href="https://lingotales.com/es"/>
                <link rel="alternate" hreflang="it" href="https://lingotales.com/it"/>
                <link rel="alternate" hreflang="pl" href="https://lingotales.com/pl"/>
                <link rel="alternate" hreflang="pt" href="https://lingotales.com/pt"/>
                <link rel="alternate" hreflang="ru" href="https://lingotales.com/ru"/>
            </Helmet>
            <NavBarExt isHome={true}/>
            {/* <ResponsiveNavbar/> */}
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "1200px"}}>
                    <Hero />
                    <Info/>
                    {/* <LanguageList/> */}
                </div>
            </Container>
            <Footer/>
        </LanguagePageContext.Provider>
    );
}