import React from "react";
import "../styles/loading.css";


export default function Loading() {
    const root = window.location.pathname.slice(0, 3);
    let loadingText;

    if (root === "/en") {
        loadingText = "The story will start soon!";
    }
    else if (root === "/de") {
        loadingText = "Die Geschichte wird bald beginnen!";
    }
    else if (root === "/ru") {
        loadingText = "История скоро начнется!";
    }
    else if (root === "/it") {
        loadingText = "Il racconto inizierà presto!";
    }
    else if (root === "/fr") {
        loadingText = "L'histoire va bientôt commencer !";
    }
    else if (root === "/pt") {
        loadingText = "O conto vai começar em breve!";
    }
    else if (root === "/pl") {
        loadingText = "Historia rozpocznie się wkrótce!";
    }
    else if (root === "/es") {
        loadingText = "¡La historia empezará pronto!";
    }
    else {
        loadingText = "The story will start soon!";
    }

    return(
        <div className="loading-container mx-2">
            <h1 className="loading-title text-center">{loadingText}</h1>
            <div className="loading"></div>
        </div>
    );
}