import React from "react";
import NavBarExt from "../../layout/NavBarExt";
import { useForm } from "react-hook-form";
import Button from "../../components/ui/Button";
import "../../styles/login.css";

import { LanguagePageContext } from "../../context/LanguagePageContext";

import Container from "react-bootstrap/esm/Container";

import useAxios from "../../hooks/useAxios";
import axios from '../../apis/lingotalesApi';

import { login, isLoggedIn, getCsrfToken } from '../../utils/auth';
import { useNavigate } from "react-router-dom";

import {getUrlRoot} from "../../utils/utils.js";

import {Helmet} from "react-helmet";



export default function Login() {

    const {register, handleSubmit, reset, setValue} = useForm();
    const navigate = useNavigate();
    const [loginFailed, setLoginFailed] = React.useState(false);

    const [backendData, loading, error, fetchData] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        url: process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL + window.location.pathname,
        requestConfig: {
            headers: {
                'Content-Language': 'en-US',
            }
        }
    });

    React.useEffect(() => {
        if (isLoggedIn()) {
            const root = getUrlRoot();
            navigate(`${root}/home`, { replace: true });
        }
        
        fetchData();
    },[])

    if (loading) {
        return null;
    }


    async function submitHandler(data) {
        const {email, password} = data;

        // const csrfToken = await getCsrfToken();

        // if (csrfToken === null) {
        //     console.log("Login failed");
        //     setLoginFailed(true);
        //     setValue("password", "");
        //     return;
        // }

        const success = await login(email, password);

        if (success) {
            // console.log("Login successful!");
            localStorage.setItem('email', JSON.stringify(email));
            // console.log(isLoggedIn())
        } else {
            // console.log("Login failed");
            setLoginFailed(true);
            setValue("password", "");
        }

        if (isLoggedIn()) {
            // const root = getUrlRoot();
            const languagePage = localStorage.getItem("languagePage");
            navigate(`/${languagePage}/home`, { replace: true });


            // navigate(`${root}/home`, { replace: true });
        }
    }

    const lockIcon = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "lock.webp";
    const usernameIcon = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "username.jpg";
    const emailIcon = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "email.webp";

    return(
        <LanguagePageContext.Provider value={backendData}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{backendData.loginPage[0].form.logInButtonContent} | Lingotales</title>
            </Helmet>
            <NavBarExt isLogin={true}/>
            <Container className="d-flex justify-content-center">
                <div className="row mt-1" style={{maxWidth: "700px"}}>
                    <div className="login-show">
                        <form onSubmit={handleSubmit(submitHandler)} className="text-center">
                            <h6 className="login-title mt-3 mb-3">{backendData.loginPage[0].loginTitle}</h6>
                            {loginFailed ? <p className="login-error-message">{backendData.loginPage[0].form.loginErrorMessage}</p> : <></>}
                            <div className="my-form-group register-input-container">
                                <img src={emailIcon} atl="input_icon" className="input-icon"/>
                                <input type="email" className="register-input" id="email" placeholder={backendData.loginPage[0].form.emailPlaceholder} {...register("email", {required : true})}/>
                            </div>
                            <div className="my-form-group register-input-container">
                                <img src={lockIcon} atl="input_icon" className="input-icon-lock"/>
                                <input type="password" className="register-input" id="password" placeholder={backendData.loginPage[0].form.passwordPlaceholder} {...register("password", {required : true})}/>
                            </div>
                            <a href={window.location.pathname.slice(0, -5) + "forgot-password"} className={"to-login-hypertext"}>{backendData.loginPage[0].form.forgotPasswordPrompt}</a>   
                            <a href={window.location.pathname.slice(0, -5) + "register"} className={"to-login-hypertext mt-1 mb-1"}>{backendData.loginPage[0].form.accountPrompt}</a>   
                            <div className="mt-2 mx-auto" style={{maxWidth: "140px"}}>
                                <Button content={backendData.loginPage[0].form.logInButtonContent} isFooter={false} isDisable={false}/>
                            </div>
                        </form>
                    </div>
                </div>
            </Container>
        </LanguagePageContext.Provider>
    );
}