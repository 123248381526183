import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import "../../styles/textgeneration.css";
import axios from 'axios';

export default function TextGeneration(){

    const [englishText, setEnglishText] = React.useState([]);
    const [spanishText, setSpanishText] = React.useState([]);
    const [germanText, setGermanText] = React.useState([]);
    const [frenchText, setFrenchText] = React.useState([]);

    const [targetLanguageText, setTargetLanguageText] = React.useState("");
    const [translateLanguageText, setTranslateLanguageText] = React.useState("");

    const targetSelectedWord = React.useRef("");
    const translateSelectedWord = React.useRef("");

    const [targetSetWord, setTargetSetWord] = React.useState([]);
    const [translateSetWord, setTranslateWord] = React.useState([]);

    const [lastTargetSetWord, setLastTargetSetWord] = React.useState([]);
    const [lastTranslateSetWord, setLastTranslateWord] = React.useState([]);

    const [targetSetWordIndex, setTargetSetWordIndex] = React.useState([]);
    const [translateSetWordIndex, setTranslateWordIndex] = React.useState([]);

    const [pairedSetText, setpairedSetText] = React.useState("");

    const [currentPair, setCurrentPair] = React.useState();

    const defaultPairState = {"en_es": false,
                        "en_de": false,
                        "en_fr": false,
                        "es_de": false,
                        "es_fr": false,
                        "de_fr": false
                        };

    const [pairState, setPairState] = React.useState(defaultPairState);


    function saveTextHandler(language) {
        if (language=== "german") {
            setGermanText(translateLanguageText.split('\\b'));
        }
        if (language=== "english") {
            setEnglishText(translateLanguageText.split('\\b'));
        }
        if (language=== "spanish") {
            setSpanishText(translateLanguageText.split('\\b'));
        }
        if (language=== "french") {
            setFrenchText(translateLanguageText.split('\\b'));
        }
    }

    function handleTargetText(event) {
        setTargetLanguageText(event.target.value);
    }

    function handleTranslateText(event) {
        setTranslateLanguageText(event.target.value);
    }

    function handleWordClick(clickedWord, index) {
        targetSelectedWord.current = clickedWord;
        setTargetSetWord(prev => [...prev, targetSelectedWord.current]);
        setTargetSetWordIndex(prev => [...prev, index]);
    }

    function handleTranslateWordClick(clickedWord, index) {
        translateSelectedWord.current = clickedWord;
        setTranslateWord(prev => [...prev, translateSelectedWord.current]);
        setTranslateWordIndex(prev => [...prev, index]);

    }

    const modifiedTargetText = targetLanguageText.replace(/\\b/g, ' ');
    const modifiedTranslateText = translateLanguageText.replace(/\\b/g, ' ');

    const targetWords = modifiedTargetText.split(/\s+/).map((word, index) => (
        <p key={index} onClick={() => handleWordClick(word, index)} className="clickable-word-text-generation">
            {index} {word} {""}
        </p>
    ));

    const translateWords = modifiedTranslateText.split(/\s+/).map((word, index) => (
        <p key={index} onClick={() => handleTranslateWordClick(word, index)} className="clickable-word-text-generation">
            {index} {word} {""}
        </p>
    ));
    


    const handlePair = () => {
        if (targetSetWordIndex.length === 0 || translateSetWordIndex.length === 0 || currentPair ===undefined) {
            return;
        }
        const boilerTemplate = `"${currentPair}" : {"targetLanguageIndex" : [${targetSetWordIndex}], "translateLanguageIndex": [${translateSetWordIndex}]}\n`;

        setpairedSetText(prev => {
            return prev + boilerTemplate;
        })

        setLastTargetSetWord(targetSetWord)
        setLastTranslateWord(translateSetWord)

        setTargetSetWord("");
        setTranslateWord("");

        setTranslateWordIndex("");
        setTargetSetWordIndex("")
    }

    const handleClear = () => {
        setTargetSetWord("");
        setTranslateWord("");

        setTranslateWordIndex("");
        setTargetSetWordIndex("")
    }

    const generateHandle = async () => {
        // for (const key in pairState) {
        //     if (pairState.hasOwnProperty(key)) {
        //         if (!pairState[key]) return;
        //     }
        // }
   
        try {
            const res = await axios.post(`http://localhost:4000/generation/execute`, { englishText:englishText, spanishText:spanishText, germanText:germanText, frenchText:frenchText });
            // console.log(res);
        } catch (error) {
            // console.error('Error saving data to file:', error);
        }

        setPairState(defaultPairState);
        setpairedSetText("");
    }

    const removeLastSentence = () => {
        const sentences = pairedSetText.trim().split('\n');
        sentences.pop();
        setpairedSetText(sentences.join('\n'));
    };

    const doneEnglishSpanish = async () => {
        setPairState(prev => {return {...prev, "en_es": true } });
        
        try {
            const inverseText = pairedSetText.replace(/englishSpanishSet/g, 'spanishEnglishSet')
                                .replace(/targetLanguageIndex/g, 'tempLanguageIndex')
                                .replace(/translateLanguageIndex/g, 'targetLanguageIndex')
                                .replace(/tempLanguageIndex/g, 'translateLanguageIndex');

            const textObj = createJSONObject(pairedSetText);
            const inverseTextObj = createJSONObject(inverseText);

            // Get the full match pair object
            const englishSpanishSetObject = completeMatchObject(textObj, "englishSpanishSet");
            const spanishEnglishSetObject = completeMatchObject(inverseTextObj, "spanishEnglishSet");
        
            const res = await axios.post(`http://localhost:4000${window.location.pathname}`, { set: englishSpanishSetObject, inverseSet:spanishEnglishSetObject,  fileName:"en_es", inverseFileName:"es_en"});
            
            if (res.data.successful) {
                // console.log('Data saved to file successfully');
                setEnglishText(targetLanguageText.split('\\b'));
                setSpanishText(translateLanguageText.split('\\b'));
                setpairedSetText("");
                setCurrentPair("englishGermanSet");
            }
            
        } catch (error) {
            // console.error('Error saving data to file:', error);
        }

    }

    const doneEnglishGerman = async () => {
        setPairState(prev => {return {...prev, "en_de": true } });
        
        try {
            const inverseText = pairedSetText.replace(/englishGermanSet/g, 'germanEnglishSet')
                                .replace(/targetLanguageIndex/g, 'tempLanguageIndex')
                                .replace(/translateLanguageIndex/g, 'targetLanguageIndex')
                                .replace(/tempLanguageIndex/g, 'translateLanguageIndex');

            const textObj = createJSONObject(pairedSetText);
            const inverseTextObj = createJSONObject(inverseText);

            // Get the full match pair object
            const englishGermanSetObject = completeMatchObject(textObj, "englishGermanSet");
            const germanEnglishSetObject = completeMatchObject(inverseTextObj, "germanEnglishSet");
        
            const res = await axios.post(`http://localhost:4000${window.location.pathname}`, { set: englishGermanSetObject, inverseSet: germanEnglishSetObject,  fileName:"en_de", inverseFileName:"de_en"});
            
            if (res.data.successful) {
                // console.log('Data saved to file successfully');
                setGermanText(translateLanguageText.split('\\b'));
                setpairedSetText("");
                setCurrentPair("englishFrenchSet");
            }
            
        } catch (error) {
            // console.error('Error saving data to file:', error);
        }

    }

    const doneEnglishFrench = async () => {
        setPairState(prev => {return {...prev, "en_fr": true } });
        
        try {
            const inverseText = pairedSetText.replace(/englishFrenchSet/g, 'frenchEnglishSet')
                                .replace(/targetLanguageIndex/g, 'tempLanguageIndex')
                                .replace(/translateLanguageIndex/g, 'targetLanguageIndex')
                                .replace(/tempLanguageIndex/g, 'translateLanguageIndex');

            const textObj = createJSONObject(pairedSetText);
            const inverseTextObj = createJSONObject(inverseText);

            // Get the full match pair object
            const englishFrenchSetObject = completeMatchObject(textObj, "englishFrenchSet");
            const frenchEnglishSetObject = completeMatchObject(inverseTextObj, "frenchEnglishSet");
        
            const res = await axios.post(`http://localhost:4000${window.location.pathname}`, { set: englishFrenchSetObject, inverseSet: frenchEnglishSetObject,  fileName:"en_fr", inverseFileName:"fr_en"});
            
            if (res.data.successful) {
                // console.log('Data saved to file successfully');
                setFrenchText(translateLanguageText.split('\\b'));
                setpairedSetText("");
                setCurrentPair("spanishGermanSet");
            }
            
        } catch (error) {
            // console.error('Error saving data to file:', error);
        }

    }

    const doneSpanishGerman = async () => {
        setPairState(prev => {return {...prev, "es_de": true } });
        
        try {
            const inverseText = pairedSetText.replace(/spanishGermanSet/g, 'germanSpanishSet')
                                .replace(/targetLanguageIndex/g, 'tempLanguageIndex')
                                .replace(/translateLanguageIndex/g, 'targetLanguageIndex')
                                .replace(/tempLanguageIndex/g, 'translateLanguageIndex');

            const textObj = createJSONObject(pairedSetText);
            const inverseTextObj = createJSONObject(inverseText);

            // Get the full match pair object
            const spanishGermanSetObject = completeMatchObject(textObj, "spanishGermanSet");
            const germanSpanishSetObject = completeMatchObject(inverseTextObj, "germanSpanishSet");
        
            const res = await axios.post(`http://localhost:4000${window.location.pathname}`, { set: spanishGermanSetObject, inverseSet: germanSpanishSetObject,  fileName:"es_de", inverseFileName:"de_es"});
            if (res.data.successful) {
                // console.log('Data saved to file successfully');
                setpairedSetText("");
                setCurrentPair("spanishFrenchSet");

            }
            
        } catch (error) {
            // console.error('Error saving data to file:', error);
        }

    }

    const doneSpanishFrench = async () => {
        setPairState(prev => {return {...prev, "es_fr": true } });
        
        try {
            const inverseText = pairedSetText.replace(/spanishFrenchSet/g, 'frenchSpanishSet')
                                .replace(/targetLanguageIndex/g, 'tempLanguageIndex')
                                .replace(/translateLanguageIndex/g, 'targetLanguageIndex')
                                .replace(/tempLanguageIndex/g, 'translateLanguageIndex');

            const textObj = createJSONObject(pairedSetText);
            const inverseTextObj = createJSONObject(inverseText);

            // Get the full match pair object
            const spanishFrenchSetObject = completeMatchObject(textObj, "spanishFrenchSet");
            const frenchSpanishSetObject = completeMatchObject(inverseTextObj, "frenchSpanishSet");
        
            const res = await axios.post(`http://localhost:4000${window.location.pathname}`, { set: spanishFrenchSetObject, inverseSet: frenchSpanishSetObject,  fileName:"es_fr", inverseFileName:"fr_es"});
            if (res.data.successful) {
                // console.log('Data saved to file successfully');
                setpairedSetText("");
                setCurrentPair("germanFrenchSet");
            }
            
        } catch (error) {
            // console.error('Error saving data to file:', error);
        }

    }

    const doneGermanFrench = async () => {
        setPairState(prev => {return {...prev, "de_fr": true } });
        
        try {
            const inverseText = pairedSetText.replace(/germanFrenchSet/g, 'frenchGermanSet')
                                .replace(/targetLanguageIndex/g, 'tempLanguageIndex')
                                .replace(/translateLanguageIndex/g, 'targetLanguageIndex')
                                .replace(/tempLanguageIndex/g, 'translateLanguageIndex');

            const textObj = createJSONObject(pairedSetText);
            const inverseTextObj = createJSONObject(inverseText);

            // Get the full match pair object
            const germanFrenchSetObject = completeMatchObject(textObj, "germanFrenchSet");
            const frenchGermanSetObject = completeMatchObject(inverseTextObj, "frenchGermanSet");
        
            const res = await axios.post(`http://localhost:4000${window.location.pathname}`, { set: germanFrenchSetObject, inverseSet: frenchGermanSetObject,  fileName:"de_fr", inverseFileName:"fr_de"});
            if (res.data.successful) {
                // console.log('Data saved to file successfully');
                setpairedSetText("");
            }
            
        } catch (error) {
            // console.error('Error saving data to file:', error);
        }

    }

    const createJSONObject = (rawString) => {
        // Split the input string into individual JSON object strings
        const jsonObjectStrings = rawString.trim().split('\n');

        // Initialize an array to hold the parsed objects
        const parsedObjects = [];

        // Parse each individual JSON object string
        jsonObjectStrings.forEach(jsonObjectString => {
            try {
                const jsonObject = JSON.parse(`{${jsonObjectString}}`);
                parsedObjects.push(jsonObject);
            } catch (error) {
                // console.error('Error parsing JSON:', error);
            }
        });

        return parsedObjects;
    }

    const completeMatchObject = (textObject, languageSetPair) => {
        let memo = {};

        let fullMatch = [];

        for (let x = 0; x < textObject.length; x++) {
            for (let innerWordIndex = 0; innerWordIndex < textObject[x][languageSetPair].targetLanguageIndex.length; innerWordIndex++) {
                if (textObject[x][languageSetPair].targetLanguageIndex[innerWordIndex] in memo) {
                    fullMatch.push(memo[textObject[x][languageSetPair].targetLanguageIndex[innerWordIndex]]);
                }
                else {
                    fullMatch.push(textObject[x]);
                    memo[textObject[x][languageSetPair].targetLanguageIndex[innerWordIndex]] = textObject[x];
                }
            }
        }
        return fullMatch;
    }


    return(
        <Container>
            <h6 className="text-center">Story Paragraph Generation</h6>
            <Row>
                <Col>
                <h6>Target Language</h6>
                <textarea className="input-text-generation" placeholder="Enter text for Target Language" value={targetLanguageText} onChange={handleTargetText}/>
                <h6>Translate Language</h6>
                <textarea className="input-text-generation" placeholder="Enter text for Translate Language" value={translateLanguageText} onChange={handleTranslateText}/>
                <button className="button-text-generation" onClick={() => saveTextHandler("english")}>Save English Text</button>
                <button className="button-text-generation" onClick={() => saveTextHandler("spanish")}>Save Spanish Text</button>
                <button className="button-text-generation" onClick={() => saveTextHandler("german")}>Save German Text</button>
                <button className="button-text-generation" onClick={() => saveTextHandler("french")}>Save French Text</button>

                <div className="stacked-div">
                    <h6 className="title-text-generation mt-1">Target Language</h6>
                    <div className="words">
                        {targetWords}
                    </div>
                </div>
                <button className="button-text-generation" onClick={handlePair}>Pair</button>
                <button className={`button-pair--text-generation ${currentPair === "englishSpanishSet" ? "active-button-text-generation" : ""}`} onClick={() => setCurrentPair("englishSpanishSet")}>en-es</button>
                <button className={`button-pair--text-generation ${currentPair === "englishGermanSet" ? "active-button-text-generation" : ""}`} onClick={() => setCurrentPair("englishGermanSet")}>en-de</button>
                <button className={`button-pair--text-generation ${currentPair === "englishFrenchSet" ? "active-button-text-generation" : ""}`} onClick={() => setCurrentPair("englishFrenchSet")}>en-fr</button>
                <button className={`button-pair--text-generation ${currentPair === "spanishGermanSet" ? "active-button-text-generation" : ""}`} onClick={() => setCurrentPair("spanishGermanSet")}>es-de</button>
                <button className={`button-pair--text-generation ${currentPair === "spanishFrenchSet" ? "active-button-text-generation" : ""}`} onClick={() => setCurrentPair("spanishFrenchSet")}>es-fr</button>
                <button className={`button-pair--text-generation ${currentPair === "germanFrenchSet" ? "active-button-text-generation" : ""}`} onClick={() => setCurrentPair("germanFrenchSet")}>de-fr</button>

                <button className={`button-remove--text-generation`} onClick={removeLastSentence}>Remove last sentence</button>
                <button className="button-clearpair-text-generation" onClick={handleClear}>Clear Current Pair</button>

                <br/>
                <span className= "pair-label-text-generation">Last Pair: {`Target{${lastTargetSetWord}}, Translate{${lastTranslateSetWord}}`} || </span>
                <span className= "pair-label-text-generation">Current Pair: Target(<b>{`${targetSetWord}`}</b>) Translate(<b>{`${translateSetWord}`}</b>)</span>


                <div className="stacked-div">
                    <h6 className="title-text-generation">Translate Language</h6>
                    <div className="words">
                    {translateWords}
                    </div>
                </div>

                <div className="right-div mt-3">
                    <h6 className="title-text-generation">Set List</h6>
                    <p className= "pair-label-text-generation">Current Language: {currentPair}</p>
                    <p className= "pair-label-text-generation">Last Pair: {`Target{${lastTargetSetWord}}, Translate{${lastTranslateSetWord}}`}</p>
                    <p className= "pair-label-text-generation">Current Pair: Target(<b>{`${targetSetWord}`}</b>) Translate(<b>{`${translateSetWord}`}</b>)</p>
                    <textarea className="input-text-generation" placeholder="Text for paired set" value={pairedSetText} onChange={(event) => setpairedSetText(event.target.value)} />
                </div>
                <br/>
                <button className={`button-pair--text-generation ${pairState["en_es"] ? "active-button-text-generation" : ""}`} onClick={doneEnglishSpanish}>Done en-es</button>
                <button className={`button-pair--text-generation ${pairState["en_de"] ? "active-button-text-generation" : ""}`} onClick={doneEnglishGerman}>Done en-de</button>
                <button className={`button-pair--text-generation ${pairState["en_fr"] ? "active-button-text-generation" : ""}`} onClick={doneEnglishFrench}>Done en-fr</button>
                <button className={`button-pair--text-generation ${pairState["es_de"] ? "active-button-text-generation" : ""}`} onClick={doneSpanishGerman}>Done es-de</button>
                <button className={`button-pair--text-generation ${pairState["es_fr"] ? "active-button-text-generation" : ""}`} onClick={doneSpanishFrench}>Done es-fr</button>
                <button className={`button-pair--text-generation ${pairState["de_fr"] ? "active-button-text-generation" : ""}`} onClick={doneGermanFrench}>Done de-fr</button>
                <br/>
                <button className="button-text-generation" onClick={generateHandle}>Generate</button>

                </Col>
            </Row>
        </Container>
    )
}