import React from "react";
import LanguageRow from "./LanguageRow";
import LanguageCard from "./LanguageCard";
import Title from "./Title";

import "../../styles/languagelist.css";

import { LanguagePageContext } from '../../context/LanguagePageContext';


export default function Info() {


    const languagePageData = React.useContext(LanguagePageContext);

    // const languageList = languagePageData.flags.map((flag, i) => {
    //     return <LanguageRow id={flag.flagId} 
    //                         fileName={flag.fileName} 
    //                         name={flag.country} 
    //                         msgContent={flag.content} 
    //                         btnContent={flag.buttonContent}
    //                         language={flag.language}
    //                         key={i}/>
    // })
    const languageList = languagePageData.flags.map((flag, i) => {
        return <LanguageCard id={flag.flagId} 
                            flagFile={flag.fileName} 
                            subtitle={flag.buttonContent}
                            language={flag.language}
                            key={i}/>
    })

    return(
        <article className="row d-flex justify-content-around align-items-center mx-4 ">
            {/* <Title title={languagePageData.homePage[0].freeStoriesTitle}/> */}
            {languageList}
        </article>
    );
}