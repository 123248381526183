import React from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/ui/Button";
import "../../styles/login.css";

import resetPageData from "../../data/resetPageData";

import { resetPasswordRequest } from '../../utils/auth';
import { useNavigate, useParams } from "react-router-dom";

import {getUrlRoot} from "../../utils/utils.js";

import {Helmet} from "react-helmet";



export default function ResetPassword() {

    const {register, handleSubmit, reset, setValue} = useForm();
    const navigate = useNavigate();
    const [responseMessage, setResponseMessage] = React.useState("");
    const [isSuccesful, setIsSuccesful] = React.useState(false);

    const root = getUrlRoot();

    const pageDate = resetPageData[root];

    const {id, token} = useParams();

    async function submitHandler(data) {
        const {password, confirmedPassword} = data;

        if (password !== confirmedPassword) {
            setResponseMessage(pageDate.errorPasswordMessage);
            return
        }

        const success = await resetPasswordRequest(password ,id, token);
        if (!success) {
            setResponseMessage(pageDate.errorUpdateMessage)
            return
        }

        setResponseMessage(pageDate.succesfullUpdateMessage)
        setIsSuccesful(true);
    }

    function redirectLogin() {
        
        navigate(`${root}/login`, { replace: true });
    }

    const lockIcon = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "lock.webp";

    return(
        
        <div className="container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageDate.resetPasswordTitle} | Lingotales</title>
            </Helmet>
            <div className="row mx-auto login-show text-center" style={{maxWidth: "500px"}}>
                <h6 className="login-title-lingotales mx-auto my-3">{"LingoTales"}</h6>
                {!isSuccesful && 
                <form onSubmit={handleSubmit(submitHandler)}>
                    <h6 className="login-title my-3">{pageDate.resetPasswordTitle}</h6>

                    <p className="mt-2 forgot-password-email-text">{pageDate.newPasswordLabel}</p>
                    <div className="mt-1 my-form-group register-input-container">
                        <img src={lockIcon} atl="input_icon" className="input-icon-lock"/>
                        <input type="password" className="register-input " id="password" placeholder={pageDate.newPasswordLabel} {...register("password", {required : true})}/>
                    </div>

                    <p className="mt-1 forgot-password-email-text">{pageDate.confirmPasswordLabel}</p>
                    <div className="mt-1 my-form-group register-input-container">
                        <img src={lockIcon} atl="input_icon" className="input-icon-lock"/>
                        <input type="password" className="register-input " id="confirmedPassword" placeholder={pageDate.confirmPasswordLabel} {...register("confirmedPassword", {required : true})}/>
                    </div>
                    <p className={"forgot-password-email-text red"}>{responseMessage}</p>
                    
                    {!isSuccesful && <div className="row mx-auto py-2" style={{maxWidth: "180px"}} >
                        <Button content={pageDate.submitButtonContent} isFooter={false} isDisable={isSuccesful ? true : false}/>
                    </div> }
                </form>
                }
                
                {isSuccesful && 
                <>
                    <h6 className="login-title mt-3 mb-3">{"Password Updated!"}</h6>
                    <p className={"forgot-password-email-text green"}>{responseMessage}</p>
                    <div className="row mx-auto py-2" style={{maxWidth: "180px"}} >
                        <Button content={pageDate.LoginButtonContent} buttonHandler={ () => redirectLogin()} isFooter={false} isDisable= {false}/>
                    </div>
                </>
                }

            </div>
        </div>
                    
    );
}