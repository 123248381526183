import React from "react";

import "../../styles/title.css"


export default function Title(props) {

    // const [isIntersecting, setIsIntersecting] = React.useState(false);
    // const ref = React.useRef(null);

    // React.useEffect(() => {
    //     const observer = new IntersectionObserver(
    //       ([entry]) => {
    //         setIsIntersecting(entry.isIntersecting);
    //       },
    //       { rootMargin: "-20px" }
    //     );
    //     //console.log(isIntersecting);
    //     observer.observe(ref.current);
    
    //     return () => observer.disconnect();
    //   }, [isIntersecting]);

    //   React.useEffect(() => {
    //     if (isIntersecting) {
    //       ref.current.querySelectorAll("div").forEach((el) => {
    //         el.classList.add("slide-in");
    //       });
    //     } else {
    //       ref.current.querySelectorAll("div").forEach((el) => {
    //         el.classList.remove("slide-in");
    //       });
    //     }
    //   }, [isIntersecting]);
    
    return(
        // <section ref={ref}>
        <section className="px-0">
            <div className="info-title-static mb-2">
                <h6 className="title">{props.title}</h6>
            </div>
        </section>
    );
}