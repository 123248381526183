import React from "react";

import "../../styles/languagecard.css"

import { useNavigate } from "react-router-dom";

export default function LanguageCard(props) {
    const imgPath = process.env.REACT_APP_PUBLIC_URL_FLAGS + props.flagFile;

    const navigate = useNavigate();

    const redirection = React.useCallback( () => {
        let currentPath = window.location.pathname;

        if (currentPath === "/") {
            currentPath = "/stories/" + props.language.toLowerCase();
        }
        else {
            currentPath = currentPath + "/stories/" + props.language.toLowerCase(); 
        }

        navigate(currentPath);

    }, [props.language])

    return(
        <div className="language-card-container d-flex align-items-center flex-column mx-1 col-md-5 col-sm-5 mt-3 text-center" onClick={redirection}>
            <img className="language-card-image" src={imgPath}/>
            <p className="language-card-subtitle mb-1">{props.subtitle}</p>
        </div>
    );
}