import React from "react";
import "../../styles/languagetitle.css"

export default function LanguageTitle(props) {
    return(
            <div className="mt-4 text-center">
                <input type="text" placeholder={props.searchBarText} className="search-input" onChange={props.searchBarHandler} value={props.searchBar}/>
            </div>
    );
    
}