import React from "react";

import "../../styles/blogcard.css";


export default function BlogCard(props) {

    const imgPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + props.image;

    return(
    <div className="blog-card-container mt-3" id={props.title} onClick={props.handleClick} >
        <h6 className="blog-card-title pt-2">{props.title}</h6>
        <img className="blog-card-img mt-2" src={imgPath} alt="ai-img"/>
        <div className="blog-info-container mt-2 pb-2">
            <p className="blog-card-author">{props.author} |</p>
            <p className="blog-card-date"> {props.dateString}</p>
        </div>
    </div>
    );
}