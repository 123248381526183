import React from "react";
import '../../styles/flag.css';

export default function Flag(props) {
    let flagPath = process.env.REACT_APP_PUBLIC_URL_FLAGS + props.fileName;

    const spain = "../../../images/flags/spain_flag.png";

    return(
        <>
            {props.format === "header-stories" && <img src={flagPath} alt={props.fileName} className={`${props.format}` } id={props.id}/>}
            {props.format !== "header-stories" && <img src={flagPath} alt={props.fileName} className="flag" id={props.id}/>}
        </>
    );
}