import React from "react";
import { useParams } from "react-router-dom";

import HeaderStories from "../../layout/HeaderStories";
import Paragraph from "./Paragraph";
import Translation from "./Translation";
import StoryCard from "../../components/ui/StoryCard";
import LogNavBar from "../../layout/LogNavBar";
import Button from "../../components/ui/Button";

import Loading from "../../layout/Loading";


import "../../styles/storypage.css"

import { LanguagePageContext } from "../../context/LanguagePageContext";

import { useNavigate } from "react-router-dom";

import useAxios from "../../hooks/useAxios";
import axios from '../../apis/lingotalesApi';

import {Helmet} from "react-helmet";


export default function StoryPage(props) {

    // const [backendData, loading, error] = useAxios({
    //     axiosInstance: axios,
    //     method: 'GET',
    //     url: window.location.pathname,
    //     requestConfig: {
    //         headers: {
    //             'Content-Language': 'en-US',
    //         }
    //     }
    // });

    const [backendData, loading, error, getUnloggedStoryPage, getUserStoryPageData] = useAxios(
        {
            axiosInstance: axios,
            method: 'POST',
            url: process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL + window.location.pathname,
            data: {
                // Your POST payload data here
                email: localStorage.getItem('email'),
                // ...
            },
            requestConfig: {
                headers: {
                    'Content-Language': 'en-US',
                    // Other headers if needed
                }
            }
        },
        () => {
            // console.log("get unlogged and logged page data");
        }
    )

    const congratsParagraph = React.useRef(null);

    React.useEffect(() =>{
        const email = localStorage.getItem('email');
        if (email !== undefined && email !== null) {
            // Perform the POST request here
            // post api for logged user needs an email
             getUserStoryPageData();
        }
        else{
            //unlogged people dont need an email so just normal "get" request
            getUnloggedStoryPage();
        }
    }, [])



    const wordsString = {
        "english": "Words",
        "german": "Wörte",
        "french": "Mots",
        "spanish": "Palabras",
        "italian": "Parole",
        "polish": "Słowa",
        "russian": "Слова",
        "portuguese": "Palavras"
    }


    const congratsPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "congrats.png";
    const pinkHeartPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "pink_heart.png";
    const brokenHeartPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "broken_heart.avif";

    const navigate = useNavigate();

    const [paragraphCount, setParagraphCount] = React.useState(1);
    const [currentParagraph, setCurrentParagraph] = React.useState(0);
    const [storyDone, setStoryDone] = React.useState(false);
    const [selectHeart, setSelectHeart] = React.useState([{"id": "happy-heart", "isHeld" : false}, {"id": "broken-heart", "isHeld" : false}]);

    const [x, y, z, performGet, performPost] = useAxios(
        {
            axiosInstance: axios,
            method: 'POST',
            url: process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL + "/en/account/storyprogress",
            // url: 'http://localhost:4000/en/account/storyprogress',
            data: {
                // Your POST payload data here
                email: localStorage.getItem('email'),
                storyId: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1],
                paragraph: paragraphCount
                // ...
            },
            requestConfig: {
                headers: {
                    'Content-Language': 'en-US',
                    // Other headers if needed
                }
            }
        },
        () => {
            // console.log("set paragraph count post");
        }
    )

    const [x_1, y_2, z_2, performGet_2, performPut] = useAxios(
        {
            axiosInstance: axios,
            method: 'PUT',
            url: process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL + "/en/account/storyprogress",
            // url: 'http://localhost:4000/en/account/storyprogress',
            data: {
                // Your POST payload data here
                email: localStorage.getItem('email'),
                storyId: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1],
                // ...
            },
            requestConfig: {
                headers: {
                    'Content-Language': 'en-US',
                    // Other headers if needed
                }
            }
        },
        () => {
            // console.log("set paragraph count post");
        }
    )

    React.useEffect(() => {
        const email = localStorage.getItem('email');

        if (paragraphCount>0 && email !== undefined && email !== null) {
            // Perform the POST request here
            performPost();
        }
    }, [paragraphCount]);
    
    const storyId = useParams();

    const route = window.location.pathname.slice(0, window.location.pathname.indexOf(props.language)) + props.language;

    function checkId(storyId) {
        
        const maxId = 1000;

        if (storyId.storyId.includes('.')) {
            return false;
        }

        const id = parseInt(storyId.storyId);

        if (id > 0 && id <= maxId) {

            return true;
        }

        return false;
    }

    const isValidId = checkId(storyId);

    // if (isValidId) {
    //     props.setCurrentPath(()=> props.address + "/" + props.language + "/" + storyId.storyId);
    // }

    function translateSelectorHandler(event) {
        
        setTranslationState((arrTranslationState) => arrTranslationState.map(translationState => {
            return translationState.id === event.target.id ? 
            {...translationState, isActive: !translationState.isActive} : translationState
        }))
    }

    function continueSelectorHandler() {
        setParagraphCount(prev => prev +1);
    }

    function moreStoriesButtonHandler() {
        const currentPath = window.location.pathname;
        const newStoryRoute = currentPath.slice(0, currentPath.lastIndexOf("/"));
        navigate(newStoryRoute);
        navigate(0);
    }

    React.useEffect(() => {
        if (backendData.storyProgressEntry === undefined) {return}
        setParagraphCount(Number(backendData.storyProgressEntry.paragraph));
        console.log("storyProgressEntry:", backendData.storyProgressEntry);
    }, [backendData.storyProgressEntry])



    React.useEffect(() => {
        if (backendData.storyParagraphs === undefined) {return}

        const currentTarget = backendData.storyParagraphs[props.language.toLowerCase()];
        //end of the story
        if (paragraphCount === currentTarget.length + 1) {
            setStoryDone(() => true);
            if (props.isLogged) {
                performPut();
            }
        }
    }, [paragraphCount])

    React.useEffect(() => {
        if (storyDone) {
            congratsParagraph.current.scrollIntoView({ behavior: 'smooth'});
        }
    }, [storyDone])

    let categoryList;

    if (backendData.story !== undefined) {
        categoryList = backendData.story[0].categoryLabel.map((category, i, arr) => {
            if ( arr.length -1 === i) {
                return <p key={i} className="story--category">{category}</p>
            }
            return <p key={i} className="story--category">{category + ","}</p>
        })
    }
    

    // let translationDefault = [];


    let languageState = [{
        "language" : "English",
        "isActive" : true
        },
        // {
        // "language" : "Russian",
        // "isActive" : false
        // },
        {
        "language" : "Spanish",
        "isActive" : false
        },
        {
        "language" : "German",
        "isActive" : false
        },
        // {
        // "language" : "Italian",
        // "isActive" : false
        // },
        {
        "language" : "French",
        "isActive" : false
        }
        // {
        // "language" : "Polish",
        // "isActive" : false
        // },
        // {
        // "language" : "Portuguese",
        // "isActive" : false
        // }
    ];

    languageState = languageState.filter(function(value, index, arr){ 
        return value.language.toLowerCase() !== props.language;
    });


    languageState[0].isActive = true;
    
    const [currentTranslation, setCurrentTranslation] = React.useState(languageState);

    function chooseTranslateHandler(event) {

        setCurrentParagraph(() => event.target.getAttribute("data-id"));

        setCurrentTranslation((prev) => prev.map(currentTranslationState => {
            return currentTranslationState.language === event.target.id ? 
            {...currentTranslationState, isActive: true} : {...currentTranslationState, isActive: false}
        }))
    }

    const [storyEnglish, setStoryEnglish] = React.useState([]);
    const [storySpanish, setStorySpanish] = React.useState([]);
    const [storyFrench, setStoryFrench] = React.useState([]);
    // const [storyItalian, setStoryItalian] = React.useState([]);
    // const [storyRussian, setStoryRussian] = React.useState([]);
    // const [storyPolish, setStoryPolish] = React.useState([]);
    // const [storyPortuguese, setStoryPortuguese] = React.useState([]);
    const [storyGerman, setStoryGerman] = React.useState([]);

    React.useEffect(() =>{
        if (backendData.storyParagraphs === undefined) {return}
        setStoryEnglish(backendData.storyParagraphs.english);
        setStorySpanish(backendData.storyParagraphs.spanish);
        setStoryFrench(backendData.storyParagraphs.french);
        // setStoryItalian(backendData.storyParagraphs.italian);
        // setStoryRussian(backendData.storyParagraphs.russian);
        // setStoryPolish(backendData.storyParagraphs.polish);
        // setStoryPortuguese(backendData.storyParagraphs.portuguese);
        setStoryGerman(backendData.storyParagraphs.german);
    }, [backendData])

    

    function getCurrentTranslatedLanguage() {
        let currentLanguage= "german";
        currentTranslation.forEach(elem => {
            if (elem.isActive) {
                currentLanguage = elem.language;
                return;
            }
        })
        return currentLanguage.toLowerCase();
    }

    function getCurrentTargetLanguage() {
        return props.language.toLowerCase();
    }

    function getCurrentLanguagePair() {
        let currentTranslatedLanguage = getCurrentTranslatedLanguage();
        let currentTargetLanguage = getCurrentTargetLanguage();

        // const languagePairPool = ["english", "russian", "spanish", "german", "french", "italian", "portuguese", "polish"];
        const languagePairPool = ["english", "spanish", "german", "french"];

        let currentPair = {"target" : "english", "translate": "german"};


        // Look for the language pair
        for(let i = 0; i<languagePairPool.length; i++) {
            for (let k = i + 1; k < languagePairPool.length; k++) {
                if (currentTargetLanguage === languagePairPool[i] && currentTranslatedLanguage === languagePairPool[k]) {
                    currentPair["target"] = languagePairPool[i];
                    currentPair["translate"] = languagePairPool[k];
                }
                if (currentTargetLanguage === languagePairPool[k] && currentTranslatedLanguage === languagePairPool[i]) {
                    currentPair["target"] = languagePairPool[k];
                    currentPair["translate"] = languagePairPool[i];
                }
            }
        }
        return currentPair;
    }

    function inverseLanguagePair(languagePair) {
        const inversedLanguagePair = {
            target: languagePair.translate,
            translate: languagePair.target
        }
        return inversedLanguagePair;
    }

    function getActiveWord(story, currentParagraph) {
        let currentActiveWord;
        story[currentParagraph].forEach( word => {
            if (word.active) {
                currentActiveWord = word;
                return;
            }
        })
        return currentActiveWord;
    }

    function getStory(targetLanguage) {
        if (targetLanguage === "english") {
            return storyEnglish;
        }

        // if (targetLanguage === "russian") {
        //     return storyRussian;
        // }

        if (targetLanguage === "german") {
            return storyGerman;
        }

        // if (targetLanguage === "italian") {
        //     return storyItalian;
        // }

        if (targetLanguage === "french") {
            return storyFrench;
        }

        if (targetLanguage === "spanish") {
            return storySpanish;
        }

        // if (targetLanguage === "polish") {
        //     return storyPolish;
        // }

        // if (targetLanguage === "portuguese") {
        //     return storyPortuguese;
        // }
    }

    function updateTranslation( {target, translate}, activeWord) {
        const pair = target + "-" + translate;

        switch(pair) {
        // case "english-russian":
        //     if (activeWord !== undefined) {
        //         updateTranslationEnglishRussian(activeWord, target);
        //     } 
        //     break;

        case "english-spanish":
            if (activeWord !== undefined) {
                updateTranslationEnglishSpanish(activeWord, target);
            } 
            break;

        case "english-german":
            if (activeWord !== undefined) {
                updateTranslationEnglishGerman(activeWord, target);
            } 
            break;

        case "english-french":
            if (activeWord !== undefined) {
                updateTranslationEnglishFrench(activeWord, target);
            } 
            break;

        // case "english-italian":
        //     if (activeWord !== undefined) {
        //         updateTranslationEnglishItalian(activeWord, target);
        //     } 
        //     break;

        // case "english-portuguese":
        //     if (activeWord !== undefined) {
        //         updateTranslationEnglishPortuguese(activeWord, target);
        //     } 
        //     break;

        // case "english-polish":
        //     if (activeWord !== undefined) {
        //         updateTranslationEnglishPolish(activeWord, target);
        //     } 
        //     break;

        // case "russian-english":
        //     if (activeWord !== undefined) {
        //         updateTranslationEnglishRussian(activeWord, target);
        //     } 
        //     break;

        // case "russian-spanish":
        //     if (activeWord !== undefined) {
        //         updateTranslationRussianSpanish(activeWord, target);
        //     } 
        //     break;

        // case "russian-german":
        //     if (activeWord !== undefined) {
        //         updateTranslationRussianGerman(activeWord, target);
        //     } 
        //     break;

        // case "russian-french":
        //     if (activeWord !== undefined) {
        //         updateTranslationRussianFrench(activeWord, target);
        //     } 
        //     break;

        // case "russian-italian":
        //     if (activeWord !== undefined) {
        //         updateTranslationRussianItalian(activeWord, target);
        //     } 
        //     break;

        // case "russian-portuguese":
        //     if (activeWord !== undefined) {
        //         updateTranslationRussianPortuguese(activeWord, target);
        //     } 
        //     break;

        // case "russian-polish":
        //     if (activeWord !== undefined) {
        //         updateTranslationRussianPolish(activeWord, target);
        //     } 
        //     break;

        case "spanish-english":
            if (activeWord !== undefined) {
                updateTranslationEnglishSpanish(activeWord, target);
            } 
            break;

        // case "spanish-russian":
        //     if (activeWord !== undefined) {
        //         updateTranslationRussianSpanish(activeWord, target);
        //     } 
        //     break;

        case "spanish-german":
            if (activeWord !== undefined) {
                updateTranslationSpanishGerman(activeWord, target);
            } 
            break;

        case "spanish-french":
            if (activeWord !== undefined) {
                updateTranslationSpanishFrench(activeWord, target);
            } 
            break;

        // case "spanish-italian":
        //     if (activeWord !== undefined) {
        //         updateTranslationSpanishItalian(activeWord, target);
        //     } 
        //     break;

        // case "spanish-portuguese":
        //     if (activeWord !== undefined) {
        //         updateTranslationSpanishPortuguese(activeWord, target);
        //     } 
        //     break;

        // case "spanish-polish":
        //     if (activeWord !== undefined) {
        //         updateTranslationSpanishPolish(activeWord, target);
        //     } 
        //     break;

        case "german-english":
            if (activeWord !== undefined) {
                updateTranslationEnglishGerman(activeWord, target);
            } 
            break;

        // case "german-russian":
        //     if (activeWord !== undefined) {
        //         updateTranslationRussianGerman(activeWord, target);
        //     } 
        //     break;

        case "german-spanish":
            if (activeWord !== undefined) {
                updateTranslationSpanishGerman(activeWord, target);
            } 
            break;

        case "german-french":
            if (activeWord !== undefined) {
                updateTranslationGermanFrench(activeWord, target);
            } 
            break;

        // case "german-italian":
        //     if (activeWord !== undefined) {
        //         updateTranslationGermanItalian(activeWord, target);
        //     } 
        //     break;

        // case "german-portuguese":
        //     if (activeWord !== undefined) {
        //         updateTranslationGermanPortuguese(activeWord, target);
        //     } 
        //     break;

        // case "german-polish":
        //     if (activeWord !== undefined) {
        //         updateTranslationGermanPolish(activeWord, target);
        //     } 
        //     break;

        case "french-english":
            if (activeWord !== undefined) {
                updateTranslationEnglishFrench(activeWord, target);
            } 
            break;

        // case "french-russian":
        //     if (activeWord !== undefined) {
        //         updateTranslationRussianFrench(activeWord, target);
        //     } 
        //     break;

        case "french-spanish":
            if (activeWord !== undefined) {
                updateTranslationSpanishFrench(activeWord, target);
            } 
            break;

        case "french-german":
            if (activeWord !== undefined) {
                updateTranslationGermanFrench(activeWord, target);
            } 
            break;

        // case "french-italian":
        //     if (activeWord !== undefined) {
        //         updateTranslationFrenchItalian(activeWord, target);
        //     } 
        //     break;

        // case "french-portuguese":
        //     if (activeWord !== undefined) {
        //         updateTranslationFrenchPortuguese(activeWord, target);
        //     } 
        //     break;

        // case "french-polish":
        //     if (activeWord !== undefined) {
        //         updateTranslationFrenchPolish(activeWord, target);
        //     } 
        //     break;

        // case "italian-english":
        //     if (activeWord !== undefined) {
        //         updateTranslationEnglishItalian(activeWord, target);
        //     } 
        //     break;

        // case "italian-russian":
        //     if (activeWord !== undefined) {
        //         updateTranslationRussianItalian(activeWord, target);
        //     } 
        //     break;

        // case "italian-spanish":
        //     if (activeWord !== undefined) {
        //         updateTranslationSpanishItalian(activeWord, target);
        //     } 
        //     break;

        // case "italian-german":
        //     if (activeWord !== undefined) {
        //         updateTranslationGermanItalian(activeWord, target);
        //     } 
        //     break;

        // case "italian-french":
        //     if (activeWord !== undefined) {
        //         updateTranslationFrenchItalian(activeWord, target);
        //     } 
        //     break;

        // case "italian-portuguese":
        //     if (activeWord !== undefined) {
        //         updateTranslationItalianPortuguese(activeWord, target);
        //     } 
        //     break;

        // case "italian-polish":
        //     if (activeWord !== undefined) {
        //         updateTranslationItalianPolish(activeWord, target);
        //     } 
        //     break;

        // case "portuguese-english":
        //     if (activeWord !== undefined) {
        //         updateTranslationEnglishPortuguese(activeWord, target);
        //     } 
        //     break;

        // case "portuguese-russian":
        //     if (activeWord !== undefined) {
        //         updateTranslationRussianPortuguese(activeWord, target);
        //     } 
        //     break;

        // case "portuguese-spanish":
        //     if (activeWord !== undefined) {
        //         updateTranslationSpanishPortuguese(activeWord, target);
        //     } 
        //     break;

        // case "portuguese-german":
        //     if (activeWord !== undefined) {
        //         updateTranslationGermanPortuguese(activeWord, target);
        //     } 
        //     break;

        // case "portuguese-french":
        //     if (activeWord !== undefined) {
        //         updateTranslationFrenchPortuguese(activeWord, target);
        //     } 
        //     break;

        // case "portuguese-italian":
        //     if (activeWord !== undefined) {
        //         updateTranslationItalianPortuguese(activeWord, target);
        //     } 
        //     break;

        // case "portuguese-polish":
        //     if (activeWord !== undefined) {
        //         updateTranslationPortuguesePolish(activeWord, target);
        //     } 
        //     break;

        // case "polish-english":
        //     if (activeWord !== undefined) {
        //         updateTranslationEnglishPolish(activeWord, target);
        //     } 
        //     break;

        // case "polish-russian":
        //     if (activeWord !== undefined) {
        //         updateTranslationRussianPolish(activeWord, target);
        //     } 
        //     break;

        // case "polish-spanish":
        //     if (activeWord !== undefined) {
        //         updateTranslationSpanishPolish(activeWord, target);
        //     } 
        //     break;

        // case "polish-german":
        //     if (activeWord !== undefined) {
        //         updateTranslationGermanPolish(activeWord, target);
        //     } 
        //     break;

        // case "polish-french":
        //     if (activeWord !== undefined) {
        //         updateTranslationFrenchPolish(activeWord, target);
        //     } 
        //     break;

        // case "polish-italian":
        //     if (activeWord !== undefined) {
        //         updateTranslationItalianPolish(activeWord, target);
        //     } 
        //     break;

        // case "polish-portuguese":
        //     if (activeWord !== undefined) {
        //         updateTranslationPortuguesePolish(activeWord, target);
        //     } 
        //     break;

        default:
            // console.log("Dont update any language")
            break;
        }
    }

    // function updateTranslationEnglishRussian(activeWord, targetLanguage) {

    //     let englishLanguageWordIndex;
    //     let russianLanguageWordIndex;
    //     // let paragraphId;
    //     // let wordIndex;

    //     if (targetLanguage === "russian") {
    //         const {russianEnglishSet} = activeWord
    //         // [paragraphId, wordIndex] = russianEnglishSet.index.split("-");
    //         russianLanguageWordIndex = russianEnglishSet.targetLanguageIndex;
    //         englishLanguageWordIndex = russianEnglishSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {englishRussianSet} = activeWord;
    //         // [paragraphId, wordIndex] = englishRussianSet.index.split("-");
    //         englishLanguageWordIndex = englishRussianSet.targetLanguageIndex;
    //         russianLanguageWordIndex = englishRussianSet.translateLanguageIndex;
    //     }

    //     setStoryEnglish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [_, wordId] = word.index.split("-");

    //             // console.log("paragraphIndex:", parseInt(paragraphIndex));
    //             // console.log("paragraphId:", parseInt(paragraphId));

    //             // if (paragraphIndex != parseInt(paragraphId)) {
    //             //     console.log("inside");
    //             //     return word;
    //             // }
                
    //             if(englishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))

    //     setStoryRussian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [_, wordId] = word.index.split("-");

    //             // if (paragraphIndex !== parseInt(paragraphId)) {
    //             //     console.log("inside");
    //             //     return word;
    //             // }
                
    //             if(russianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))
    // }

    function updateTranslationEnglishSpanish(activeWord, targetLanguage) {

        let englishLanguageWordIndex;
        let spanishLanguageWordIndex;

        if (targetLanguage === "spanish") {
            const {spanishEnglishSet} = activeWord
            spanishLanguageWordIndex = spanishEnglishSet.targetLanguageIndex;
            englishLanguageWordIndex = spanishEnglishSet.translateLanguageIndex;
        }
        else {
            const {englishSpanishSet} = activeWord;
            englishLanguageWordIndex = englishSpanishSet.targetLanguageIndex;
            spanishLanguageWordIndex = englishSpanishSet.translateLanguageIndex;
        }

        setStoryEnglish((paragraphArray) => paragraphArray.map( (paragraph) =>{
            return paragraph.map((word) => {

                const [paragraphId, wordId] = word.index.split("-");
                
                if(englishLanguageWordIndex.includes(Number(wordId))) {

                    return {
                        ...word,
                        active:true
                    }
                }
                else{
                    return {
                        ...word,
                        active: false
                    }
                }
        
                
            })
        }))

        setStorySpanish((paragraphArray) => paragraphArray.map( (paragraph) =>{
            return paragraph.map((word) => {

                const [paragraphId, wordId] = word.index.split("-");
                
                if(spanishLanguageWordIndex.includes(Number(wordId))) {

                    return {
                        ...word,
                        active:true
                    }
                }
                else{
                    return {
                        ...word,
                        active: false
                    }
                }
        
                
            })
        }))
    }

    function updateTranslationEnglishGerman(activeWord, targetLanguage) {

        let englishLanguageWordIndex;
        let germanLanguageWordIndex;

        if (targetLanguage === "german") {
            const {germanEnglishSet} = activeWord
            germanLanguageWordIndex = germanEnglishSet.targetLanguageIndex;
            englishLanguageWordIndex = germanEnglishSet.translateLanguageIndex;
        }
        else {
            const {englishGermanSet} = activeWord;
            englishLanguageWordIndex = englishGermanSet.targetLanguageIndex;
            germanLanguageWordIndex = englishGermanSet.translateLanguageIndex;
        }

        setStoryEnglish((paragraphArray) => paragraphArray.map( (paragraph) =>{
            return paragraph.map((word) => {

                const [paragraphId, wordId] = word.index.split("-");
                
                if(englishLanguageWordIndex.includes(Number(wordId))) {
                    return {
                        ...word,
                        active:true
                    }
                }
                else{
                    return {
                        ...word,
                        active: false
                    }
                }
        
                
            })
        }))

        setStoryGerman((paragraphArray) => paragraphArray.map( (paragraph) =>{
            return paragraph.map((word) => {

                const [paragraphId, wordId] = word.index.split("-");
                
                if(germanLanguageWordIndex.includes(Number(wordId))) {

                    return {
                        ...word,
                        active:true
                    }
                }
                else{
                    return {
                        ...word,
                        active: false
                    }
                }
        
                
            })
        }))
    }

    function updateTranslationEnglishFrench(activeWord, targetLanguage) {

        let englishLanguageWordIndex;
        let frenchLanguageWordIndex;

        if (targetLanguage === "french") {
            const {frenchEnglishSet} = activeWord
            frenchLanguageWordIndex = frenchEnglishSet.targetLanguageIndex;
            englishLanguageWordIndex = frenchEnglishSet.translateLanguageIndex;
        }
        else {
            const {englishFrenchSet} = activeWord;
            englishLanguageWordIndex = englishFrenchSet.targetLanguageIndex;
            frenchLanguageWordIndex = englishFrenchSet.translateLanguageIndex;
        }

        setStoryEnglish((paragraphArray) => paragraphArray.map( (paragraph) =>{
            return paragraph.map((word) => {

                const [paragraphId, wordId] = word.index.split("-");
                
                if(englishLanguageWordIndex.includes(Number(wordId))) {

                    return {
                        ...word,
                        active:true
                    }
                }
                else{
                    return {
                        ...word,
                        active: false
                    }
                }
        
                
            })
        }))

        setStoryFrench((paragraphArray) => paragraphArray.map( (paragraph) =>{
            return paragraph.map((word) => {

                const [paragraphId, wordId] = word.index.split("-");
                
                if(frenchLanguageWordIndex.includes(Number(wordId))) {

                    return {
                        ...word,
                        active:true
                    }
                }
                else{
                    return {
                        ...word,
                        active: false
                    }
                }
        
                
            })
        }))
    }

    // function updateTranslationEnglishItalian(activeWord, targetLanguage) {

    //     let englishLanguageWordIndex;
    //     let italianLanguageWordIndex;

    //     if (targetLanguage === "italian") {
    //         const {italianEnglishSet} = activeWord
    //         italianLanguageWordIndex = italianEnglishSet.targetLanguageIndex;
    //         englishLanguageWordIndex = italianEnglishSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {englishItalianSet} = activeWord;
    //         englishLanguageWordIndex = englishItalianSet.targetLanguageIndex;
    //         italianLanguageWordIndex = englishItalianSet.translateLanguageIndex;
    //     }

    //     setStoryEnglish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(englishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))

    //     setStoryItalian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(italianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))
    // }

    // function updateTranslationEnglishPortuguese(activeWord, targetLanguage) {

    //     let englishLanguageWordIndex;
    //     let portugueseLanguageWordIndex;

    //     if (targetLanguage === "portuguese") {
    //         const {portugueseEnglishSet} = activeWord
    //         portugueseLanguageWordIndex = portugueseEnglishSet.targetLanguageIndex;
    //         englishLanguageWordIndex = portugueseEnglishSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {englishPortugueseSet} = activeWord;
    //         englishLanguageWordIndex = englishPortugueseSet.targetLanguageIndex;
    //         portugueseLanguageWordIndex = englishPortugueseSet.translateLanguageIndex;
    //     }

    //     setStoryEnglish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(englishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))

    //     setStoryPortuguese((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(portugueseLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))
    // }

    // function updateTranslationEnglishPolish(activeWord, targetLanguage) {

    //     let englishLanguageWordIndex;
    //     let polishLanguageWordIndex;

    //     if (targetLanguage === "polish") {
    //         const {polishEnglishSet} = activeWord
    //         polishLanguageWordIndex = polishEnglishSet.targetLanguageIndex;
    //         englishLanguageWordIndex = polishEnglishSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {englishPolishSet} = activeWord;
    //         englishLanguageWordIndex = englishPolishSet.targetLanguageIndex;
    //         polishLanguageWordIndex = englishPolishSet.translateLanguageIndex;
    //     }

    //     setStoryEnglish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(englishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))

    //     setStoryPolish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(polishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))
    // }

    // function updateTranslationRussianSpanish(activeWord, targetLanguage) {

    //     let russianLanguageWordIndex;
    //     let spanishLanguageWordIndex;

    //     if (targetLanguage === "spanish") {
    //         const {spanishRussianSet} = activeWord
    //         spanishLanguageWordIndex = spanishRussianSet.targetLanguageIndex;
    //         russianLanguageWordIndex = spanishRussianSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {russianSpanishSet} = activeWord;
    //         russianLanguageWordIndex = russianSpanishSet.targetLanguageIndex;
    //         spanishLanguageWordIndex = russianSpanishSet.translateLanguageIndex;
    //     }

    //     setStoryRussian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(russianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))

    //     setStorySpanish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(spanishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))
    // }

    // function updateTranslationRussianGerman(activeWord, targetLanguage) {

    //     let russianLanguageWordIndex;
    //     let germanLanguageWordIndex;

    //     if (targetLanguage === "german") {
    //         const {germanRussianSet} = activeWord
    //         germanLanguageWordIndex = germanRussianSet.targetLanguageIndex;
    //         russianLanguageWordIndex = germanRussianSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {russianGermanSet} = activeWord;
    //         russianLanguageWordIndex = russianGermanSet.targetLanguageIndex;
    //         germanLanguageWordIndex = russianGermanSet.translateLanguageIndex;
    //     }

    //     setStoryRussian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(russianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))

    //     setStoryGerman((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(germanLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))
    // }

    // function updateTranslationRussianFrench(activeWord, targetLanguage) {

    //     let russianLanguageWordIndex;
    //     let frenchLanguageWordIndex;

    //     if (targetLanguage === "french") {
    //         const {frenchRussianSet} = activeWord
    //        frenchLanguageWordIndex = frenchRussianSet.targetLanguageIndex;
    //         russianLanguageWordIndex = frenchRussianSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {russianFrenchSet} = activeWord;
    //         russianLanguageWordIndex = russianFrenchSet.targetLanguageIndex;
    //         frenchLanguageWordIndex = russianFrenchSet.translateLanguageIndex;
    //     }

    //     setStoryRussian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(russianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))

    //     setStoryFrench((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(frenchLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))
    // }

    // function updateTranslationRussianItalian(activeWord, targetLanguage) {

    //     let russianLanguageWordIndex;
    //     let italianLanguageWordIndex;

    //     if (targetLanguage === "italian") {
    //         const {italianRussianSet} = activeWord
    //         italianLanguageWordIndex = italianRussianSet.targetLanguageIndex;
    //         russianLanguageWordIndex = italianRussianSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {russianItalianSet} = activeWord;
    //         russianLanguageWordIndex = russianItalianSet.targetLanguageIndex;
    //         italianLanguageWordIndex = russianItalianSet.translateLanguageIndex;
    //     }

    //     setStoryRussian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(russianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))

    //     setStoryItalian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(italianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))
    // }

    // function updateTranslationRussianPortuguese(activeWord, targetLanguage) {

    //     let russianLanguageWordIndex;
    //     let portugueseLanguageWordIndex;

    //     if (targetLanguage === "portuguese") {
    //         const {portugueseRussianSet} = activeWord
    //         portugueseLanguageWordIndex = portugueseRussianSet.targetLanguageIndex;
    //         russianLanguageWordIndex = portugueseRussianSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {russianPortugueseSet} = activeWord;
    //         russianLanguageWordIndex = russianPortugueseSet.targetLanguageIndex;
    //         portugueseLanguageWordIndex = russianPortugueseSet.translateLanguageIndex;
    //     }

    //     setStoryRussian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(russianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))

    //     setStoryPortuguese((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(portugueseLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))
    // }

    // function updateTranslationRussianPolish(activeWord, targetLanguage) {

    //     let russianLanguageWordIndex;
    //     let polishLanguageWordIndex;

    //     if (targetLanguage === "polish") {
    //         const {polishRussianSet} = activeWord
    //         polishLanguageWordIndex = polishRussianSet.targetLanguageIndex;
    //         russianLanguageWordIndex = polishRussianSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {russianPolishSet} = activeWord;
    //         russianLanguageWordIndex = russianPolishSet.targetLanguageIndex;
    //         polishLanguageWordIndex = russianPolishSet.translateLanguageIndex;
    //     }

    //     setStoryRussian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(russianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))

    //     setStoryPolish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(polishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
        
                
    //         })
    //     }))
    // }

    function updateTranslationSpanishGerman(activeWord, targetLanguage) {

        let germanLanguageWordIndex;
        let spanishLanguageWordIndex;

        if (targetLanguage === "german") {
            const {germanSpanishSet} = activeWord
            germanLanguageWordIndex = germanSpanishSet.targetLanguageIndex;
            spanishLanguageWordIndex = germanSpanishSet.translateLanguageIndex;
        }
        else {
            const {spanishGermanSet} = activeWord;
            spanishLanguageWordIndex = spanishGermanSet.targetLanguageIndex;
            germanLanguageWordIndex = spanishGermanSet.translateLanguageIndex;
        }

        setStorySpanish((paragraphArray) => paragraphArray.map( (paragraph) =>{
            return paragraph.map((word) => {

                const [paragraphId, wordId] = word.index.split("-");
                
                if(spanishLanguageWordIndex.includes(Number(wordId))) {

                    return {
                        ...word,
                        active:true
                    }
                }
                else{
                    return {
                        ...word,
                        active: false
                    }
                }
        
                
            })
        }))

        setStoryGerman((paragraphArray) => paragraphArray.map( (paragraph) =>{
            return paragraph.map((word) => {

                const [paragraphId, wordId] = word.index.split("-");
                
                if(germanLanguageWordIndex.includes(Number(wordId))) {

                    return {
                        ...word,
                        active:true
                    }
                }
                else{
                    return {
                        ...word,
                        active: false
                    }
                }
        
                
            })
        }))
    }

    function updateTranslationSpanishFrench(activeWord, targetLanguage) {

        let frenchLanguageWordIndex;
        let spanishLanguageWordIndex;

        if (targetLanguage === "french") {
            const {frenchSpanishSet} = activeWord
            frenchLanguageWordIndex = frenchSpanishSet.targetLanguageIndex;
            spanishLanguageWordIndex = frenchSpanishSet.translateLanguageIndex;
        }
        else {
            const {spanishFrenchSet} = activeWord;
            spanishLanguageWordIndex = spanishFrenchSet.targetLanguageIndex;
            frenchLanguageWordIndex = spanishFrenchSet.translateLanguageIndex;
        }

        setStorySpanish((paragraphArray) => paragraphArray.map( (paragraph) =>{
            return paragraph.map((word) => {

                const [paragraphId, wordId] = word.index.split("-");
                
                if(spanishLanguageWordIndex.includes(Number(wordId))) {

                    return {
                        ...word,
                        active:true
                    }
                }
                else{
                    return {
                        ...word,
                        active: false
                    }
                }
            })
        }))

        setStoryFrench((paragraphArray) => paragraphArray.map( (paragraph) =>{
            return paragraph.map((word) => {

                const [paragraphId, wordId] = word.index.split("-");
                
                if(frenchLanguageWordIndex.includes(Number(wordId))) {

                    return {
                        ...word,
                        active:true
                    }
                }
                else{
                    return {
                        ...word,
                        active: false
                    }
                }
            })
        }))
    }

    // function updateTranslationSpanishItalian(activeWord, targetLanguage) {

    //     let italianLanguageWordIndex;
    //     let spanishLanguageWordIndex;

    //     if (targetLanguage === "italian") {
    //         const {italianSpanishSet} = activeWord
    //         italianLanguageWordIndex = italianSpanishSet.targetLanguageIndex;
    //         spanishLanguageWordIndex = italianSpanishSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {spanishItalianSet} = activeWord;
    //         spanishLanguageWordIndex = spanishItalianSet.targetLanguageIndex;
    //         italianLanguageWordIndex = spanishItalianSet.translateLanguageIndex;
    //     }

    //     setStorySpanish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(spanishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))

    //     setStoryItalian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(italianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))
    // }

    // function updateTranslationSpanishPortuguese(activeWord, targetLanguage) {

    //     let portugueseLanguageWordIndex;
    //     let spanishLanguageWordIndex;

    //     if (targetLanguage === "portuguese") {
    //         const {portugueseSpanishSet} = activeWord
    //         portugueseLanguageWordIndex = portugueseSpanishSet.targetLanguageIndex;
    //         spanishLanguageWordIndex = portugueseSpanishSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {spanishPortugueseSet} = activeWord;
    //         spanishLanguageWordIndex = spanishPortugueseSet.targetLanguageIndex;
    //         portugueseLanguageWordIndex = spanishPortugueseSet.translateLanguageIndex;
    //     }

    //     setStorySpanish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(spanishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))

    //     setStoryPortuguese((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(portugueseLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))
    // }

    // function updateTranslationSpanishPolish(activeWord, targetLanguage) {

    //     let polishLanguageWordIndex;
    //     let spanishLanguageWordIndex;

    //     if (targetLanguage === "polish") {
    //         const {polishSpanishSet} = activeWord
    //         polishLanguageWordIndex = polishSpanishSet.targetLanguageIndex;
    //         spanishLanguageWordIndex = polishSpanishSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {spanishPolishSet} = activeWord;
    //         spanishLanguageWordIndex = spanishPolishSet.targetLanguageIndex;
    //         polishLanguageWordIndex = spanishPolishSet.translateLanguageIndex;
    //     }

    //     setStorySpanish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(spanishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))

    //     setStoryPolish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(polishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))
    // }

    function updateTranslationGermanFrench(activeWord, targetLanguage) {

        let germanLanguageWordIndex;
        let frenchLanguageWordIndex;

        if (targetLanguage === "french") {
            const {frenchGermanSet} = activeWord
            frenchLanguageWordIndex = frenchGermanSet.targetLanguageIndex;
            germanLanguageWordIndex = frenchGermanSet.translateLanguageIndex;
        }
        else {
            const {germanFrenchSet} = activeWord;
            germanLanguageWordIndex = germanFrenchSet.targetLanguageIndex;
            frenchLanguageWordIndex = germanFrenchSet.translateLanguageIndex;
        }

        setStoryGerman((paragraphArray) => paragraphArray.map( (paragraph) =>{
            return paragraph.map((word) => {

                const [paragraphId, wordId] = word.index.split("-");
                
                if(germanLanguageWordIndex.includes(Number(wordId))) {

                    return {
                        ...word,
                        active:true
                    }
                }
                else{
                    return {
                        ...word,
                        active: false
                    }
                }
            })
        }))

        setStoryFrench((paragraphArray) => paragraphArray.map( (paragraph) =>{
            return paragraph.map((word) => {

                const [paragraphId, wordId] = word.index.split("-");
                
                if(frenchLanguageWordIndex.includes(Number(wordId))) {

                    return {
                        ...word,
                        active:true
                    }
                }
                else{
                    return {
                        ...word,
                        active: false
                    }
                }
            })
        }))
    }

    // function updateTranslationGermanItalian(activeWord, targetLanguage) {

    //     let germanLanguageWordIndex;
    //     let italianLanguageWordIndex;

    //     if (targetLanguage === "italian") {
    //         const {italianGermanSet} = activeWord
    //         italianLanguageWordIndex = italianGermanSet.targetLanguageIndex;
    //         germanLanguageWordIndex = italianGermanSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {germanItalianSet} = activeWord;
    //         germanLanguageWordIndex = germanItalianSet.targetLanguageIndex;
    //         italianLanguageWordIndex = germanItalianSet.translateLanguageIndex;
    //     }

    //     setStoryGerman((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(germanLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))

    //     setStoryItalian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(italianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))
    // }

    // function updateTranslationGermanPortuguese(activeWord, targetLanguage) {

    //     let germanLanguageWordIndex;
    //     let portugueseLanguageWordIndex;

    //     if (targetLanguage === "portuguese") {
    //         const {portugueseGermanSet} = activeWord
    //         portugueseLanguageWordIndex = portugueseGermanSet.targetLanguageIndex;
    //         germanLanguageWordIndex = portugueseGermanSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {germanPortugueseSet} = activeWord;
    //         germanLanguageWordIndex = germanPortugueseSet.targetLanguageIndex;
    //         portugueseLanguageWordIndex = germanPortugueseSet.translateLanguageIndex;
    //     }

    //     setStoryGerman((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(germanLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))

    //     setStoryPortuguese((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(portugueseLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))
    // }

    // function updateTranslationGermanPolish(activeWord, targetLanguage) {

    //     let germanLanguageWordIndex;
    //     let polishLanguageWordIndex;

    //     if (targetLanguage === "polish") {
    //         const {polishGermanSet} = activeWord
    //         polishLanguageWordIndex = polishGermanSet.targetLanguageIndex;
    //         germanLanguageWordIndex = polishGermanSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {germanPolishSet} = activeWord;
    //         germanLanguageWordIndex = germanPolishSet.targetLanguageIndex;
    //         polishLanguageWordIndex = germanPolishSet.translateLanguageIndex;
    //     }

    //     setStoryGerman((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(germanLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))

    //     setStoryPolish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(polishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))
    // }

    // function updateTranslationFrenchItalian(activeWord, targetLanguage) {

    //     let frenchLanguageWordIndex;
    //     let italianLanguageWordIndex;

    //     if (targetLanguage === "italian") {
    //         const {italianFrenchSet} = activeWord
    //         italianLanguageWordIndex = italianFrenchSet.targetLanguageIndex;
    //         frenchLanguageWordIndex = italianFrenchSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {frenchItalianSet} = activeWord;
    //         frenchLanguageWordIndex = frenchItalianSet.targetLanguageIndex;
    //         italianLanguageWordIndex = frenchItalianSet.translateLanguageIndex;
    //     }

    //     setStoryFrench((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(frenchLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))

    //     setStoryItalian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(italianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))
    // }

    // function updateTranslationFrenchPortuguese(activeWord, targetLanguage) {

    //     let frenchLanguageWordIndex;
    //     let portugueseLanguageWordIndex;

    //     if (targetLanguage === "portuguese") {
    //         const {portugueseFrenchSet} = activeWord
    //         portugueseLanguageWordIndex = portugueseFrenchSet.targetLanguageIndex;
    //         frenchLanguageWordIndex = portugueseFrenchSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {frenchPortugueseSet} = activeWord;
    //         frenchLanguageWordIndex = frenchPortugueseSet.targetLanguageIndex;
    //         portugueseLanguageWordIndex = frenchPortugueseSet.translateLanguageIndex;
    //     }

    //     setStoryFrench((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(frenchLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))

    //     setStoryPortuguese((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(portugueseLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))
    // }

    // function updateTranslationFrenchPolish(activeWord, targetLanguage) {

    //     let frenchLanguageWordIndex;
    //     let polishLanguageWordIndex;

    //     if (targetLanguage === "polish") {
    //         const {polishFrenchSet} = activeWord
    //         polishLanguageWordIndex = polishFrenchSet.targetLanguageIndex;
    //         frenchLanguageWordIndex = polishFrenchSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {frenchPolishSet} = activeWord;
    //         frenchLanguageWordIndex = frenchPolishSet.targetLanguageIndex;
    //         polishLanguageWordIndex = frenchPolishSet.translateLanguageIndex;
    //     }

    //     setStoryFrench((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(frenchLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))

    //     setStoryPolish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(polishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))
    // }

    // function updateTranslationItalianPortuguese(activeWord, targetLanguage) {

    //     let italianLanguageWordIndex;
    //     let portugueseLanguageWordIndex;

    //     if (targetLanguage === "portuguese") {
    //         const {portugueseItalianSet} = activeWord
    //         portugueseLanguageWordIndex = portugueseItalianSet.targetLanguageIndex;
    //         italianLanguageWordIndex = portugueseItalianSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {italianPortugueseSet} = activeWord;
    //         italianLanguageWordIndex = italianPortugueseSet.targetLanguageIndex;
    //         portugueseLanguageWordIndex = italianPortugueseSet.translateLanguageIndex;
    //     }

    //     setStoryItalian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(italianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))

    //     setStoryPortuguese((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(portugueseLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))
    // }

    // function updateTranslationItalianPolish(activeWord, targetLanguage) {

    //     let italianLanguageWordIndex;
    //     let polishLanguageWordIndex;

    //     if (targetLanguage === "polish") {
    //         const {polishItalianSet} = activeWord
    //         polishLanguageWordIndex = polishItalianSet.targetLanguageIndex;
    //         italianLanguageWordIndex = polishItalianSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {italianPolishSet} = activeWord;
    //         italianLanguageWordIndex = italianPolishSet.targetLanguageIndex;
    //         polishLanguageWordIndex = italianPolishSet.translateLanguageIndex;
    //     }

    //     setStoryItalian((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(italianLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))

    //     setStoryPolish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(polishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))
    // }

    // function updateTranslationPortuguesePolish(activeWord, targetLanguage) {

    //     let portugueseLanguageWordIndex;
    //     let polishLanguageWordIndex;

    //     if (targetLanguage === "polish") {
    //         const {polishPortugueseSet} = activeWord
    //         polishLanguageWordIndex = polishPortugueseSet.targetLanguageIndex;
    //         portugueseLanguageWordIndex = polishPortugueseSet.translateLanguageIndex;
    //     }
    //     else {
    //         const {portuguesePolishSet} = activeWord;
    //         portugueseLanguageWordIndex = portuguesePolishSet.targetLanguageIndex;
    //         polishLanguageWordIndex = portuguesePolishSet.translateLanguageIndex;
    //     }

    //     setStoryPortuguese((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(portugueseLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))

    //     setStoryPolish((paragraphArray) => paragraphArray.map( (paragraph) =>{
    //         return paragraph.map((word) => {

    //             const [paragraphId, wordId] = word.index.split("-");
                
    //             if(polishLanguageWordIndex.includes(wordId)) {

    //                 return {
    //                     ...word,
    //                     active:true
    //                 }
    //             }
    //             else{
    //                 return {
    //                     ...word,
    //                     active: false
    //                 }
    //             }
    //         })
    //     }))
    // }

    function getCurrentParagraph() {
        return currentParagraph;
    }


    React.useEffect(() => {
        if (backendData.storyParagraphs !== undefined && storyEnglish.length > 0) {
        const languagePair= getCurrentLanguagePair();
        const  targetStory = getStory(languagePair.target);
        const selectedParagraph = getCurrentParagraph(); //starts from 0
        const activeWord = getActiveWord(targetStory, selectedParagraph);

        updateTranslation(languagePair, activeWord);
    }

    }, [currentTranslation, props.language, backendData])

    

    const [translationState, setTranslationState] = React.useState([]);

    React.useEffect(()=>{
        if (backendData.storyParagraphs === undefined) {return}

        setTranslationState(() => {
            let translationDefault = [];

            for(let i=0; i< backendData.storyParagraphs.english.length; i++) {
                translationDefault.push({
                    id: `${i}`,
                    isActive: false
                })
            }
            return translationDefault;

        });
    },[backendData])

    function getClickedWord(text, wordId, paragraphId) {
        for(let i= 0; i< text.length; i++) {
            if (text[i].index === `${paragraphId}-${wordId}`) {
                return text[i];
            }
        }
    }



    function selectWordHandler(event, isFromTranslation) {
        // Inverse the language pair if we click on the translate text instead
        const languagePair = isFromTranslation ? inverseLanguagePair(getCurrentLanguagePair()) : getCurrentLanguagePair();

        let [paragraphId, wordId] = event.target.id.split("-");

        //Show tranlation when clicking a word if there is no translation displayed
        setTranslationState((arrTranslationState) => arrTranslationState.map(translationState => {
            return translationState.id === paragraphId ? {...translationState, isActive: true} : translationState;
        }))

        const clickedWord = getClickedWord(backendData.storyParagraphs[languagePair.target.toLowerCase()][parseInt(paragraphId)], parseInt(wordId), parseInt(paragraphId));

        updateTranslation(languagePair, clickedWord);

    }

    let paragraphList;

    if (backendData.storyParagraphs !== undefined && translationState.length > 0) {

        paragraphList = backendData.storyParagraphs.english.map((text, i , arr) => {

            const currentTargetText = getStory(props.language.toLowerCase());
            const currentTranslatedLanguage = getCurrentTranslatedLanguage();
            const currentTranslatedText = getStory(currentTranslatedLanguage); //fixing this story
    
            return (
                <div key={i}>{  i < paragraphCount && 
                        <Paragraph  text={currentTargetText[i]} 
                        clickHandler={(event) => translateSelectorHandler(event)}
                        continueHandler = {() => continueSelectorHandler()}
                        disableContinueBtn = { i !== paragraphCount-1 ? true : false}
                        isLastParagraph = {i === currentTargetText.length -1 ? true : false}
                        paragraphId={i}
                        audio={backendData.story[0].audio[i]}
                        selectWord= {(event) => selectWordHandler(event, false)}
                        levelRef={backendData.story[0].levelRef.toLowerCase()}
                        isActive={translationState[i].isActive}/>}
    
                    {(translationState[i].isActive && i < paragraphCount) &&
                    <Translation    translationText={currentTranslatedText[i]} 
                                    activeLanguage={"russian"} 
                                    clickHandler={(event) => chooseTranslateHandler(event)}
                                    selectWord= {(event) => selectWordHandler(event, true)}
                                    paragraphId={i}
                                    currentTranslation={currentTranslation}/>}
                </div>
            );
        })

    }
    
    

    function selectHeartHandler(event) {
        // console.log(event.target.id);
        setSelectHeart((hearts) => hearts.map((heart) => {
            return heart.id === event.target.id ? {...heart, isHeld: !heart.isHeld} : {...heart, isHeld: false};
        }))
    }

    function cardSelectorHandler(event) {

        const currentPath = window.location.pathname;
        const newStoryRoute = currentPath.slice(0, currentPath.lastIndexOf("/")) + `/${event.target.id}`;
        navigate(newStoryRoute);
        navigate(0);
    }


    //TO BE IMPROVED!!!!

    // React.useEffect(() => {
    //     let tempAllstoryCardList
    //     if (backendData.storyCards !== undefined) {
    //         const loginPrompt = backendData.storyBrowserPage[0].loginCardPrompt;
    //         const loginButtonContent = backendData.navBar[0].navLogin;

    //         tempAllstoryCardList = backendData.storyCards.map((card, index) => {
    //             return <StoryCard 
    //                             title={card.title} 
    //                             category={card.genre} 
    //                             level={card.level} 
    //                             rate={card.likes} 
    //                             img={card.fileName} 
    //                             levelRef={card.levelRef} 
    //                             id={card.storyId}
    //                             clickHandler={(event) => cardSelectorHandler(event)}
    //                             index={index + 1}
    //                             loginCardPrompt={loginPrompt}
    //                             loginButtonContent={loginButtonContent}
    //                             key={index}
    //                             />
    //         })
    //     }

    //     setStoryCardList(tempAllstoryCardList);
    // }, [backendData])

    let recommendationStoryCardList

    if (backendData.storyCards !== undefined) {
        //the following values can be empty since they are not used but we cant pass just an non existing value to storycard
        const loginPrompt = "";
        const loginButtonContent = "";

        // console.log("backend:", backendData);

        recommendationStoryCardList = backendData.storyCards.map((card, index) => {
            return <StoryCard 
                            title={card.title} 
                            category={card.category} 
                            level={card.level} 
                            likes={card.likes} 
                            language={card.language}
                            img={card.fileName} 
                            levelRef={card.levelRef} 
                            id={card.storyId}
                            clickHandler={(event) => cardSelectorHandler(event)}
                            index={index + 1}
                            loginCardPrompt={loginPrompt}
                            loginButtonContent={loginButtonContent}
                            key={index}
                            />
        })
    }

    

    // const divRefCongrats = React.useRef();

    // React.useEffect(() => {
    //     divRefCongrats.current.scrollIntoView({ behavior: 'smooth' });
    // },[]);

    const defaultModalController = [{    "id" : "language",
                                        "show" : false
                                    },
                                    {    "id" : "account",
                                        "show" : false
                                    }] 

    const [modalController, setModalController] = React.useState(defaultModalController);

    function closeModalHandler(event){
        // console.log(event.target)
        setModalController(prev => prev.map((modal) => {
            if (event.target.id !== modal.id) {
                return {...modal,
                        show: false};
            }
            return modal;
        }))
    }

    function closeAllModalHandler() {
        setModalController(prev => prev.map((modal) => {return {...modal, show: false}}))
    }

    function openModalHandler(event) {
        setModalController(prev => prev.map((modal) => {
            if (event.target.id === modal.id) {
                return {...modal,
                        show: true};
            }
            return modal;
        }))
    }
    

    return (
        <>
        {backendData.story === undefined ? <Loading/>:
            <LanguagePageContext.Provider value={backendData}>
                    {!props.isLogged ?
                    <HeaderStories activeLanguage={props.language} isStoryPage={true}/> :
                    
                    <LogNavBar isDiscover={true} activeLanguage={props.language} modalController={modalController} openModalHandler={(event) => openModalHandler(event)}  closeModalHandler={(event) => closeModalHandler(event)}/>
                    
                    }

                    {isValidId && 
                    <div className={`container`} onClick={closeAllModalHandler} style={{maxWidth: "900px"}}>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{backendData.story[0].title} | Lingotales</title>
                        </Helmet>
                        <div className="row mt-3">
                            <div className="story-container">
                                <p className={`story-tag-level d-none d-sm-block ${backendData.story[0].levelRef.toLowerCase()}-story-tag-level`}>{backendData.story[0].levelRef}</p>
                                <h4 className="story--title">{backendData.story[0].title}</h4>
                                <div className="story-category-container mt-1">
                                    {categoryList}
                                    <p className="word-tag">{`(${backendData.story[0].wordCount} ${wordsString[props.language]})`} ||</p>
                                    <div className="rate--container">
                                        <p className="story--rate">{`${backendData.story[0].likes}`}</p>
                                        <img src={pinkHeartPath} className="little-heart-title"/>
                                    </div>
                                </div>
                                {paragraphList}
                                {storyDone && 
                                    <div className="text-center pt-4 congrats-section" ref={congratsParagraph}>
                                        <h4 className="amazing-story-message">{backendData.storyPage[0].motivationPrompt}</h4>
                                        <img className="congrats-img-story" src={congratsPath}/>
                                        <h6 className="congrat-story-message pt-1">{backendData.storyPage[0].congratsPrompt}</h6>
                                        <h6 className="like-story-message pt-4">{backendData.storyPage[0].likePrompt}</h6>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <img className={`heart-img-story mx-3 ${selectHeart[0].isHeld ? "clicked-heart": ""}`}  src={pinkHeartPath} id="happy-heart" onClick={selectHeartHandler}/>
                                            <img className={`heart-img-story mx-3 ${selectHeart[1].isHeld ? "clicked-brokenheart": ""}`} src={brokenHeartPath} id="broken-heart" onClick={selectHeartHandler}/>
                                        </div>
                                        <h6 className="like-story-message pt-4">{backendData.storyPage[0].similarStoriesPrompt}</h6>
                                        <div className="row d-flex justify-content-center mb-4 px-2">
                                            {recommendationStoryCardList}
                                        </div>
                                        <div className="row d-flex justify-content-center mb-4 mx-auto" style={{maxWidth: "200px"}}>
                                            <Button content={backendData.storyPage[0].moreStoriesPrompt} buttonHandler={moreStoriesButtonHandler}/>
                                        </div>
                                    </div>}
                                <></>
                            </div>
                        </div>
                    </div>
                    }

                    {!isValidId && <h1>Not Valid Id</h1>}
                    
            </LanguagePageContext.Provider>
        }
        </>
    );
}