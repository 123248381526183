// Function to get the url root
export const getUrlRoot= () => {
    const root = window.location.pathname.slice(0, 3);
    if (root === "/en") {
        return "/en";
    }
    else if (root === "/de") {
        return "/de";
    }
    else if (root === "/ru") {
        return "/ru";
    }
    else if (root === "/it") {
        return "/it";
    }
    else if (root === "/fr") {
        return "/fr";
    }
    else if (root === "/pt") {
        return "/pt";
    }
    else if (root === "/pl") {
        return "/pl";
    }
    else if (root === "/es") {
        return "/es";
    }
    else {
        return "/en";
    }
  };
  