import React from "react";
import languageData from "./data/languageData";
import StoriesBrowser from "./pages/StoryBrowser/StoriesBrowser";
import Register from "./pages/Register/Register";
import Login from "./pages/Login/Login";
import StoryPage from "./pages/Story/StoryPage";
import Blog from "./pages/BlogBrowser/Blog";
import BlogPage from "./pages/Blog/BlogPage";
import About from "./pages/About/About";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy/Privacy";
import Dashboard from "./pages/Dashboard/Dashboard";
import Discover from "./pages/Discover/Discover";
import Account from "./pages/Account/Account";
import PrivateRoutes from "./utils/PrivateRoutes";
import TextGeneration from "./pages/TextGeneration/TextGeneration";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Home from "./pages/Home/Home";


import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";




import "./styles/app.css";

function App() {

  const routeBrowser = languageData.data.storyLink.map(({path, language}, key) => <Route 

    exact path={path} 
    element={
        <StoriesBrowser 
        language={language} 
        /> 
    }
    key={key} />);

  const routeStories = languageData.data.storyLink.map(({path, language}, key) => <Route 
    exact path={path + "/:storyId"} 
    element={
      <StoryPage  
      language={language}
      />
    } 
    
    key={key} />);

  const routeHomeList = languageData.data.homeLink.map(({path}, key) => <Route 
    exact path={path} 
    element={
      <Home/>
    } 
    key={key}/>);

  const routeRegisterList = languageData.data.registerLink.map(({path}, key) => <Route 
    exact path={path} 
    element={
      <Register/>
    } 
    key={key}/>);

  const routeLoginList = languageData.data.loginLink.map(({path}, key) => <Route 
    exact path={path} 
    element={
    <Login
    />
    } 
    key={key}/>);
                                                                                                                                    
  // const routeHelpList = languageData.data.helpLink.map(({path}, key) => <Route exact path={path} element={<Development   setCurrentPath={setCurrentPath} 
  //                                                                                                                                   currentPath={currentPath}
  //                                                                                                                                   />} key={key}/>);

  const routeAboutList = languageData.data.aboutLink.map(({path}, key) => <Route exact path={path} element={<About/>} key={key}/>);


  const routeTermsList = languageData.data.termsLink.map(({path}, key) => <Route exact path={path} element={<Terms/>} key={key}/>);

  const routePrivacyList = languageData.data.privacyLink.map(({path}, key) => <Route exact path={path} element={<Privacy/>} key={key}/>);
                                                                                                                                    
  // const routePricingList = languageData.data.pricingLink.map(({path}, key) => <Route exact path={path} element={<Development  setCurrentPath={setCurrentPath} 
  //                                                                                                                                     currentPath={currentPath}
  //                                                                                                                                     />} key={key}/>);

  const routeForgotPasswordgList = languageData.data.forgotPasswordLink.map(({path}, key) => <Route exact path={path} element={<ForgotPassword/>} key={key}/>);

  const routeResetPasswordgList = languageData.data.resetPasswordLink.map(({path}, key) => <Route exact path={path} element={<ResetPassword/>} key={key}/>);

  const routeBlogList = languageData.data.blogLink.map(({path}, key) => <Route exact path={path} element={<Blog/>} key={key}/>);

  const routeBlogPageList = languageData.data.blogLink.map(({path}, key) => <Route exact path={path + "/:storyId"} element={<BlogPage/>} key={key}/>);

  const routeDashboardList = languageData.data.dashboardLink.map(({path}, key) => <Route element={<PrivateRoutes/>}> <Route element={<Dashboard/>} exact path={path} key={key}/> </Route>);

  const routeDiscoverList = languageData.data.discoverLink.map(({path, language}, key) => <Route element={<PrivateRoutes/>}> <Route element={<Discover language={language}/>} exact path={path} key={key}/> </Route>);

  const routeStoryPageList = languageData.data.discoverLink.map(({path, language}, key) => <Route element={<PrivateRoutes/>}> <Route element={<StoryPage language={language} isLogged={true}/>} exact path={path + "/:storyId"} key={key}/> </Route>);
  
  const routeAccountList = languageData.data.accountLink.map(({path}, key) => <Route element={<PrivateRoutes/>}> <Route element={<Account/>} exact path={path} key={key}/> </Route>);

  return (
    <>
      <Router>
        <Routes>
          {routeHomeList}
          {routeBrowser}
          {routeStories}
          {routeRegisterList}
          {routeLoginList}
          {/* {routeHelpList} */}
          {routeAboutList}
          {routeTermsList}
          {routePrivacyList}
          {/* {routePricingList} */}
          {routeBlogList}
          {routeBlogPageList}
          {routeDashboardList}
          {routeDiscoverList}
          {routeStoryPageList}
          {routeAccountList}
          {routeForgotPasswordgList}
          {routeResetPasswordgList}
          <Route exact path={"/generation"} element={<TextGeneration/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
