import React from "react";

import { LanguagePageContext } from "../../context/LanguagePageContext";
import LogNavBar from "../../layout/LogNavBar";

import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios"

import { getUserCreatedMonth } from "../../utils/parseUserData";

import {Helmet} from "react-helmet";

import "../../styles/account.css";

export default function Account(){

    const [backendData, setBackendData] = React.useState([]);
    const location = useLocation();

    const [flagListState, setFlagListState] = React.useState();
    const [targetLanguageState, setTargetLanguageState] = React.useState([]);
    // const [postRequestMade, setPostRequestMade] = React.useState(false); // New state variable to track POST request

    React.useEffect(() => {
        const email = localStorage.getItem('email');

        axios.post(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}${window.location.pathname}`, {email: email}).then(response => {
            setBackendData(response.data);
            setFlagListState(response.data.flags);
            setTargetLanguageState(response.data.user[0].targetLang);
            // setPostRequestMade(true); // Marking the POST request as made

            // setIsLoading(false);
        }).catch( error => {
            // console.log(error);
            // setIsLoading(false);
        });
    }, [])

    // React.useEffect(() => {
    //     // Fetch the CSRF token from your server
    //     axios.get('http://localhost:4000/get-csrf-token')
    //       .then(response => {
    //         // Store the CSRF token in a variable
    //         const csrfToken = response.data.csrfToken;
      
    //         // Use the CSRF token when making the POST request
    //         const email = localStorage.getItem('email');
    //         console.log('sending email', email);
      
    //         axios
    //           .post(`http://localhost:4000${window.location.pathname}`, { email: email}, {
    //             headers: {
    //               'X-CSRF-Token': csrfToken, // Include the CSRF token in the request headers
    //             },
    //           })
    //           .then(response => {
    //             setBackendData(response.data);
    //             setFlagListState(response.data.flags);
    //             setTargetLanguageState(response.data.user[0].targetLang);
    //           })
    //           .catch(error => {
    //             console.log(error);
    //           });
    //       })
    //       .catch(error => {
    //         console.log(error);
    //       });
    //   }, []);
      

    React.useEffect(() => {

        if ( targetLanguageState.length > 0 ) {
            // console.log("Updating Account Data");

            const email = localStorage.getItem('email');
            // console.log("sending email", email);

            axios.put(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}${window.location.pathname}`, {email: email, targetLang: targetLanguageState}).then(response => {
                // console.log(response.data);

            }).catch( error => {
                // console.log(error);
            });
        }
        
        
    }, [targetLanguageState])

    




    const profileLogoPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "dog-profile.png";
    const levelPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "star.png";

    // let modifiedFlagData =  registerData["/en/register"].flags;
    // modifiedFlagData[0].isHeld = true;
    // modifiedFlagData[1].isHeld = true;

    const defaultModalController = [{    "id" : "language",
                                        "show" : false
                                    },
                                    {    "id" : "account",
                                        "show" : false
                                    }] 

    const [modalController, setModalController] = React.useState(defaultModalController);
    // const [flagListState, setFlagListState] = React.useState(modifiedFlagData);
    

    function closeModalHandler(event){
        // console.log(event.target)
        setModalController(prev => prev.map((modal) => {
            if (event.target.id !== modal.id) {
                return {...modal,
                        show: false};
            }
            return modal;
        }))
    }

    function closeAllModalHandler() {
        setModalController(prev => prev.map((modal) => {return {...modal, show: false}}))
    }

    function openModalHandler(event) {
        setModalController(prev => prev.map((modal) => {
            if (event.target.id === modal.id) {
                return {...modal,
                        show: true};
            }
            return modal;
        }))
    }



    function clickHandler(event) {
        // console.log(event.target.id);

        // setFlagListState((prev) => prev.map((country) =>{
        //             return country.id !== event.target.id ? country : {
        //                 ...country,
        //                 "isHeld" : !country.isHeld
        //                 }
        //     })
        // )
        // console.log(event.target.id);

        setTargetLanguageState(prev => {
            if (prev.includes(event.target.id)) {
                return prev.filter(item => item !== event.target.id);
            }
            else {
                return [...prev, event.target.id];
            }
        })

    }
    let flagList;

    if (backendData.flags !== undefined) {

    // setFlagListState(backendData.flags);
    // console.log(backendData.user[0].targetLang);
    // const targetLanguages = backendData.user[0].targetLang;
    // console.log(targetLanguageState);

    flagList = backendData.flags.map((country, i) => {

        const pathFlag = process.env.REACT_APP_PUBLIC_URL_FLAGS + country.fileName;

        return(
            <div key={i} className={`col-sm-4 col-md-3 col-6 d-flex align-items-center mx-auto justify-content-start emphasis-container ${targetLanguageState.includes(country.language) ? "clicked-flag" : ""}`}>
                <img className="account-flag-icon" src={pathFlag} alt="flag" onClick={clickHandler} id={country.language}/>
                <p className="account-flag-subtitle" id={country.language} onClick={clickHandler}>{country.label}</p>
            </div>
        );
    })

}

    return(
        <>
        {backendData.logNavBar === undefined ? null:
        <LanguagePageContext.Provider value={backendData}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{backendData.accountPage[0].accountTitle} | Lingotales</title>
            </Helmet>
                <div>
                    <LogNavBar isHome={false} modalController={modalController} openModalHandler={(event) => openModalHandler(event)}  closeModalHandler={(event) => closeModalHandler(event)}/>
                    <div className="container mt-2 mx-auto mb-4" onClick={closeAllModalHandler} style={{maxWidth: "700px"}}>
                        <div className="row mx-auto" style={{maxWidth: "260px"}}>
                            <div className="text-center account-page-title-container d-flex align-items-center justify-content-center mt-2">
                                <h1 className="account-page-title mb-1 mt-1">{backendData.accountPage[0].accountTitle}</h1>
                            </div>
                        </div>

                        <div className="row mt-3 text-center px-2">
                            <div className="account-card-container d-flex flex-column align-items-center justify-content-center">
                                <div className="d-flex align-items-center mt-1">
                                    <img src={levelPath} className="account-level-img"/>
                                    <p className="account-username-tag">{backendData.accountPage[0].levelLabel} {backendData.user[0].level}</p>
                                </div>
                                <img src={profileLogoPath} className="profile-img mt-1"/>
                                <h4 className="account-name mt-1">{backendData.user[0].username}</h4>
                                <p className="account-member-msg mt-1 mb-1">{getUserCreatedMonth(backendData.user.langCode, backendData.user[0].createdMonth) + " " + backendData.user[0].createdYear}</p>
                            </div>
                        </div>

                        <div className="row mt-3 text-center px-2">
                            <h6 className="account-page-subtitle mt-2">{backendData.accountPage[0].learningTitle}</h6>
                        </div>

                        <div className="row mt-2 text-center px-2">
                            <div className="account-card-container">
                                <p className="pt-2 account-language-title">{backendData.accountPage[0].learningSubtitle}</p>
                                <div className="row d-flex justify-content-between px-2 py-2 mx-auto">
                                    {flagList}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LanguagePageContext.Provider>
            }
            </>
    );
}