import React from "react";
import "../../styles/contactmodal.css";
import Button from "../ui/Button";

import Modal from 'react-bootstrap/Modal';
import { LanguagePageContext } from '../../context/LanguagePageContext';

function ContactModal({show, handleClose}) {

    const languagePageData = React.useContext(LanguagePageContext);
    const [imageLoaded, setImageLoaded] = React.useState(false);

    const messageImgPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + languagePageData.homePage[0].contactModal.messageImg;
    

    React.useEffect(() => {
        const img = new Image();
        img.onload = () => setImageLoaded(true);
        img.src = messageImgPath;
      }, []);

    return(
        <>
            {imageLoaded ? (
                <Modal show={show} onHide={handleClose} size="sm">
                    <Modal.Body>
                        <div className="contact-modal-container">
                            <div className="contact-modal-title-container">
                                <h6 className="contact-modal-title">{languagePageData.homePage[0].contactModal.title}</h6>
                                {/* <img className="modal-check-img" src={checkMessagePath} alt={"message checked"}/> */}
                            </div>
                            <img className="modal-message-img mt-3" src={messageImgPath} alt={"message contact"}/>
                            <p className="contact-modal-message mt-1">{languagePageData.homePage[0].contactModal.message}</p>
                            {/* <button className="contact-modal-button" onClick={handleClose}>Close</button> */}
                            <div className="contact-modal-button-container">
                                <Button content={languagePageData.homePage[0].contactModal.buttonContent} buttonHandler={handleClose}/>
                            </div>
                        </div>
                        
                    </Modal.Body>
                </Modal>) :
                <></>
            }

        </>
    )
}

export default ContactModal