import React from "react";
import "../../styles/filter.css";
import CheckBox from "../../components/ui/CheckBox";
// import ToggleButton from "./ToggleButton";
import Button from "../../components/ui/Button";
import { useForm } from "react-hook-form";

import { LanguagePageContext } from '../../context/LanguagePageContext';

export default function Filter(props) {

    const languagePageData = React.useContext(LanguagePageContext);

    

    const defaultFilterInput = {
        level: {
                A1CheckBox : false,
                A2CheckBox : false,
                B1CheckBox : false,
                B2CheckBox: false},

        wordCount: {
                    SmallCheckBox : false,
                    MediumCheckBox: false,
                    LargeCheckBox: false},

        category: {
                    MysteryCheckBox : false,
                    ScifyCheckBox : false,
                    RomanceCheckBox: false,
                    AdventureCheckBox: false,
                    SportsCheckBox : false,
                    FantasyCheckBox : false,
                    ComedyCheckBox: false,
                    KidsCheckBox: false,
                    CulturalCheckBox : false,
                    InformativeCheckBox: false,
                    MindblowingCheckBox: false,
                    TechnicalCheckBox : false,
                    FoodCheckBox : false,
                    InspirationalCheckBox: false,
                    TechnologiesCheckBox: false,
                },
        sort: {
                alphabetSort: false,
                likeSort: false,
                wordCountSort: false,
                levelSort: false,
        }
    }
    
    const [filterLevel, setFilterLevel] = React.useState(defaultFilterInput.level);
    const [filterWordCount, setFilterWordCount] = React.useState(defaultFilterInput.wordCount);
    const [filterCategory, setFilterCategory] = React.useState(defaultFilterInput.category);
    const [filterSort, setFilterSort] = React.useState(defaultFilterInput.sort);

    // When chaning the story language reset the filter values
    React.useEffect(() => {
        setFilterLevel(defaultFilterInput.level);
        setFilterWordCount(defaultFilterInput.wordCount);
        setFilterCategory(defaultFilterInput.category);
        setFilterSort(defaultFilterInput.sort);
    }, [props.activeLanguage])

    function checkboxLevelHandler(event){
        const {name, value} = event.target;
        setFilterLevel((prev) =>{
            return {
                ...prev,
                [name] : value === "true" ? false : true
            }
        });
    }

    function checkboxWordCountHandler(event){
        const {name, value} = event.target;
        setFilterWordCount((prev) =>{
            return {
                ...prev,
                [name] : value === "true" ? false : true
            }
        });
    }

    function checkboxCategoryHandler(event){
        const {name, value} = event.target;
        setFilterCategory((prev) =>{
            return {
                ...prev,
                [name] : value === "true" ? false : true
            }
        });
    }

    function checkboxSortHandler(event){
        const {name, value} = event.target;

        const sort={
            alphabetSort: false,
            likeSort: false,
            wordCountSort: false,
            levelSort: false,
            }

        if(filterSort[name] === sort[name]) {
            sort[name] = true
        }

        setFilterSort(sort);
    }


    const {register, handleSubmit} = useForm();

    const levelCheckBoxList = languagePageData.storyBrowserPage[0].level.map((elem, i) =>{
        const value = filterLevel[elem.id];
        return <CheckBox key={i} label={elem.label} id={elem.id} register={register} value={value} handleClick={ event => checkboxLevelHandler(event)}/>
    })

    const wordCountCheckBox = languagePageData.storyBrowserPage[0].wordCount.map((elem, i) =>{
        const value = filterWordCount[elem.id];
        const isDisable = elem.id !== "SmallCheckBox" ? "disable-checkbox" : ""; 

        return <CheckBox key={i} label={elem.label} id={elem.id} register={register} value={value} handleClick={ event => checkboxWordCountHandler(event)} isDisable={isDisable}/>
    })

    const categoryCheckBox = languagePageData.storyBrowserPage[0].category.map((elem, i) =>{
        const value = filterCategory[elem.id];
        return(
            <div className="col-4 px-0 mx-0 d-flex flex-wrap">
                <CheckBox key={i} label={elem.label} id={elem.id} register={register} value={value} handleClick={ event => checkboxCategoryHandler(event)}/>
            </div>
        );
    })

    const sortCheckBox = languagePageData.storyBrowserPage[0].sort.map((elem, i) => {
        const value = filterSort[elem.id];
        return(
            <div className="col-12 px-0">
                <CheckBox key={i} label={elem.label} id={elem.id} register={register} value={value} handleClick={ event => checkboxSortHandler(event)}/>
            </div>
        );
    })


    function submitHandler(e) {
        const submitData = {filterLevel,
                            filterWordCount,
                            filterCategory,
                            filterSort}
        // console.log(submitData);
        props.setFilterData(submitData)
    }

    return(
        <form onSubmit={handleSubmit(submitHandler)}>
            <div className="row d-flex px-2 mt-2 justify-content-between">
                <div className="col-md-3 col-12 my-1 order-md-1 level-container d-flex flex-column align-items-left px-2">
                    <h6 className="filter-section-title mb-1">{languagePageData.storyBrowserPage[0].levelTitle}</h6>
                    {levelCheckBoxList}
                </div>
                <div className="col-md-5 col-12 my-1 order-md-2 word-container d-flex flex-column align-items-left px-2">
                    <h6 className="filter-section-title mb-1">{languagePageData.storyBrowserPage[0].wordCountTitle}</h6>
                    {wordCountCheckBox}
                </div>
                <div className="col-md-12 col-12 my-1 order-md-4 category-container d-flex flex-column align-items-left px-2">
                    <h6 className="filter-section-title mb-1">{languagePageData.storyBrowserPage[0].categoryTitle}</h6>
                    <div className="container px-0 d-flex flex-wrap">
                        {categoryCheckBox}
                    </div>
                </div>
                <div className="col-md-3 col-12 my-1 order-md-3 sort-container d-flex flex-column align-items-left px-2">
                    <h6 className="filter-section-title mb-1">{languagePageData.storyBrowserPage[0].sortTitle}</h6> 
                    <div className="container px-0 d-flex flex-column">
                        {sortCheckBox}
                    </div>
                </div>
            </div>

            <div className="row mx-auto mt-2" style={{maxWidth: "160px"}}>
                <div className="col-12 px-1 d-flex align-items-center">
                    <Button content={languagePageData.storyBrowserPage[0].filterButtonContent} isDisable={props.isLoading ? true : false}/>
                </div>
                {props.isLoading &&  
                    <div className="col-12 mt-1 d-flex align-items-center justify-content-center">
                        <div className="filter-loading"></div>
                    </div>}
            </div>
            
        </form>
    );
}