import React from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/ui/Button";
import "../../styles/login.css";

import { LanguagePageContext } from "../../context/LanguagePageContext";

import useAxios from "../../hooks/useAxios";
import axios from '../../apis/lingotalesApi';

import {forgotPasswordRequest } from '../../utils/auth';
import { useNavigate } from "react-router-dom";

import {getUrlRoot} from "../../utils/utils.js"
import { getByDisplayValue } from "@testing-library/react";

import {Helmet} from "react-helmet";


export default function ForgotPassword() {

    const {register, handleSubmit, reset, setValue} = useForm();
    const [responseMessage, setResponseMessage] = React.useState("");
    const [isSuccesfulMessage, setIsSuccesfulMessage] = React.useState(false);

    const [backendData, loading, error, fetchData] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        url: process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL + window.location.pathname,
        requestConfig: {
            headers: {
                'Content-Language': 'en-US',
            }
        }
    });

    React.useEffect(() => {
        fetchData();
    },[])

    if (loading) {
        return null;
    }


    async function submitHandler(data) {
        const {email} = data;

        const success = await forgotPasswordRequest(email, window.location.pathname);

        const message = success ?  backendData.succesfulMessage : backendData.errorMessage;
        setIsSuccesfulMessage(success);
        setResponseMessage(message);
    }
    const emailIcon = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "email.webp";


    return(
        <LanguagePageContext.Provider value={backendData}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{backendData.resetTitle} | Lingotales</title>
            </Helmet>
            <div className="container px-0 mr-0">
                <div className="row mx-auto login-show" style={{maxWidth: "500px"}}>
                    <form onSubmit={handleSubmit(submitHandler)} className="text-center">
                        <h6 className="login-title-lingotales mt-3 mb-3">{"LingoTales"}</h6>
                        <h6 className="login-title mt-3 mb-3">{backendData.resetTitle}</h6>
                        <p className="forgot-password-email-text">{backendData.resetText}</p>
                        <div className="mt-3 my-form-group register-input-container">
                            <img src={emailIcon} atl="input_icon" className="input-icon"/>
                            <input type="email" className="register-input" id="email" placeholder={backendData.emailPlaceHolder} {...register("email", {required : true})}/>
                        </div>
                        <p className={`forgot-password-email-text ${isSuccesfulMessage ? "green" :"red"}`}>{responseMessage}</p>
                        <div className="row mx-auto py-2" style={{maxWidth: "180px"}} >
                            <Button content={backendData.continueButtonContent} isFooter={false} isDisable={isSuccesfulMessage ? true : false}/>
                        </div>
                    </form>
                </div>
            </div>    
        </LanguagePageContext.Provider>
    );
}