import {Outlet, Navigate} from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import { getToken, logout } from '../utils/auth';
import { getUrlRoot } from "../utils/utils.js"

const PrivateRoutes = () => {

    const [tokenData, setTokenData] = React.useState();
    const [tokenFetched, setTokenFetched] = React.useState(false); // New state to track token fetch status

    React.useEffect( () => {

        const fetchData = async () => {
            try {
              const response = await axios.get(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}/protected`, {
                headers: {
                  Authorization: getToken()
                }
              });
              setTokenData(response.data);
            } catch (error) {
              // console.error("Error fetching data:", error);
              logout();
            //   setTokenFetched(true);
            } 
            finally {
              setTokenFetched(true);
            }
          };
          fetchData();
        
    }, [])

    const isValidToken = tokenData && tokenData.isValid;

    const langPrefix = getUrlRoot();

    

    if (!tokenFetched) {
        return null;
      }
    

    return(
        isValidToken  ? <Outlet/> : <Navigate to={`${langPrefix}/login`}/>
    )
}

export default PrivateRoutes