import React from 'react';
import { useNavigate } from "react-router-dom";
import Button from '../components/ui/Button';
import SelectLanguageModal from '../components/modals/SelectLanguageModal';
import Container from "react-bootstrap/esm/Container";

import { LanguagePageContext } from '../context/LanguagePageContext';

// import DropdownLanguage from './DropdownLanguage';

import "../styles/navbarext.css"


export default function NavBarExt(props) {

    const [modalShow, setModalShow] = React.useState(false);

    const languagePageData = React.useContext(LanguagePageContext);

    const logoPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "mice.png";

    //console.log(languagePageData);

    const navigate = useNavigate();

    function redirection(address) {

        let currentPath = window.location.pathname;

        if ((currentPath === "/" && address !== "/") || (currentPath === "/blog" && address !== "/blog")|| 
            (currentPath === "/login" && address !== "/login") || (currentPath === "/register" && address !== "/register") || (currentPath === "/about" && address !== "/about")) {
            currentPath = address;
        }
        else if (   currentPath === "/en" || currentPath === "/de" || currentPath === "/fr" || currentPath === "/ru" ||
                    currentPath === "/it" || currentPath === "/pt" || currentPath === "/pl" || currentPath === "/es") {

            if (address === "/") {
                return;
            }

            currentPath = currentPath + address;
        }

        else if (currentPath.length > 3) {
            // console.log("im being printed");
            if (currentPath.slice(-address.length) === address){
                return
            }

            if (address !== "/") {
                if (window.location.pathname === "/blog/post") {
                    currentPath = address;
                }
                else {
                    currentPath = currentPath.slice(0, 3) + address;
                }
            }
            else{
                if (window.location.pathname === "/blog/post") {
                    currentPath = address;
                }
                else{
                    currentPath = currentPath.slice(0, 3);
                }
            }
        }

        else {
            return;
        }


        navigate(currentPath);
    }

    //TEMPORARY VALUES
    const navBlog = languagePageData.blogNav || languagePageData.navBar[0].navBlog;
    const navRegister = languagePageData.register || languagePageData.navBar[0].navRegister;
    const navLogin = languagePageData.login || languagePageData.navBar[0].navLogin;
    const navLanguage = languagePageData.language || languagePageData.navBar[0].navLanguage;



    return(
        <nav>
            <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "20px"}}>
                <div className="w-100" style={{maxWidth: "1400px"}}>
                    <div className='container'>
                        <div className='row py-2'>
                                <div className="col-md-3 col-lg-2 col-4 d-flex justify-content-center align-items-center my-1 name-container" onClick={() => redirection("/")}>
                                    <img className='nav-logo' src={logoPath}/>
                                    <p className='nav-name' >LingoTales</p>
                                    {/* <Link to="/" className="nav-name">STORIESPOLY</Link> */}
                                </div>
                                {/* <div className="col-sm-1 col-md-1 col-lg-1 d-flex justify-content-center px-0 my-1"> */}
                                {/* <div className="col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center"> */}
                                    {/* {!props.isRegister  && <p className='nav-title' onClick={() => redirection("/stories/english")}>STORIES</p>} */}
                                    
                                {/* </div> */}
                                {/* <div className="col-sm-1 col-md-1 col-lg-1 d-flex justify-content-center px-0 my-1 align-items-center"> */}
                                    {/* {(!props.isRegister && !props.isLogin && !props.isBlog) && <Button content={languagePageData.blog} buttonHandler={() => redirection("/blog")} isNavBar={true}/>} */}
                                    {/* {!props.isRegister && <p className='nav-item' onClick={() => redirection("/blog")}>{languagePageData.blog}</p>} */}
                                    
                                {/* </div> */}
                                <div className="col-md-6 col-lg-8 col-4 d-flex justify-content-center align-items-center my-1">
                                    {/* <Button content={languagePageData.language} isNavBar={true}/> */}
                                    {!props.isRegister && <p className='nav-item hover-underline-animation d-md-block d-none' onClick={() => redirection("/blog")}>{navBlog}</p>}

                                    {!props.isBlogPage && <p className='nav-item hover-underline-animation dropdown' onClick={() =>setModalShow(true)}>{navLanguage}</p>}
                                    {/* <DropdownLanguage isHeader={false} currentPath={props.currentPath} setCurrentPath={props.setCurrentPath} languageList={languagePageData.languageList} isNavBar={true}/>  */}
                                </div>
                                {/* <div className="col-sm-1 col-md-1 col-lg-1 d-flex justify-content-center px-0 my-1"></div> */}
                                <div className="col-md-3 col-lg-2 col-4 d-flex justify-content-center align-items-center px-0 my-1">
                                    {(props.isRegister || props.isHome || props.isDevel || props.isBlog || props.isBlogPage) && <Button content={navLogin} buttonHandler={() => redirection("/login")} isNavBar={true}/>}
                                    {props.isLogin  && <Button content={navRegister} buttonHandler={() => redirection("/register")} isNavBar={true}/>}
                                    {/* <p className='nav-item' onClick={() => redirection("/login")}>Login</p>    */}
                                </div>

                            
                        </div>
                    </div>
                            <SelectLanguageModal show={modalShow} onHide={() => setModalShow(false)}/>
                </div>
            </Container>
        </nav>
    );
}
