import React from "react";
import "../styles/lognavbar.css"
import Flag from "../components/ui/Flag";

import { useNavigate } from "react-router-dom";
import { LanguagePageContext } from '../context/LanguagePageContext';
import AccountModal from "../components/modals/AccountModal";

export default function LogNavBar(props) {

    const languagePageData = React.useContext(LanguagePageContext);


    const logoPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + languagePageData.logNavBar[0].navLingotalesImg;
    const homePath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + languagePageData.logNavBar[0].navHomeImg;
    const discoverPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + languagePageData.logNavBar[0].navDiscoverImg;
    const trophyPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + languagePageData.logNavBar[0].navChallengesImg;
    const chalkboardPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + languagePageData.logNavBar[0].navLessonsImg;
    const dictionaryPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + languagePageData.logNavBar[0].navDictonaryImg;
    const worldPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + languagePageData.logNavBar[0].navLanguageImg;
    const loginPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + languagePageData.logNavBar[0].navAccountImg;


    const activeLanguage = props.activeLanguage !== undefined ? props.activeLanguage : "english";

    // const buttonDataAccount = [{prompt: "Profile", id:"account"},{prompt: "Log Out", id:"logout"}];
    // const buttonDataLanguagePage = [{prompt: "English", id:"en"},{prompt: "Spanish", id:"es"}, {prompt: "Russian", id:"ru"},{prompt: "German", id:"de"}, {prompt: "Italian", id:"it"},{prompt: "Polish", id:"pl"}, {prompt: "French", id:"fr"},{prompt: "Portuguese", id:"pt"}];

    const navigate = useNavigate();

    function redirection(addres){

        navigate(addres);
        navigate(0);

    }

    function languageSelecthandler(event) {
        // console.log(event.target);
        if (event.target.id === undefined || event.target.id === "" ) {
            return;
        }
        const address = window.location.pathname.slice(0, window.location.pathname.lastIndexOf("/discover")) + "/discover/" + event.target.id;
        navigate(address);
    }

    let languageList;

    if (props.isDiscover) {
        languageList = languagePageData.flags.map((flag, i) => {
        const currentLanguage = flag.language.toLowerCase();

        return(
            <div key={i} className="col-3 col-lg-1 col-md-2 d-flex align-items-center flex-column" onClick={languageSelecthandler}>
                <Flag id={currentLanguage} fileName={flag.fileName} format={"header-stories"}/>
                {props.activeLanguage === currentLanguage && <p className="caption-size emphasis" id={currentLanguage} >{flag.label} </p>}
                {props.activeLanguage !== currentLanguage && <p className="caption-size" id={currentLanguage}>{flag.label} </p>}
            </div>)
        })
    }


    return(
        <nav className="nav-container">
            <div className='container' onClick={props.closeModalHandler}>
                <div className='row mx-auto p-2' style={{maxWidth: "1200px"}}>
                    <div className="col-lg-2 order-lg-0 col-4 order-0 d-flex justify-content-center center-div pt-2" onClick={() => redirection(window.location.pathname.slice(0,4) + "home")}>
                        <img className='nav-logo' src={logoPath}/>
                        <p className='nav-name' >{languagePageData.logNavBar[0].navLingotales}</p>
                    </div>
                    
                    <div className="row col-lg-8 order-lg-1 order-3 d-flex justify-content-center text-center">
                        <div className={`col-lg-2 col-4 py-1 mt-2 d-flex flex-column justify-content-center align-items-center nav-item-container ${props.isHome ? "accent-bg": "animated-container"}`} onClick={() => redirection(window.location.pathname.slice(0,4) + "home")}>
                            <img className='nav-item-img' src={homePath}/>
                            <p className={`log-nav-item mt-1 ${props.isHome ? "selected-text": "unselected-text"}`}>{languagePageData.logNavBar[0].navHome}</p>
                        </div>
                        <div className= {`col-lg-2 col-4 mt-2 d-flex flex-column justify-content-center align-items-center nav-item-container ${props.isDiscover ? "accent-bg": "animated-container"}`} onClick={() => redirection(window.location.pathname.slice(0,4) + "discover/" +  activeLanguage)}>
                            <img className='nav-item-img' src={discoverPath}/>
                            <p className={`log-nav-item mt-1 ${props.isDiscover ? "selected-text": "unselected-text"}`}>{languagePageData.logNavBar[0].navDiscover}</p>
                        </div>
                        <div className="col-lg-2 col-4 mt-2 d-flex flex-column justify-content-center align-items-center nav-item-container unavailable">
                            <img className='nav-item-img' src={trophyPath}/>
                            <p className='log-nav-item mt-1 unselected-text'>{languagePageData.logNavBar[0].navChallenges}</p>
                        </div>
                        <div className="col-lg-2 col-4 mt-2 d-flex flex-column justify-content-center align-items-center nav-item-container unavailable">
                            <img className='nav-item-img' src={chalkboardPath}/>
                            <p className='log-nav-item mt-1 unselected-text'>{languagePageData.logNavBar[0].navLessons}</p>
                        </div>
                        <div className="col-lg-2 col-4 mt-2 d-flex flex-column justify-content-center align-items-center nav-item-container unavailable">
                            <img className='nav-item-img' src={dictionaryPath}/>
                            <p className='log-nav-item mt-1 unselected-text'>{languagePageData.logNavBar[0].navDictionary}</p>
                        </div>
                    </div>

                    
                    {/* <div className="col-sm-2 col-8 order-sm-3 order-2 d-flex justify-content-center align-items-center"> */}
                        
                    <div className="col-lg-1 order-lg-2 col-4 order-1 d-flex flex-column justify-content-center align-items-center px-2 nav-item-container pt-2" onClick={props.openModalHandler} id="language">
                        <img className='nav-item-img' src={worldPath} id="language"/>
                        <p className='log-nav-item mt-1 unselected-text' id="language">{languagePageData.logNavBar[0].navLanguage}</p>
                        {props.modalController[0].show && <AccountModal open={props.modalController[0].show} data={languagePageData.logNavBar[0].navLanguageBar}/>}    
                    </div>

                    <div className="col-lg-1 order-lg-3 col-4 order-2 d-flex flex-column justify-content-center align-items-center px-2 nav-item-container pt-2" onClick={props.openModalHandler} id="account">
                        <img className='nav-item-img' src={loginPath} id="account"/>
                        <p className='log-nav-item mt-1 unselected-text' id="account">{languagePageData.logNavBar[0].navAccount}</p>
                        {props.modalController[1].show && <AccountModal open={props.modalController[1].show} data={languagePageData.logNavBar[0].navAccountBar}/>}
                    </div>
                        
                        
                    {/* </div> */}
                </div>

                {props.isDiscover && 
                <div className="row">
                    <div className="text-center d-flex align-items-center justify-content-center mb-2 pt-1">
                        {languageList}
                    </div>
                </div>}
            </div>
        </nav>
    );
}