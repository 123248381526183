import React from "react";

import "../../styles/translation.css";


export default function Translation(props) {

    const divRef = React.useRef(null);

    React.useEffect(() => {
        divRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', });
    },[]);

    const generateTranslationText = props.translationText.map((word, i) => {
        return(
            <div key={i} className="translation-word-container" >
                <p className={`translation-word-text ${word.active ? "bold" : ""}`} id={word.index} onClick={props.selectWord}>{word.word}</p>
            </div>
        );
    })

    const languageTagList = props.currentTranslation.map((state, i) => {
        if(state.isActive) {
            return(
                <div key={i} className="language-tag-container active-container" id={state.language} data-id={34}>
                    <p className="language-tag active-language">{state.language}</p> 
                </div>
            );
        }
        return(
            <div key={i} className="language-tag-container no-active-container" onClick={props.clickHandler} id={state.language} data-id={props.paragraphId}>
                <p className="language-tag ">{state.language}</p> 
            </div> 
        );
    })

    return(
        <div className="translation-container my-3">
            <div className="list-language-tag-container">
                {languageTagList}
            </div>

            <div ref={divRef} className="d-flex flex-wrap justify-content-start mt-3 mb-3 mx-2">
                {generateTranslationText}
            </div>
            
            {/* <p className="translation-text"> {props.translationText}</p> */}
        </div>
    );
    
}