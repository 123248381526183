import React from "react";
import "../../styles/storycard.css"

import Button from "./Button";
import ProgressBar from "./ProgressBar";
// import { LanguagePageContext } from '../../context/LanguagePageContext';

export default function StoryCard(props) {


    // DATA COMING FROM BACKEND
    //const languagePageData = React.useContext(LanguagePageContext);

    //IMAGES DATA
    const redHeartIconPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "heart.png";
    const imgPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + props.img;

    //CATEGORY SETTINGS
    const categoryList = props.category.map((category, i, arr) => {
        if ( arr.length -1 === i) {
            return <p key={i} className="card--category">{category}</p>
        }
        return <p key={i} className="card--category">{category + ","}</p>
    })

    return(
        <div className="col-md-3 col-sm-6 col-12 mt-2 px-1">
            {/* <div className={`${props.index > 10 ? "disable-card-container": "no"}`}> */}
                
                <div className={`card--container pt-1 pb-1 ${props.levelRef.toLowerCase()}-container ${(props.index > props.availableCardSize && !props.allowed ) ? "disable-card-container": ""}`} id={props.id} onClick={props.clickHandler} index={props.index} language={props.language.toLowerCase()}>
                    <div className="card-login-container">
                        <p className="login-prompt">{props.loginCardPrompt}</p>
                        <Button content={props.loginButtonContent}/>
                    </div>
                        <div className={`${(props.index > props.availableCardSize && !props.allowed) ? "disable-card": ""} test-container`}>
                            <h6 className={`card--title px-1 ${props.levelRef.toLowerCase()}-title`}>{props.title}</h6>
                            <img src={imgPath} alt={props.title} className="card-img"/>
                            <div className="category--container">
                                {categoryList}
                            </div>
                            
                            <p className="card--level">{props.level}</p>
                            <div className="like-container">
                                <p className="card--rate">{props.likes}</p>
                                <img src={redHeartIconPath} alt="" className="heart-icon"/>
                            </div>
                            <p className={`level-tag ${props.levelRef.toLowerCase()}-level-tag`}>{props.levelRef}</p>

                            {props.progress && <ProgressBar bgcolor="#4CAF50" completed={props.progress}/>}
                        </div>
                </div>
            {/* </div> */}
        </div>
    );
}