import React from "react";
import NavBarExt from "../../layout/NavBarExt";
import Button from "../../components/ui/Button";
import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';

import Container from "react-bootstrap/esm/Container";

import "../../styles/register.css";

import { LanguagePageContext } from "../../context/LanguagePageContext";
import { useNavigate } from "react-router-dom";

import useAxios from "../../hooks/useAxios";
import axios from '../../apis/lingotalesApi';

import { registerUser, isLoggedIn } from '../../utils/auth';
import {getUrlRoot} from "../../utils/utils.js";

import {Helmet} from "react-helmet";

export default function Register() {

    const navigate = useNavigate();

    const {register, handleSubmit, formState: { errors }} = useForm();

    const [backendData, loading, error, fetchData] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        url: process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL + window.location.pathname,
        requestConfig: {
            headers: {
                'Content-Language': 'en-US',
            }
        }
    });

    React.useEffect(() => {
        fetchData();
    },[])


    async function submitHandler(data) {
        const {username, email, password} = data;
        const userTargetLanguages = flagListState.filter(flag => flag.isHeld).map(flag => flag.language);

        // Get rid off the first /
        const languagePage = getUrlRoot().slice(1);

        const success = await registerUser(username, email, password, userTargetLanguages, languagePage);

        if (success) {
            // console.log("Login successful!");
            localStorage.setItem('email', JSON.stringify(email));

            setTimeout(() => {
                // console.log(isLoggedIn())
                
                if (isLoggedIn()) {
                    const root = getUrlRoot();
                    navigate(`${root}/home`, { replace: true });
                }

            }, 500)
        } else {
            // console.log("Login failed");
        }

        

    }

    const [flagListState, setFlagListState] = React.useState(backendData.flags);


    React.useEffect(() => {
        setFlagListState(backendData.flags);
    }, [backendData])
    
    const [isSelected, setIsSelected] = React.useState(true);
    const [registerStep, setRegisterStep] = React.useState(1);
    const location = useLocation()

    React.useEffect(() => {
        if (flagListState === undefined) {return} 
        setFlagListState(() => backendData.flags);

      }, [location.key])


    function clickHandler(event) {
        setFlagListState((prev) => prev.map((country) =>{
                    return Number(country.flagId) !== Number(event.target.id) ? country : {
                        ...country,
                        "isHeld" : !country.isHeld
                        }
            })
        )

    }

    React.useEffect(() => {
        if (flagListState === undefined) {return} 

        let isSel = false;

        for(let i=0; i< flagListState.length; i++) {
            if (flagListState[i].isHeld) {
                isSel = true
                break;
            }
        }

        setIsSelected( ()=> isSel);

    }, [flagListState])
    
    const chooseImg = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "choose.png";
    const createImg = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "create.webp";
    const lockIcon = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "lock.webp";
    const usernameIcon = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "username.jpg";
    const emailIcon = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "email.webp";

    let flagList;

    if (flagListState !== undefined){
        flagList = flagListState.map((country, i) => {

            const pathFlag = process.env.REACT_APP_PUBLIC_URL_FLAGS + country.fileName;
    
            return(
                <div key={i} className={`col-sm-3 col-4 mx-auto d-flex align-items-center flex-column emphasis-container ${country.isHeld ? "clicked-flag" : ""}`}>
                    <img className="flag-register-icon" src={pathFlag} alt="flag" onClick={clickHandler} id={country.flagId}/>
                    <p className="flag-register-subtitle" id={country.flagId} onClick={clickHandler}>{country.label}</p>
                </div>
            );
        })
    }
    


    function redirection (action) {
        if(action === "next") {
            setRegisterStep(prev => prev+1);
        }
        if(action === "back") {
            setRegisterStep( prev => prev-1);
        }
    }

    return(
        <>
        {backendData.registerPage === undefined ? <></>: 
        <LanguagePageContext.Provider value={backendData}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{backendData.registerPage[0].form.signUpButtonContent} | Lingotales</title>
            </Helmet>
            <NavBarExt isRegister={true} />
            <Container className="d-flex justify-content-center">
                <div className="row mt-1" style={{maxWidth: "700px"}}>
                    <div className={registerStep !== 1 ? "register-hide" : "register-show"}>
                        <div className="row mt-2 d-flex">
                            <div className={`d-flex justify-content-center flex-column text-center align-items-center`}>
                                <p className="register-img-title">{backendData.registerPage[0].registerFirstStep}</p>
                                <img className="register-img mt-2" src={chooseImg} alt="img"/>
                                <p className="register-img-subtitle mt-2">{backendData.registerPage[0].registerFirstSubtitle}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className={registerStep !== 2 ? "register-hide" : "register-show"}>
                        <div className="row mt-2 d-flex">
                            <div className={`d-flex justify-content-center flex-column text-center align-items-center`}>
                                <p className="register-img-title">{backendData.registerPage[0].registerSecondStep}</p>
                                <img className="register-img" src={createImg} alt="img"/>
                                <p className="register-img-subtitle">{backendData.registerPage[0].registerSecondSubtitle}</p>
                            </div>
                        </div>
                    </div>
                
                    <div className={registerStep !== 1 ? "register-hide" : "register-show mt-3"}>
                        <div className="row mt-2">
                            <div className="text-center">
                                <h6 className="choose-msg">{backendData.registerPage[0].registerChooseLanguageTitle}</h6>
                            </div>
                        </div>
                        <div className="row mt-1">
                            {flagList}
                        </div>
                    </div>

                        
                    <div className={registerStep !== 2 ? "register-hide" : "register-show"}>
                        <div className="row mt-2">
                            <div className="text-center">
                                <h6 className="choose-msg">{backendData.registerPage[0].registerCreateAccountTitle}</h6>
                            </div>
                        </div>

                        <div className="row mt-1 mx-auto">
                            <form onSubmit={handleSubmit(submitHandler)} className="text-center">
                                {errors.username && <p className="registration-error-message">{errors.username.message}</p>}
                                <div className="my-form-group register-input-container">
                                    <img src={usernameIcon} atl="input_icon" className="input-icon"/>
                                    <input type="text" className="register-input" id="username" placeholder={backendData.registerPage[0].form.namePlaceholder} {...register('username', {required: `${backendData.registerPage[0].form.contactNameRequired}`, minLength: { value: 2, message: `${backendData.registerPage[0].form.contactErrorName}` }})}/>    
                                </div>
                                {errors.email && <p className="registration-error-message">{errors.email.message}</p>}
                                <div className="my-form-group register-input-container">
                                    <img src={emailIcon} atl="input_icon" className="input-icon"/>
                                    <input type="email" className="register-input" id="email" placeholder={backendData.registerPage[0].form.emailPlaceholder} {...register('email', {required: `${backendData.registerPage[0].form.contactEmailRequired}`, pattern: {value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, message: `${backendData.registerPage[0].form.contactErrorEmail}`}})}/>
                                </div>
                                {errors.password && <p className="registration-error-message">{errors.password.message}</p>}
                                <div className="my-form-group register-input-container">
                                    <img src={lockIcon} atl="input_icon" className="input-icon-lock"/>
                                    <input type="password" className="register-input" id="password" placeholder={backendData.registerPage[0].form.passwordPlaceholder} {...register('password', {required: `${backendData.registerPage[0].form.contactPasswordRequired}`, minLength: { value: 8, message: `${backendData.registerPage[0].form.contactErrorPassword}` }})}/>
                                </div>
                                <a href={window.location.pathname.slice(0, -8) + "login"} className={"to-login-hypertext mb-2"}>{backendData.registerPage[0].form.alreadyAccount}</a>
                                <div className="mx-auto" style={{maxWidth: "160px"}}>
                                    <Button content={backendData.registerPage[0].form.signUpButtonContent} isFooter={false} isDisable={false}/>
                                </div>
                            </form>
                        </div>
                    </div>
                        
                    <div className={`mx-auto mt-4 ${registerStep !== 1 ? "register-hide" : "register-show"}`}  style={{maxWidth: "180px"}}>
                        <Button content={backendData.registerPage[0].registerContinueButton} buttonHandler={() => redirection("next")} isDisable={!isSelected}/>
                    </div>

                    {/* <div className={`mx-auto ${registerStep !== 2 ? "register-hide" : "register-show"}`} style={{maxWidth: "300px"}}>
                        <Button content={backendData.registerPage[0].registerBackButton} buttonHandler={() => redirection("back")} isDisable={false}/>
                    </div> */}
                </div>
            </Container>
        </LanguagePageContext.Provider>
        }
        </>
    );
}