const data = {
    "/en": {
        "errorPasswordMessage": "The passwords are not the same.",
        "errorUpdateMessage": "Something went wrong...It was not possible to update the new password.",
        "succesfullUpdateMessage": "Congratulations! Your password has been updated. Please log in to your account.",
        "resetPasswordTitle": "Reset Password",
        "successfulPasswordTitle": "Password Updated!",
        "newPasswordLabel":"New Password",
        "confirmPasswordLabel": "Confirm New Password",
        "submitButtonContent": "Submit",
        "LoginButtonContent": "Log In"
    },

    "/fr": {
        "errorPasswordMessage": "Les mots de passe ne sont pas les mêmes.",
        "errorUpdateMessage": "Il n'a pas été possible de mettre à jour le nouveau mot de passe.",
        "succesfullUpdateMessage": "Félicitations pour votre mot de passe ! Votre mot de passe a été mis à jour. Veuillez vous connecter à votre compte.",
        "resetPasswordTitle": "Réinitialiser le mot de passe",
        "successfulPasswordTitle": "Mot de passe mis à jour !",
        "newPasswordLabel":"Nouveau mot de passe",
        "confirmPasswordLabel": "Confirmer le nouveau mot de passe",
        "submitButtonContent": "Soumettre",
        "LoginButtonContent": "Se connecter"
    },

    "/de": {
        "errorPasswordMessage": "Die Kennwörter sind nicht identisch..",
        "errorUpdateMessage": "Irgendetwas ist schief gelaufen... Es war nicht möglich, das neue Passwort zu aktualisieren.",
        "succesfullUpdateMessage": "Herzlichen Glückwunsch! Ihr Passwort wurde aktualisiert. Bitte melden Sie sich bei Ihrem Konto an.",
        "resetPasswordTitle": "Kennwort zurücksetzen",
        "successfulPasswordTitle": "Passwort aktualisiert!",
        "newPasswordLabel":"Neues Passwort",
        "confirmPasswordLabel": "Neues Passwort bestätigen",
        "submitButtonContent": "Einreichen",
        "LoginButtonContent": "Einloggen"
    },

    "/ru": {
        "errorPasswordMessage": "Пароли не совпадают.",
        "errorUpdateMessage": "Что-то пошло не так... Не удалось обновить новый пароль.",
        "succesfullUpdateMessage": "Поздравляем! Ваш пароль был обновлен. Пожалуйста, войдите в свою учетную запись.",
        "resetPasswordTitle": "Сброс пароля",
        "successfulPasswordTitle": "Пароль обновлен!",
        "newPasswordLabel":"Новый пароль",
        "confirmPasswordLabel": "Подтверждение нового пароля",
        "submitButtonContent": "Отправить",
        "LoginButtonContent": "Войти"
    },

    "/it": {
        "errorPasswordMessage": "Le password non sono le stesse.",
        "errorUpdateMessage": "Qualcosa è andato storto... Non è stato possibile aggiornare la nuova password.",
        "succesfullUpdateMessage": "Congratulazioni! La password è stata aggiornata. Accedere al proprio account.",
        "resetPasswordTitle": "Reimpostare la password",
        "successfulPasswordTitle": "Password aggiornata!",
        "newPasswordLabel":"Nuova password",
        "confirmPasswordLabel": "Conferma la nuova password",
        "submitButtonContent": "Invia",
        "LoginButtonContent": "Accedi"
    },

    "/pl": {
        "errorPasswordMessage": "Hasła nie są takie same.",
        "errorUpdateMessage": "Coś poszło nie tak... Nie można było zaktualizować nowego hasła.",
        "succesfullUpdateMessage": "Gratulacje! Twoje hasło zostało zaktualizowane. Zaloguj się na swoje konto.",
        "resetPasswordTitle": "Resetowanie hasła",
        "successfulPasswordTitle": "Hasło zaktualizowane!",
        "newPasswordLabel":"Nowe hasło",
        "confirmPasswordLabel": "Potwierdź nowe hasło",
        "submitButtonContent": "Prześlij",
        "LoginButtonContent": "Zaloguj się"
    },

    "/pt": {
        "errorPasswordMessage": "As palavras-passe não são as mesmas.",
        "errorUpdateMessage": "Algo correu mal... Não foi possível atualizar a nova palavra-passe.",
        "succesfullUpdateMessage": "Parabéns! A sua palavra-passe foi actualizada. Inicie sessão na sua conta.",
        "resetPasswordTitle": "Repor a palavra-passe",
        "successfulPasswordTitle": "Palavra-passe actualizada!",
        "newPasswordLabel":"Nova palavra-passe",
        "confirmPasswordLabel": "Confirmar a nova palavra-passe",
        "submitButtonContent": "Enviar",
        "LoginButtonContent": "Iniciar sessão"
    },

    "/es": {
        "errorPasswordMessage": "Las contraseñas no son las mismas.",
        "errorUpdateMessage": "Algo salió mal... No fue posible actualizar la nueva contraseña.",
        "succesfullUpdateMessage": "Enhorabuena. Su contraseña ha sido actualizada. Inicie sesión en su cuenta.",
        "resetPasswordTitle": "Restablecer contraseña",
        "successfulPasswordTitle": "Contraseña actualizada",
        "newPasswordLabel":"Nueva contraseña",
        "confirmPasswordLabel": "Confirmar nueva contraseña",
        "submitButtonContent": "Enviar",
        "LoginButtonContent": "Iniciar sesión"
    }
}

export default data;