import axios from 'axios';
import Cookies from "js-cookie";

const apiUrl = 'http://localhost:4000'; // Your API URL

export const getCsrfToken = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}/get-csrf-token`);
    const csrfToken = response.data.csrfToken;

    return csrfToken;
  } catch (error) {
    // console.error('csrf Token failed:', error);
    return null;
  }
};
// Function to log in the user and get the JWT token
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}/login`, { email: email, password: password });

    const { token, languagePage } = response.data;
    // Save the token as an HTTP-only
    // Cookies.set('token', token, { expires: 1, secure: true, sameSite: 'None', httpOnly: true });
    Cookies.set('token', token, { expires: 1});
    localStorage.setItem("languagePage", languagePage)

    return true;
  } catch (error) {
    // console.error('Login failed:', error);
    return false;
  }
};

export const registerUser = async (username, email, password, targetLang, languagePage) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}/register`, {username: username, email: email , password: password, targetLang: targetLang, languagePage: languagePage });
    const { token } = response.data;
    Cookies.set('token', token, { expires: 1});

    return true;
  } catch (error) {
    // console.error('Registration failed:', error);
    return false;
  }
};

// Function to log out the user and remove the token
export const logout = () => {
  // Remove the token from Cookie
  Cookies.remove('token');
  localStorage.clear();
};

// Function to get the JWT token from localStorage
export const getToken = () => {
  const token =  Cookies.get('token');
  // console.log(token);
  return token;
};

// Function to check if the user is logged in (has a valid token)
export const isLoggedIn = () => {
  const token = getToken();
  return !!token;
};

// Function to check if the user exists
export const forgotPasswordRequest = async (email, pathname) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}${pathname}`, {email});

    if (response.data.isSuccessful) {
      return true;
    }
    else{
      return false;
    }

    
  } catch (error) {
    // console.error('Login failed:', error);
    return false;
  }
};

// Function to reset password
export const resetPasswordRequest = async (password, id, token) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}/reset-password/${id}/${token}`, {password});

    return response.data.isSuccessful;

    
  } catch (error) {
    // console.error('Login failed:', error);
    return false;
  }
};


