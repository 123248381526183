import React from "react";
import "../../styles/checkbox.css";


export default function CheckBox(props) {

    return(
        <div className={`check-container ${props.isDisable}` }>
            <input className="filter-check-input" type="checkbox" checked ={props.value} value={props.value} id={props.id} name={props.id} {...props.register(props.id)} onClick={props.handleClick}/>
            <label className={`filter-section-items label-size ${props.value ? "checked": ""}`} htmlFor={props.id}>{props.label}</label>
        </div>
    );
}