import React from "react";
import Button from "./Button";
import StoryCard from "./StoryCard";
import { useNavigate } from "react-router-dom";
import { LanguagePageContext } from "../../context/LanguagePageContext";

import "../../styles/storycardlist.css"

export default function StoryCardList(props) {


    const languagePageData = React.useContext(LanguagePageContext);

    const navigate = useNavigate();

    function cardSelectorHandler (event) {
        const storyLanguage = event.target.getAttribute("language")
        const storyId = event.target.id;
        const address = window.location.pathname.slice(0, 4) + "discover/" + storyLanguage + "/" + storyId;
        navigate(address);
    }


    const [next, setNext] = React.useState(true);
    const [prev, setPrev] = React.useState(true);
    const [currentCards, setCurrentCards] = React.useState([]);

    const page = React.useRef(1);
    const step = React.useRef(4); // humber of cards to display in the page
    const nextDisable = React.useRef(false);
    const prevDisable = React.useRef(false);


    function setPage(state) {

        if (state === "next") {
            setNext((prev) =>{
                return !prev;
            })
            return;
        }

        if (state ==="prev") {
            setPrev((prev) =>{
                return !prev;
            })
            return;
        }    
    }


    const AllstoryCardList = props.storyCards.map((card, index) => {
        const loginPrompt = "Login to get full access";
        const loginButtonContent = "Login";

        // console.log(card);
        
        return <StoryCard 
                        title={card.title} 
                        category={card.categoryLabel} 
                        level={card.levelLabel} 
                        likes={card.likes} 
                        img={card.fileName} 
                        levelRef={card.levelRef} 
                        id={card.id}
                        clickHandler={(event) => cardSelectorHandler(event)}
                        index={index + 1}
                        allowed={true}
                        language={card.language}
                        loginCardPrompt={loginPrompt}
                        loginButtonContent={loginButtonContent}
                        key={index}
                        progress = {card.progress}
                        />
    })

    const [storyCardList, setStoryCardList] = React.useState(AllstoryCardList);

    React.useEffect(() => {
        let tempAllstoryCardList
        if (props.storyCards !== undefined) {

            tempAllstoryCardList = props.storyCards.map((card, index) => {
                return <StoryCard 
                                title={card.title} 
                                category={card.categoryLabel} 
                                level={card.levelLabel}  
                                likes={card.likes} 
                                img={card.fileName} 
                                levelRef={card.levelRef} 
                                id={card.storyId}
                                clickHandler={(event) => cardSelectorHandler(event)}
                                index={index + 1}
                                key={index}
                                allowed={true}
                                language={card.language}
                                progress = {card.progress}
                                />
            })
        }

        setStoryCardList(tempAllstoryCardList);
    }, [props.storyCards])

    React.useEffect(()=>{
        page.current = page.current + 1;
        console.log(page.current);
        updateCards("next");
    }, [next])

    React.useEffect(()=>{
        page.current = page.current - 1;
        updateCards("prev");

    }, [prev])


    React.useEffect(() => {
        page.current = 1;
        updateCards("init");
    }, [storyCardList])



    function updateCards(action) {
        
        const lastPage = Math.ceil(storyCardList.length / step.current);
        const firstPage = 1;

        let nextCount = 0;
        let currentCount = 0;

        const condition1 = page.current < lastPage && page.current >= firstPage;
        const condition2 = page.current > firstPage && page.current <= lastPage;
        const condition3 = action === "init";


        if (!condition3) {
            nextDisable.current = condition1 ? false : true;
            prevDisable.current = condition2 ? false : true;
        }

        if (condition1 || condition2 || condition3 ) {
            currentCount = page.current * step.current - step.current;

            if (storyCardList.length - currentCount >= step.current) {
                nextCount = currentCount + step.current;
            }
            else{
                nextCount = storyCardList.length;
            }
            
            setCurrentCards(() => {
                return storyCardList.slice(currentCount, nextCount);
            })
        }

        if (condition3) {

            if (storyCardList.length <= step.current) {
                nextDisable.current = true;
                prevDisable.current = true;
            }

            if (storyCardList.length > step.current) {
                nextDisable.current = false;
                prevDisable.current = true;
            }
        }
    }

    return(
        <div className="mt-3">
            <h6 className="storycardlist-category-subtitle">{props.categoryTitle}</h6>
            {props.flagList}
            <div className="row d-flex">
                {currentCards}
            </div>

            <div className="row d-flex justify-content-center align-items-center mt-3" >
                <div className="col-md-2 px-0 col-sm-3 col-4">
                    {currentCards.length > 0 && <Button content={languagePageData.dashboard[0].backButtonContent} buttonHandler={() => setPage("prev")} isDisable={prevDisable.current}/>}
                </div>
                <div className="col-1 d-flex justify-content-center align-items-center">
                    {currentCards.length > 0 && <p className="page-number text-center mx-auto">{page.current}</p>}
                </div>
                <div className="col-md-2 px-0 col-sm-3 col-4">
                    {currentCards.length > 0 && <Button content={languagePageData.dashboard[0].nextButtonContent} buttonHandler={() => setPage("next")} isDisable={nextDisable.current}/>}
                </div>
            </div>
        </div>
    );
}