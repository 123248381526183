import React from 'react';
import "../../styles/progressbar.css"

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    display: 'flex',
    alignItems: 'center',
    height: '10%',
    width: '50%',
    backgroundColor: "#f7f7f7",
    borderRadius: 30,
    margin: 0
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'center'
  }

  const labelContainerStyles = {
    display: 'flex',
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center', // Center content vertically
    width: '100%', // Fill the entire progress bar width
    height: '100%', // Fill the entire progress bar height
  }

  const labelStyles = {
    padding: 2,
    color: 'white',
    fontWeight: 'bold',
    fontSize: `${Math.min(100 / (completed + 1), 100) + 32}%`,
    margin: 0
  }

  return (
    <div style={containerStyles} className='my-1'>
      <div style={fillerStyles}>
        <div style={labelContainerStyles}>
          <p style={labelStyles}>{`${completed}%`}</p>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
