import React from "react";

import "../../styles/terms.css";

import SecondaryFooter from "../../layout/SecondaryFooter";

import useAxios from "../../hooks/useAxios";
import axios from '../../apis/lingotalesApi';

import { LanguagePageContext } from '../../context/LanguagePageContext';

import {Helmet} from "react-helmet";



export default function Privacy() {

    const lingotalesLogo = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "mice.png"

    const [response, loading, error, fetchData] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        url: process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL + window.location.pathname,
        requestConfig: {
            headers: {
                'Content-Language': 'en-US',
            }
        }
    });

    React.useEffect(() => {
        fetchData();
    },[])

    if (loading) {return null}


    const sectionList = response.privacyPage.sections.map( (section, idx) => {
        return (<section className="mt-2">
            <h2 className="terms-section-title">{idx + 1 }. {section.title}</h2>
            <p className="terms-section-text mt-1">{section.text}</p>
        </section>)
    })

    return(
        <LanguagePageContext.Provider value={response}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{response.privacyPage.privacySubtitle} | Lingotales</title>
                <meta
                name="description"
                content= {response.privacyPage.sections[0].text}
                />
                <link rel="canonical" href="https://lingotales.com/privacy"/>
                <link rel="alternate" hreflang="en" href="https://lingotales.com/en/privacy"/>
                <link rel="alternate" hreflang="de" href="https://lingotales.com/de/privacy"/>
                <link rel="alternate" hreflang="fr" href="https://lingotales.com/fr/privacy"/>
                <link rel="alternate" hreflang="es" href="https://lingotales.com/es/privacy"/>
                <link rel="alternate" hreflang="it" href="https://lingotales.com/it/privacy"/>
                <link rel="alternate" hreflang="pl" href="https://lingotales.com/pl/privacy"/>
                <link rel="alternate" hreflang="pt" href="https://lingotales.com/pt/privacy"/>
                <link rel="alternate" hreflang="ru" href="https://lingotales.com/ru/privacy"/>
            </Helmet>
            <main className="terms-container">
                <div className="d-flex align-items-center">
                    <img className="terms-lingotales-logo" src={lingotalesLogo} alt={"Lingotales"}/>
                    <h1 className="terms-lingotales-title px-2">Lingotales</h1>
                </div>
                <h2 className="terms-subtitle mt-2">{response.privacyPage.privacySubtitle}</h2>
                <p className="terms-date">{response.privacyPage.effectiveDate}</p>
                {sectionList}
            </main>
            <SecondaryFooter/>
        </LanguagePageContext.Provider>
    );
}