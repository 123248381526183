import React, { useRef, useState, useEffect } from 'react';

import "../../styles/audioplayer.css";

function AudioPlayer(props) {

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);

  const audioSpeedDelta = 0.10;

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleAudioEnded = () => {
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const handleAudioSpeed = (changedStepSpeed) => {
    const minAudioSpeed = 0.7;
    const maxAudioSpeed = 1.4;

    if (playbackSpeed + changedStepSpeed < minAudioSpeed) {
      return
    }
    if (playbackSpeed + changedStepSpeed > maxAudioSpeed) {
      return
    }


    setPlaybackSpeed(playbackSpeed => playbackSpeed + changedStepSpeed);

    audioRef.current.playbackRate = playbackSpeed + changedStepSpeed;
  }

  useEffect(() => {
    audioRef.current.addEventListener('loadedmetadata', () => {
      setDuration(audioRef.current.duration);
    });
  
    audioRef.current.addEventListener('timeupdate', () => {
      setCurrentTime(audioRef.current.currentTime);
      setProgress((audioRef.current.currentTime / duration) * 100);
      setTimeRemaining(duration - audioRef.current.currentTime);
    });
  
    audioRef.current.addEventListener('ended', handleAudioEnded);
  
    return () => {
      // Use optional chaining to check if audioRef.current exists before removing event listeners
      audioRef.current?.removeEventListener('loadedmetadata', () => {});
      audioRef.current?.removeEventListener('timeupdate', () => {});
      audioRef.current?.removeEventListener('ended', handleAudioEnded);
    };
  }, [duration]);
  

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleProgressBarClick = (e) => {
    if (!isSeeking) {
      const progressBar = e.currentTarget;
      const clickX = e.clientX - progressBar.getBoundingClientRect().left;
      const newTime = (clickX / progressBar.offsetWidth) * duration;
      audioRef.current.currentTime = newTime;
    }
  };

  const playPauseIcon = isPlaying ? process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "pause.png" : process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "play-button-arrowhead.png";
  const audioMinusIcon = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "rewind-button.png";
  const audioPlusIcon = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "fast-forward.png";


  return (
    <div className="custom-audio mt-1">
      <div className="player-controls d-flex align-items-center px-1 py-1">
        <img src={playPauseIcon} alt={isPlaying ? 'pause-icon' : 'play-icon'} className={`audio-play-icon ${isPlaying ? 'playing' : ''}`} onClick={togglePlay} />
        <audio ref={audioRef} src={props.audioFile} type="audio/wav" />
        <div className='d-lg-block d-sm-none'>
          <div className='d-flex align-items-center px-1 '>
            <img src={audioMinusIcon} alt="minus-audio" className="audio-icon" onClick={ () => handleAudioSpeed(-audioSpeedDelta)} />
            <span className="time-remaining small-text px-1">x{playbackSpeed.toFixed(2)}</span>
            <img src={audioPlusIcon} alt="plus-audio" className="audio-icon" onClick={ () => handleAudioSpeed(audioSpeedDelta)} />
          </div>
        </div>
        <div className="progress-bar mx-1" onClick={handleProgressBarClick}>
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
        <div className="time-remaining small-text px-1">{formatTime(timeRemaining)}</div>
      </div>
    </div>
  );
}

export default AudioPlayer;
