import React from "react";
import Button from "../../components/ui/Button";
// import FlagList from "./FlagList";
import LanguageList from "./LanguagesList";

import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import Title from "./Title";

import { LanguagePageContext } from '../../context/LanguagePageContext';

import "../../styles/hero.css";

export default function Hero() {

    const languagePageData = React.useContext(LanguagePageContext);

    const [text, setText] = React.useState(languagePageData.homePage[0].eslogan) //array of objects  [{"index" : "1", "word" : "Immerse", "set" : "1", "active": false},...]

    const treePath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "tree.webp";
    const translationPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + "english-spanish-translation.png";

    

    const navigate = useNavigate();

    function redirection(address) {

        if (address !== undefined) {

            let currentPath = window.location.pathname;

            if(currentPath === "/") {
                currentPath = address;

            }
            else {
                currentPath = currentPath + address;

            }

            navigate(currentPath);
        }
        return;
    }

    function selectWord(event) {
        // console.log(event.target.id);

    }

    //List of all words eslogan
    const createText = text.map((word, i) => {
        return(
            <div key={i} className="my-word-container" >
                <p className={`my-word-text ${word.active ? "bold" : ""}`} id={word.set} onClick={selectWord}>{word.word}</p>
            </div>
        );
    })

    

    const location = useLocation()

    React.useEffect(() => {
        setText(() => languagePageData.homePage[0].eslogan);
      }, [location.key])
    
    return(
        <article>
            <div className="container ">
                <div className="row">
                <div className="col-lg-6 col-12 px-4 d-flex flex-column justify-content-between mt-4">
                    <div>
                    <Title title={languagePageData.homePage[0].JourneyTitle} />
                    <div className="d-flex flex-wrap eslogan-container mt-3">
                        {createText}
                    </div>
                    </div>
                    {/* <div className="button-list-container mt-3"> */}
                    <div className="">
                        <button
                            className={"button-container shadow-button start-button col-md-4 mx-1 mt-3"}
                            onClick={() => redirection("/register")}
                        >
                            <p className="button-message">
                            {languagePageData.homePage[0].startButtonContent}
                            </p>
                        </button>
                        <button
                            className={"button-container shadow-button bright-button mx-1 col-md-7 mt-3"}
                            onClick={() => redirection("/login")}
                        >
                            <p className="button-message">
                            {languagePageData.homePage[0].loginButtonContent}
                            </p>
                        </button>
                    </div>
                </div>
                <div className="col-lg-6 mt-4">
                    {/* <img src={translationPath} alt={"translation"} className="img-max" /> */}
                    <LanguageList/>
                </div>
                </div>
            </div>
        </article>
    );
}