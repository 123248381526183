import React from "react";
import "../../styles/form.css";
import Button from "../../components/ui/Button";
import ContactModal from "../../components/modals/ContactModal";

import { useForm } from "react-hook-form";
import axios from "axios"

import { LanguagePageContext } from '../../context/LanguagePageContext';

export default function Form() {

    const {register, handleSubmit, reset, formState: { errors }} = useForm();

    const languagePageData = React.useContext(LanguagePageContext);

    const[show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function submitHandler(data) {

        axios.post(`${process.env.REACT_APP_LINGOTALES_BASE_SERVER_URL}/contact`, data).then(response => {
            // console.log(response.data)
            // setIsModalOpen(true);
            reset(); // Reset the form values
            handleShow();

        }).catch( error => {
            // console.log(error)
        })
    }

    return(
        <div>
            <form onSubmit={handleSubmit(submitHandler)}>
                <p className="form-title">{languagePageData.homePage[0].form.contactTitle}</p>
                {errors.username && <p className="contact-error-message">{errors.username.message}</p>}
                <div className="my-form-group mt-2">
                    <label htmlFor="username" className="my-form-label">{languagePageData.homePage[0].form.nameLabel}</label>
                    <input type="text" className="my-form-controll" id="username" placeholder={languagePageData.homePage[0].form.namePlaceholder} {...register('username', {required: `${languagePageData.homePage[0].form.contactNameRequired}`, minLength: { value: 2, message: `${languagePageData.homePage[0].form.contactErrorName}` }})}/>
                </div>
                {errors.email && <p className="contact-error-message">{errors.email.message}</p>}
                <div className="my-form-group mt-2">
                    <label htmlFor="email" className="my-form-label">{languagePageData.homePage[0].form.emailLabel}</label>
                    <input type="email" className="my-form-controll" id="email" placeholder={languagePageData.homePage[0].form.emailPlaceholder} {...register('email', {required: `${languagePageData.homePage[0].form.contactEmailRequired}`, pattern: {value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, message: `${languagePageData.homePage[0].form.contactErrorEmail}`}})}/>
                </div>
                {errors.message && <p className="contact-error-message">{errors.message.message}</p>}
                <div className="my-form-group mt-2 mb-2">
                    <label htmlFor="message" className="my-form-label">{languagePageData.homePage[0].form.messageLabel}</label>
                    <textarea className="my-form-controll" id="message" rows="3" placeholder={languagePageData.homePage[0].form.messagePlaceholder} {...register('message', {required: `${languagePageData.homePage[0].form.contactMessageRequired}`, minLength: { value: 2, message: `${languagePageData.homePage[0].form.contactErrorMessage}` }})}></textarea>
                </div>
                <Button content={languagePageData.homePage[0].form.submitButtonContent} isFooter={true} isDisable={false}/>
            </form>
            <ContactModal show={show} handleClose={handleClose}/>
        </div>
    );
}