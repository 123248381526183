// Function to check if the user is logged in (has a valid token)
export const getUserCreatedMonth = (langCode, monthNumber) => {
    const month = {"en":  {
                      0: "Member since January",
                      1: "Member since February",
                      2: "Member since March",
                      3: "Member since April",
                      4: "Member since May",
                      5: "Member since June",
                      6: "Member since July",
                      7: "Member since August",
                      8: "Member since September",
                      9: "Member since October",
                      10: "Member since November",
                      11: "Member since December",
                      },
                  "de":  {
                      0: "Mitglied seit Januar",
                      1: "Mitglied seit Februar",
                      2: "Mitglied seit März",
                      3: "Mitglied seit April",
                      4: "Mitglied seit Mai",
                      5: "Mitglied seit Juni",
                      6: "Mitglied seit Juli",
                      7: "Mitglied seit August",
                      8: "Mitglied seit September",
                      9: "Mitglied seit Oktober",
                      10: "Mitglied seit November",
                      11: "Mitglied seit Dezember",
                      },
                  "fr":  {
                      0: "Membre depuis Janvier",
                      1: "Membre depuis Février",
                      2: "Membre depuis Mars",
                      3: "Membre depuis Avril",
                      4: "Membre depuis Mai",
                      5: "Membre depuis Juin",
                      6: "Membre depuis Juillet",
                      7: "Membre depuis Août",
                      8: "Membre depuis Septembre",
                      9: "Membre depuis Octobre",
                      10: "Membre depuis Novembre",
                      11: "Membre depuis Décembre",
                      },
                  "ru":  {
                      0: "Участник с января",
                      1: "Участник с февраля",
                      2: "Участник с марта",
                      3: "Участник с апреля",
                      4: "Участник с мая",
                      5: "Участник с июня",
                      6: "Участник с июля",
                      7: "Участник с августа",
                      8: "Участник с сентября",
                      9: "Участник с октября",
                      10: "Участник с ноября",
                      11: "Участник с декабря",
                      },
                  "pl":  {
                      0: "Członek od stycznia",
                      1: "Członek od lutego",
                      2: "Członek od marca",
                      3: "Członek od kwietnia",
                      4: "Członek od maja",
                      5: "Członek od czerwca",
                      6: "Członek od lipca",
                      7: "Członek od sierpnia",
                      8: "Członek od września",
                      9: "Członek od października",
                      10: "Członek od listopada",
                      11: "Członek od grudnia",
                      },
                  "it":  {
                      0: "Membro da gennaio",
                      1: "Membro da febbraio",
                      2: "Membro da marzo",
                      3: "Membro da aprile",
                      4: "Membro da maggio",
                      5: "Membro da giugno",
                      6: "Membro da luglio",
                      7: "Membro da agosto",
                      8: "Membro da settembre",
                      9: "Membro da ottobre",
                      10: "Membro da novembre",
                      11: "Membro da dicembre",
                      },
                  "pt":  {
                      0: "Membro desde janeiro",
                      1: "Membro desde fevereiro",
                      2: "Membro desde março",
                      3: "Membro desde abril",
                      4: "Membro desde maio",
                      5: "Membro desde junho",
                      6: "Membro desde julho",
                      7: "Membro desde agosto",
                      8: "Membro desde setembro",
                      9: "Membro desde outubro",
                      10: "Membro desde novembro",
                      11: "Membro desde dezembro",
                      },
                  "es":  {
                      0: "Miembro desde Enero",
                      1: "Miembro desde Febrero",
                      2: "Miembro desde Marzo",
                      3: "Miembro desde Abril",
                      4: "Miembro desde Mayo",
                      5: "Miembro desde Junio",
                      6: "Miembro desde Julio",
                      7: "Miembro desde Agosto",
                      8: "Miembro desde Septiembre",
                      9: "Miembro desde Octubre",
                      10: "Miembro desde Noviembre",
                      11: "Miembro desde Diciembre",
                      },
              }
  
    return month[langCode][monthNumber];
  };
  