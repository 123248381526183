import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Button from "../../components/ui/Button";

import { useNavigate } from "react-router-dom";


import { LanguagePageContext } from "../../context/LanguagePageContext";

import "../../styles/banner.css"

export default function Banner(props) {
  const imgPath = process.env.REACT_APP_PUBLIC_URL_ILLUSTRATIONS + props.img;

  const languagePageData = React.useContext(LanguagePageContext);

  const navigate = useNavigate();

  const redirection = React.useCallback( () => {
    let currentPath = window.location.pathname.slice(0, 3) + "/discover/" + props.language.toLowerCase() + `/${props.storyOfTheDay.storyId}`;

    navigate(currentPath);

}, [props.language])

  return (
    <div className="row">
      
        <div className="col-12">
          <h6 className="banner-day-story">{languagePageData.dashboard[0].storyDayHeader}</h6>
          {props.flagList}
        </div>
      

      <div className="col-lg-8 col-12 order-lg-0 order-1 d-flex flex-column align-items-start">
        
          <h6 className="banner-title mt-2">{`${props.storyOfTheDay.title} (${props.storyOfTheDay.levelRef})`}</h6>
          <p className="banner-category-info">{`${props.storyOfTheDay.categoryLabel}`}, <strong>{`${props.storyOfTheDay.levelLabel}`}</strong></p>
          <div className="banner-container">
            <p className="banner-text py-2">{`${props.storyOfTheDay.startSentence}`}</p>
          </div>

        <div className="banner-button">
          <Button content={languagePageData.dashboard[0].readButtonContent} buttonHandler={() => redirection()}/>
        </div>
      </div>

      <div className="col-lg-4 col-12 order-lg-1 order-0 d-flex justify-content-center align-items-center relative">
          <div className="banner-img-container">
              <img className="banner-img" src={imgPath} alt="card-image" />
              <div className="banner-img-fade"></div>
          </div>
      </div>

    </div>
    
  );
}